import { useState } from 'react'
import styled from 'styled-components/macro'

export const CopyText = ({ children }: { children: string }) => {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(children)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  return (
    <StyledCopyText>
      <TextToCopy disabled={true} value={children} />
      <div>
        <CopyButton onClick={copy}>
          <CopyButtonText>{copied ? 'Copied!' : 'Copy link'}</CopyButtonText>
        </CopyButton>
      </div>
    </StyledCopyText>
  )
}

const StyledCopyText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`

const TextToCopy = styled.input`
  padding: 6px 10px;
  border-radius: 2px 0 0 2px;
  border: 1px solid #0d83d955;
  border-right: none;
  min-width: 300px;
  user-select: all;
  font-size: 12px;
`

const CopyButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
  padding: 0px 10px;
  /* background: linear-gradient(45deg, #0d83d9, #17bac3); */
  background: linear-gradient(45deg, #4838a4, #0c98ff 150%);
  border-radius: 0 2px 2px 0;
  cursor: pointer;
`

const CopyButtonText = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 500;
`
