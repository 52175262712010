import { Provider } from 'react-redux'

import Widget from './components/Widget'

import store from './store'

import { AnyFunction } from './utils/types'

type Props = {
  handleNewUserMessage: AnyFunction
  handleMessagesChanged?: AnyFunction
  handleQuickButtonClicked?: AnyFunction
  title?: string
  titleAvatar?: string
  subtitle?: string
  senderPlaceHolder?: string
  showCloseButton?: boolean
  fullScreenMode?: boolean
  autofocus?: boolean
  profileAvatar?: string
  profileClientAvatar?: string
  launcher?: AnyFunction
  handleTextInputChange?: (event: any) => void
  chatId?: string
  handleToggle?: AnyFunction
  launcherOpenLabel?: string
  launcherCloseLabel?: string
  launcherCloseImg?: string
  launcherOpenImg?: string
  sendButtonAlt?: string
  showTimeStamp?: boolean
  imagePreview?: boolean
  zoomStep?: number
  emojis?: boolean
  handleSubmit?: AnyFunction
  showBadge?: boolean
  resizable?: boolean
} & typeof defaultProps

function ConnectedWidget({
  title,
  titleAvatar,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  fullScreenMode,
  autofocus,
  profileAvatar,
  profileClientAvatar,
  launcher,
  handleNewUserMessage,
  handleMessagesChanged,
  handleQuickButtonClicked,
  handleTextInputChange,
  chatId,
  handleToggle,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  handleSubmit,
  showBadge,
  resizable,
  emojis,
}: Props) {
  return (
    <Provider store={store}>
      <Widget
        title={title}
        titleAvatar={titleAvatar}
        subtitle={subtitle}
        handleNewUserMessage={handleNewUserMessage}
        handleMessagesChanged={handleMessagesChanged}
        handleQuickButtonClicked={handleQuickButtonClicked}
        senderPlaceHolder={senderPlaceHolder}
        profileAvatar={profileAvatar}
        profileClientAvatar={profileClientAvatar}
        showCloseButton={showCloseButton}
        fullScreenMode={fullScreenMode}
        autofocus={autofocus}
        customLauncher={launcher}
        handleTextInputChange={handleTextInputChange}
        chatId={chatId}
        handleToggle={handleToggle}
        launcherOpenLabel={launcherOpenLabel}
        launcherCloseLabel={launcherCloseLabel}
        launcherCloseImg={launcherCloseImg}
        launcherOpenImg={launcherOpenImg}
        sendButtonAlt={sendButtonAlt}
        showTimeStamp={showTimeStamp}
        imagePreview={imagePreview}
        zoomStep={zoomStep}
        handleSubmit={handleSubmit}
        showBadge={showBadge}
        resizable={resizable}
        emojis={emojis}
      />
    </Provider>
  )
}

const defaultProps = {
  title: 'Welcome',
  subtitle: '',
  senderPlaceHolder: 'Type a message...',
  showCloseButton: true,
  fullScreenMode: false,
  autofocus: true,
  chatId: 'rcw-chat-container',
  launcherOpenLabel: 'Open chat',
  launcherCloseLabel: 'Close chat',
  launcherOpenImg: '',
  launcherCloseImg: '',
  sendButtonAlt: 'Send',
  showTimeStamp: true,
  imagePreview: false,
  zoomStep: 80,
  showBadge: true,
}
ConnectedWidget.defaultProps = defaultProps

export default ConnectedWidget
