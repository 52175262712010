import { useGetUserQuery } from 'services/userApi'
import styled from 'styled-components/macro'

// todo: decide whether this widget should load its own data versus receive it as props

/*
 * Hides upgrade button if !onUpgrade prop
 */
export const UsageWidget = ({ onUpgrade }: { onUpgrade?: () => void }) => {
  const { data: user, isLoading: userLoading } = useGetUserQuery()

  const nextMonthToday = () => {
    // todo: revisit placeholder
    const date = new Date(Date.now())
    date.setMonth(date.getMonth() + 1)
    return date.toLocaleDateString('en-us', { month: 'short', day: 'numeric' })
  }

  const getResponseLimit = (planType: string) => {
    const planLimits = {
      basic: 100,
      advanced: 800,
      premium: 2500,
    }
    return planLimits[planType || 'basic']
  }

  const getLightsaberColor = (usagePct) => {
    if (usagePct < 75) {
      // return '#6dc3f0'
      return '#777777'
    } else if (usagePct < 90) {
      // return '#ff8731'
      return '#ff5100'
    } else {
      // return '#df3314'
      return '#ff1f1f'
    }
  }

  const usagePct = (100 * (user?.currentPeriodResponses || 0)) / getResponseLimit(user?.planType)

  // const usagePct = 70

  return (
    <StyledUsageWidget>
      <Text>Responses collected</Text>
      <ResponseBarWrapper>
        <ResponseBar>
          <Lightsaber
            style={{
              width: usagePct + '%',
            }}
          ></Lightsaber>
        </ResponseBar>
        <ResponseBarLabels>
          <Text>
            <span style={{ fontSize: 20, color: getLightsaberColor(usagePct) }}>
              {user?.currentPeriodResponses || 0}
            </span>
            <span style={{ margin: '0 2px' }}>/</span>
            <span>{getResponseLimit(user?.planType)}</span>
          </Text>
          <Text style={{ fontSize: 12 }}>Resets {nextMonthToday()}</Text>
        </ResponseBarLabels>

        {onUpgrade && <ButtonText onClick={onUpgrade}>+ Increase response limit</ButtonText>}
      </ResponseBarWrapper>
    </StyledUsageWidget>
  )
}

const StyledUsageWidget = styled.div``

const ResponseBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const ResponseBar = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background-color: #e6e6e6;
  overflow: hidden;
`

const Lightsaber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #33347d;
`

const ResponseBarLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.p`
  font-size: 14px;
  color: #777;
  margin: 10px 0;
`

const ButtonText = styled(Text)`
  font-weight: 500;
  font-size: 13px;
  color: #0792da;
  cursor: pointer;
`
