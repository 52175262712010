import { AddRounded, SportsScore } from '@mui/icons-material'
import { Topbar } from 'components/Elements/Topbar'
import { MaxWidthAndCenter } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import styled from 'styled-components/macro'
import usePageTitle from 'utils/usePageTitle'

export const Integrations = () => {
  usePageTitle('Integrations | Storyforms')
  return (
    <>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      />
      <MaxWidthAndCenter style={{ width: 1000 }}>
        <Main>
          {/* <SectionTitle>Integrations</SectionTitle>
          <Section></Section> */}
          <SectionTitle>Integrations (coming soon)</SectionTitle>
          <Section>
            <Card>
              <img style={{ height: 40 }} src="/img/zapier.svg" />
              <CardTitle>Zapier</CardTitle>
              <CardText>Connect your Storyform to thousands of apps and services, using our custom Zaps</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 30, padding: '5px 0' }} src="/img/ganalytics.svg" />
              <CardTitle>Google Analytics</CardTitle>
              <CardText>Collect detailed engagement metrics to gain insights into trends</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/gsheets.svg" />
              <CardTitle>Google Sheets</CardTitle>
              <CardText>Export your Storyform completion data to a Google Sheet</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/excel.svg" />
              <CardTitle>Excel Online</CardTitle>
              <CardText>Export your Storyform completion data to an Excel Sheet</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>
            <Card>
              <img style={{ height: 40 }} src="/img/mailchimp.svg" />
              <CardTitle>Mailchimp</CardTitle>
              <CardText>
                Send new contacts to your Mailchimp lists, and tag them so they're easy to organize
              </CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/hubspot.svg" />
              <CardTitle>HubSpot</CardTitle>
              <CardText>
                Send Storyform data to HubSpot to quickly follow up on new leads and built out profiles
              </CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/airtable.svg" />
              <CardTitle>Airtable</CardTitle>
              <CardText>Send Storyform completions to Airtable to work with the data</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/slack.svg" />
              <CardTitle>Slack</CardTitle>
              <CardText>
                Notify a channel or individual in Slack with Storyform data to take instant action
              </CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/pipedrive.svg" />
              <CardTitle>Pipedrive</CardTitle>
              <CardText>
                Turn new contacts into deals and add them to any stage of your sales pipeline in Pipedrive
              </CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <Card>
              <img style={{ height: 40 }} src="/img/automateio.png" />
              <CardTitle>Automate.io</CardTitle>
              <CardText>Instantly send Storyform completions to 100+ popular apps and trigger workflows</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Connect</IntegrateButton>
            </Card>

            <AddCard onClick={() => alert('Coming soon!')}>
              <AddRounded sx={{ fontSize: 'inherit' }} />
            </AddCard>
          </Section>

          <SectionTitle style={{ marginTop: 70 }}>Webhooks (coming soon)</SectionTitle>
          <Section>
            <Card>
              <SportsScore />
              <CardTitle>Storyform Completions</CardTitle>
              <CardText>Receive a postback whenevery someone completes your Storyform</CardText>
              <IntegrateButton onClick={() => alert('Coming soon!')}>Configure</IntegrateButton>
            </Card>

            <AddCard onClick={() => alert('Coming soon!')}>
              <AddRounded sx={{ fontSize: 'inherit' }} />
            </AddCard>
          </Section>
        </Main>
      </MaxWidthAndCenter>
    </>
  )
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  padding: 40px 40px 100px;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const CardTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
  margin-top: 20px;
`

const CardText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
`

const IntegrateButton = styled.p`
  margin-top: auto;
  padding: 10px 20px;
  background-color: #4446a4;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  transition: 200ms background-color ease;

  &:hover {
    /* background-color: #007070; */
    background-color: #33347d;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 210px;
  max-width: 210px;
  height: 180px;
  padding: 30px 20px;
  border-radius: 2px;
  background: white;
  border: 2px solid white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const AddCard = styled(Card)`
  justify-content: center;
  box-shadow: none;
  font-size: 80px;
  cursor: pointer;
  background: transparent;
  /* color: #458c8b; */
  color: #33347d;
  border: 2px dashed #5555;
  border-radius: 2px;
  box-sizing: content-box;
`
