import styled, { css } from 'styled-components/macro'

export const fillContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export const centered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Centered = styled.div`
  position: relative;
  ${centered}
`

export const horizontalSpacedRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const fillAbsolutely = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`
