import styled from 'styled-components/macro'
import usePageTitle from 'utils/usePageTitle'
import { StripeInvoices } from './StripeInvoices'
import { StripePaymentMethod } from './StripePaymentMethod'
import { StripePlan } from './StripePlan'

export const Billing = () => {
  usePageTitle('Billing | Storyforms')
  return (
    <>
      <SplitPane>
        <Pane>
          <SectionTitle>Your Plan</SectionTitle>
          <StripePlan></StripePlan>
        </Pane>
        <Pane>
          <SectionTitle>Payment Method</SectionTitle>
          <StripePaymentMethod></StripePaymentMethod>
        </Pane>
      </SplitPane>
      <SectionTitle>Invoices</SectionTitle>
      <StripeInvoices />
    </>
  )
}

const Pane = styled.div`
  width: 50%;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const SplitPane = styled.div`
  display: flex;
  flex-direction: row;
`
