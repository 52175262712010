import { Check } from '@mui/icons-material'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Popup } from 'components/Popup'
import { Brand, Submit } from 'pages/Login/LoginComponents'
import { useEffect, useState } from 'react'
import { useDowngradeToBasicMutation, useGetClientSecretQuery, useGetPlanQuery } from 'services/userApi'
import styled from 'styled-components/macro'
import { centered, fillAbsolutely, horizontalSpacedRow } from 'styles/layout'

export const Plans = () => {
  const { data: plan, isLoading: planLoading } = useGetPlanQuery()
  const [downgradeToBasic] = useDowngradeToBasicMutation()
  const [selectedPlan, setSelectedPlan] = useState<'basic' | 'advanced' | 'premium'>(plan || 'basic')

  useEffect(() => {
    if (plan && !plan.error) setSelectedPlan(plan)
  }, [plan])

  useEffect(() => {
    if (plan && plan !== selectedPlan && selectedPlan === 'basic') {
      downgradeToBasic('basic')
    }
  }, [selectedPlan])

  return (
    <PlansContainer>
      <StyledPlans>
        <Plan>
          <Header>
            <Name>Basic</Name>
            <Price>$0</Price>
          </Header>
          <Description>
            Perfect for getting started with the platform and creating your first few Storyforms.
          </Description>
          {selectedPlan === 'basic' ? (
            <UpgradeButton selected={true}>
              <Check />
              Current Plan
            </UpgradeButton>
          ) : (
            <UpgradeButton onClick={() => setSelectedPlan('basic')} selected={false}>
              Downgrade
            </UpgradeButton>
          )}
        </Plan>
        <Plan>
          <Header>
            <Name>Advanced</Name>
            <Price>$9.99</Price>
          </Header>
          {selectedPlan === 'advanced' ? (
            <UpgradeButton selected={true}>
              <Check />
              Current Plan
            </UpgradeButton>
          ) : (
            <UpgradeButton onClick={() => setSelectedPlan('advanced')} selected={false}>
              {plan === 'basic' || plan?.error ? 'Upgrade' : 'Downgrade'}
            </UpgradeButton>
          )}
        </Plan>
        {/* <Plan>
          <Header>
            <Name>Premium</Name>
            <Price>$99</Price>
          </Header>
          {selectedPlan === 'premium' ? (
            <UpgradeButton selected={true}>
              <Check />
              Current Plan
            </UpgradeButton>
          ) : (
            <UpgradeButton onClick={() => setSelectedPlan('premium')} selected={false}>
              Upgrade
            </UpgradeButton>
          )}
        </Plan> */}
        {!planLoading && !!plan && plan !== selectedPlan && selectedPlan !== 'basic' && (
          <Popup handleBackgroundClick={() => setSelectedPlan(plan)}>
            <Payment plan={selectedPlan} />
          </Popup>
        )}
      </StyledPlans>
    </PlansContainer>
  )
}

const Payment = ({ plan }: { plan: 'advanced' | 'premium' }) => {
  const stripePromise = loadStripe(
    'pk_test_51LT8TxEfPotjCDXNT5W2qVnQ4SIuVpnJzJKMzCcNQkyC3QUK4ESt3XGATsh8kJBsn7xojQMNdZVJEmdHdkGAlY23001GtDHi7b'
  )
  const { data: result, isLoading: resultLoading } = useGetClientSecretQuery(plan)

  return (
    <>
      {result?.clientSecret && !resultLoading && (
        <Elements stripe={stripePromise} options={{ clientSecret: result.clientSecret }}>
          <PaymentModal />
        </Elements>
      )}
    </>
  )
}

const PaymentModal = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>()

  const handleSubmit = async () => {
    setLoading(true)
    if (!stripe || !elements) return
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/dashboard/billing/payments',
      },
    })

    setLoading(false)
    const error = result.error

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occurred.')
      }
    }
  }

  return (
    <StyledPaymentModal>
      <Brand />
      <PaymentElement />
      {!loading && <Submit type={'submit'} onClick={handleSubmit} value="Upgrade" />}
      {loading && <Submit type={'submit'} value="Submitting Payment" />}
    </StyledPaymentModal>
  )
}

const StyledPaymentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: 0.2s all;
`

const PlansContainer = styled.div`
  ${centered}
`

const StyledPlans = styled.div`
  ${horizontalSpacedRow}
  align-items: flex-start;
`

const Plan = styled.div`
  position: relative;
  width: 300px;
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(45deg, rgba(17, 210, 219, 1), rgba(13, 131, 217, 1));
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  --angle: 0deg;
  animation: 5s rotate linear infinite;
  overflow: hidden;
  z-index: 2;

  &:before {
    ${fillAbsolutely}
    box-sizing: border-box;
    border-radius: 7px;
    margin: 1px;
    background: #ffffff;
    z-index: -1;
    content: '';
  }

  &:after {
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }
`

const Header = styled.div`
  ${horizontalSpacedRow}
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
`

const Name = styled.div`
  font-weight: 700;
  color: #333333;
`

const Price = styled.div`
  font-weight: 700;
  color: #555555;
`

const Description = styled.div``

const UpgradeButton = styled.div<{ selected?: boolean }>`
  ${centered}
  margin: 30px 20px 20px;
  padding: 10px 20px;
  background-color: ${({ selected }) => (selected ? '#aaaaaa' : '#0cc6da')};
  color: white;
  border-radius: 30px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  transition: 200ms background-color ease;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#999999' : '#0ca8b9')};
  }
`
