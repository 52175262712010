import { TagCloud } from 'react-tagcloud'
import styled from 'styled-components/macro'
import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Tile,
} from '../SharedMobileQuestionComponents'

export function TextResult({ question, survey }: { question: Question; survey: Partial<Survey> }) {
  const { votes } = question
  const total = votes?.total || 0
  const responses = votes['-1']

  var data
  if (Object.keys(question.responseKeywords || {}).length > 0) {
    data = Object.keys(question.responseKeywords).map((keyword) => ({
      value: keyword,
      count: question.responseKeywords[keyword],
    }))
  } else {
    data = []
  }

  return (
    <Tile>
      <OpacityTop>
        <Prompt color={survey.styles?.questionTextColor || '#fff'}>{question.prompt}</Prompt>
      </OpacityTop>
      <TagCloudWrapper>
        {data.length > 0 ? (
          <TagCloudLabel>Frequent keywords:</TagCloudLabel>
        ) : (
          <TagCloudLabel style={{ paddingTop: 20 }}>Insufficient responses for keyword analytics</TagCloudLabel>
        )}
        <TagCloud
          style={{ textAlign: 'center' }}
          minSize={16}
          maxSize={26}
          tags={data}
          colorOptions={{ hue: 'blue', luminosity: 'dark' }}
        />
      </TagCloudWrapper>
      <OpacityBottom>
        <Engagements>
          <Total>
            {responses} responses <span style={{ color: '#fffc' }}>({total - responses} skips)</span>{' '}
          </Total>
        </Engagements>
      </OpacityBottom>
      <Background src={question.image?.url} />
    </Tile>
  )
}

const Total = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  width: calc(100% - 80px);
`

const TagCloudWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0 5px 20px;
  text-align: center;
  background: #fffb;
`

const TagCloudLabel = styled.h3`
  font-weight: 500;
`
