import { Delete } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { Toggle } from 'components/Input/Toggle'
import { TooltipText } from 'components/TooltipText'
import { useState } from 'react'
import Select from 'react-select'
import { useDeleteQuestionMutation, usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { useDebounce } from 'utils/useDebounce'
import { BottomTray, InputHeader, InputWrapper, Spacer } from './components'

export const FancyLabel = styled.p`
  // this is here because it is reference in QUESTION_TYPES below
  display: inline-block;
  background-color: #08324c;
  background-image: linear-gradient(45deg, rgba(17, 210, 219, 0.9), rgba(13, 131, 217, 0.9));
  background-size: 100%;
  background-clip: text;
  background-repeat: repeat;
  font-family: 'Avenir';
  font-weight: 600;
  width: max-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

const QUESTION_TYPES = [
  { value: 'intro', label: 'Intro' },
  { value: 'boolean', label: 'Yes/No' },
  // { value: 'multiple-choice', label: 'Multiple Choice' },
  // {
  //   value: 'card-multiple-choice',
  //   label: (
  //     <span>
  //       Multiple Choice <FancyLabel style={{ fontWeight: 500, color: '#5eb0cf' }}>(Next Gen)</FancyLabel>
  //     </span>
  //   ),
  // },
  { value: 'image-choice', label: 'Image Multiple Choice' },
  // { value: 'rank', label: 'Ranking' },
  { value: 'image-rank', label: 'Image Ranking' },
  { value: 'rate', label: 'Sliding Scale' },
  { value: 'text', label: 'Text' },
]

interface Props {
  survey: Partial<Survey>
  setStagingSurvey: any
  selectedQuestion: any
  onSelectQuestion: any
}

export const QuestionTab = ({ survey, setStagingSurvey, selectedQuestion, onSelectQuestion }: Props) => {
  const [editQuestion] = usePutQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  const [characterLimit, setCharacterLimit] = useState(selectedQuestion?.characterLimit || 500)

  const [choiceLimit, setChoiceLimit] = useState(
    selectedQuestion?.choiceLimit || selectedQuestion?.choices?.length
  )

  const editQuestionOptimistically = (delta) => {
    editQuestion({ _id: selectedQuestion._id, delta })
    setStagingSurvey((currentSurvey: any) => {
      return {
        ...currentSurvey,
        questions: currentSurvey.questions.map((question) =>
          question._id === selectedQuestion._id ? { ...question, ...delta } : question
        ),
      }
    })
  }

  useDebounce(
    characterLimit,
    () => {
      if (selectedQuestion?._id) {
        editQuestionOptimistically({ characterLimit })
      }
    },
    500,
    true
  )

  useDebounce(
    choiceLimit,
    () => {
      if (selectedQuestion?._id) {
        editQuestionOptimistically({ choiceLimit })
      }
    },
    500,
    true
  )

  const fullyDeleteQuestion = (question: Question) => {
    const isEnding = question.type === 'ending'
    const items = isEnding ? survey.endings : survey.questions
    if (selectedQuestion && selectedQuestion._id === question._id) {
      // user is deleting the selected question. select adjacent question, if any
      var nextSelectionTarget
      const currentSelectedIndex = items.findIndex((item) => item._id === question._id)
      if (currentSelectedIndex === 0 && items.length > 1) {
        // if deleting first question, select second question
        nextSelectionTarget = items[1]
      } else if (currentSelectedIndex > 0) {
        // if deleting any question other than the first, select the previous question in the survey
        nextSelectionTarget = items[currentSelectedIndex - 1]
      }
      onSelectQuestion(nextSelectionTarget)
    }
    if (isEnding) {
      setStagingSurvey((currentSurvey: any) => ({
        ...currentSurvey,
        endings: currentSurvey.endings?.filter((currentEnding) => currentEnding._id !== question._id),
      }))
    } else {
      setStagingSurvey((currentSurvey: any) => ({
        ...currentSurvey,
        questions: currentSurvey.questions?.filter((currentQuestion) => currentQuestion._id !== question._id),
      }))
    }

    deleteQuestion({ _id: question._id })
  }

  const getQuestionTypeOption = (questionType: string) => {
    for (let option of QUESTION_TYPES) {
      if (option.value === questionType) {
        return option
      }
    }
    return QUESTION_TYPES[0]
  }

  if (!selectedQuestion) {
    return null
  }

  return (
    <>
      {!['ending'].includes(selectedQuestion?.type!) && (
        <div style={{ margin: 10, fontSize: 14 }}>
          <Select
            menuPlacement="top"
            isSearchable={false}
            options={QUESTION_TYPES}
            defaultValue={QUESTION_TYPES[0]}
            value={getQuestionTypeOption(selectedQuestion.type)}
            onChange={(selectedType: any) => {
              if (
                selectedType.value !== selectedQuestion.type &&
                window.confirm(
                  'Are you sure you want to change the question type? Choices and other settings may be reset.'
                )
              ) {
                editQuestionOptimistically({ type: selectedType.value })
              }
            }}
          />
        </div>
      )}

      {!['intro', 'ending'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>
            <TooltipText
              style={{ padding: 10, width: 210, left: 0, top: 'calc(100% + 10px)' }}
              text="Respondents can skip the question temporarily, but must return and answer it before submitting the Storyform."
              position="bottom"
            >
              Response required
            </TooltipText>
          </InputHeader>
          <Toggle
            type="checkbox"
            defaultChecked={false}
            onClick={(event) => {
              event.preventDefault()
              alert('Coming soon!')
            }}
          />
        </InputWrapper>
      )}

      {['boolean'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>"Not Sure" Option</InputHeader>
          <Toggle
            type="checkbox"
            defaultChecked={false}
            onClick={(event) => {
              event.preventDefault()
              alert('Coming soon!')
            }}
          />
        </InputWrapper>
      )}

      {['multiple-choice'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>"Other" option</InputHeader>
          <Toggle
            type="checkbox"
            defaultChecked={false}
            onClick={(event) => {
              event.preventDefault()
              alert('Coming soon!')
            }}
          />
        </InputWrapper>
      )}

      {[
        'multiple-choice',
        'image-choice',
        'card-multiple-choice',
        'multiple-select',
        'rank',
        'image-rank',
      ].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>
            <TooltipText
              style={{ padding: 10, width: 210, left: 0, top: 'calc(100% + 10px)' }}
              text="Choices will appear in a random order when respondents take your Storyform. Preview your Storyform to see the effect in action."
              position="bottom"
            >
              Randomize choices
            </TooltipText>
          </InputHeader>
          <Toggle
            type="checkbox"
            defaultChecked={false}
            onClick={(event) => {
              event.preventDefault()
              alert('Coming soon!')
            }}
          />
        </InputWrapper>
      )}

      {['boolean', 'multiple-choice', 'card-multiple-choice', 'multiple-select', 'rank'].includes(
        selectedQuestion?.type!
      ) && (
        <InputWrapper>
          <InputHeader>Vertical alignment</InputHeader>
          <Toggle
            type="checkbox"
            defaultChecked={false}
            onClick={(event) => {
              event.preventDefault()
              alert('Coming soon!')
            }}
          />
        </InputWrapper>
      )}

      {['rank', 'image-rank'].includes(selectedQuestion?.type!) && (
        <>
          <InputWrapper>
            <InputHeader>Choice limit</InputHeader>
            <Toggle
              type="checkbox"
              defaultChecked={!!selectedQuestion?.choiceLimitActive}
              onChange={() =>
                editQuestionOptimistically({ choiceLimitActive: !selectedQuestion?.choiceLimitActive })
              }
            />
          </InputWrapper>

          {!!selectedQuestion.choiceLimitActive && (
            <InputWrapper style={{ justifyContent: 'flex-start', margin: '0px 10px 20px' }}>
              <input
                type="number"
                min="1"
                max={selectedQuestion?.choices?.length}
                disabled={!selectedQuestion.choiceLimitActive}
                defaultValue={selectedQuestion.choiceLimit}
                onChange={(event) => {
                  const choiceLimit = Number.parseInt(event.target.value)
                  if (
                    !isNaN(choiceLimit) &&
                    choiceLimit >= 1 &&
                    choiceLimit <= selectedQuestion?.choices?.length
                  ) {
                    setChoiceLimit(choiceLimit)
                  }
                }}
                onBlur={(event) => {
                  const textInput = event.target.value?.trim() || ''
                  const numericalInput = Number.parseInt(textInput)

                  if (
                    !textInput ||
                    isNaN(numericalInput) ||
                    numericalInput < 1 ||
                    numericalInput > selectedQuestion?.choices?.length
                  ) {
                    // if empty when user focus leaves the input, restore its value from the survey
                    event.target.value = choiceLimit + ''
                  }
                }}
                style={{ width: 40, marginLeft: 0, marginRight: 10, padding: '4px 6px' }}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>choices</span>
            </InputWrapper>
          )}
        </>
      )}

      {['text'].includes(selectedQuestion?.type!) && (
        <>
          <InputWrapper>
            <InputHeader>Character limit</InputHeader>
            <Toggle
              type="checkbox"
              defaultChecked={!!selectedQuestion?.characterLimitActive}
              onChange={() =>
                editQuestionOptimistically({ characterLimitActive: !selectedQuestion?.characterLimitActive })
              }
            />
          </InputWrapper>

          {!!selectedQuestion.characterLimitActive && (
            <InputWrapper style={{ justifyContent: 'flex-start', margin: '0px 10px 20px' }}>
              <input
                type="number"
                min="0"
                disabled={!selectedQuestion.characterLimitActive}
                defaultValue={selectedQuestion.characterLimit}
                onChange={(event) => {
                  const characterLimit = Number.parseInt(event.target.value)
                  if (!isNaN(characterLimit)) {
                    setCharacterLimit(characterLimit)
                  }
                }}
                onBlur={(event) => {
                  if (!event.target.value?.trim()) {
                    // if empty when user focus leaves the input, restore its value from the survey
                    event.target.value = characterLimit + ''
                  }
                }}
                style={{ width: 60, marginLeft: 10, marginRight: 10, padding: '4px 6px' }}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>characters</span>
            </InputWrapper>
          )}
        </>
      )}

      {['image-choice', 'image-rank'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>
            <TooltipText
              style={{ padding: 10, width: 210, left: 0, top: 'calc(100% + 10px)' }}
              text="Respondents can skip the question temporarily, but must return and answer it before submitting the Storyform."
              position="bottom"
            >
              Images on choices
            </TooltipText>
          </InputHeader>
          <Toggle type="checkbox" />
        </InputWrapper>
      )}

      {['intro', 'boolean', 'multiple-choice', 'multiple-select', 'rank', 'rate', 'text', 'ending'].includes(
        selectedQuestion?.type!
      ) && (
        <>
          {selectedQuestion.type !== 'ending' && <Spacer />}

          <InputHeader style={{ marginTop: 4 }}>Layout</InputHeader>

          <LayoutOptions>
            {/* Floating, LH image; RH text */}
            <LayoutOption
              style={{
                flexDirection: 'row-reverse',
                ...(selectedQuestion?.imagePosition === 'float-left' ? { borderColor: '#33347d' } : {}),
              }}
              onClick={
                selectedQuestion.imagePosition === 'float-left'
                  ? undefined
                  : () => editQuestionOptimistically({ imagePosition: 'float-left' })
              }
            >
              <LayoutColumn>
                <LayoutText></LayoutText>
                <LayoutText style={{ width: '60%' }}></LayoutText>
              </LayoutColumn>
              <LayoutColumn>
                <LayoutImage></LayoutImage>
              </LayoutColumn>
            </LayoutOption>

            {/* Floating, RH image; LH text */}
            <LayoutOption
              style={selectedQuestion.imagePosition === 'float-right' ? { borderColor: '#33347d' } : undefined}
              onClick={
                selectedQuestion.imagePosition === 'float-right'
                  ? undefined
                  : () => editQuestionOptimistically({ imagePosition: 'float-right' })
              }
            >
              <LayoutColumn>
                <LayoutText></LayoutText>
                <LayoutText style={{ width: '60%' }}></LayoutText>
              </LayoutColumn>
              <LayoutColumn>
                <LayoutImage></LayoutImage>
              </LayoutColumn>
            </LayoutOption>

            {/* No image; centered text */}
            <LayoutOption
              // style={selectedQuestion.imagePosition === 'float-center' ? { borderColor: '#33347d' } : undefined}
              style={{ borderColor: '#aaa', cursor: 'not-allowed' }}
              onClick={
                // selectedQuestion.imagePosition === 'float-center'
                //   ? undefined
                //   : () => editQuestionOptimistically({imagePosition: 'float-center'})
                undefined
              }
            >
              <LayoutColumn>
                <LayoutText style={{ width: '100%' }}></LayoutText>
                {/* <LayoutImage
                  style={{ margin: '2px 0', borderBottomWidth: 20, borderRightWidth: 30 }}
                ></LayoutImage> */}
                <LayoutText style={{ width: '70%' }}></LayoutText>
              </LayoutColumn>
            </LayoutOption>

            {/* Fullscreen height, LH image; RH text */}
            <LayoutOption
              style={{
                flexDirection: 'row-reverse',
                ...(selectedQuestion?.imagePosition === 'full-left' ? { borderColor: '#33347d' } : {}),
              }}
              onClick={
                selectedQuestion.imagePosition === 'full-left'
                  ? undefined
                  : () => editQuestionOptimistically({ imagePosition: 'full-left' })
              }
            >
              <LayoutColumn>
                <LayoutText></LayoutText>
                <LayoutText style={{ width: '60%' }}></LayoutText>
              </LayoutColumn>
              <LayoutColumn style={{ alignItems: 'flex-end' }}>
                <LayoutImage style={{ borderRightWidth: 25, borderBottomWidth: 44 }}></LayoutImage>
              </LayoutColumn>
            </LayoutOption>

            {/* Fullscreen height, RH image; LH text */}
            <LayoutOption
              style={selectedQuestion.imagePosition === 'full-right' ? { borderColor: '#33347d' } : undefined}
              onClick={
                selectedQuestion.imagePosition === 'full-right'
                  ? undefined
                  : () => editQuestionOptimistically({ imagePosition: 'full-right' })
              }
            >
              <LayoutColumn>
                <LayoutText></LayoutText>
                <LayoutText style={{ width: '60%' }}></LayoutText>
              </LayoutColumn>
              <LayoutColumn>
                <LayoutImage style={{ borderRightWidth: 25, borderBottomWidth: 44 }}></LayoutImage>
              </LayoutColumn>
            </LayoutOption>

            {/* Fullscreen, background image; centered foreground text */}
            <LayoutOption
              // style={selectedQuestion.imagePosition === 'full-center' ? { borderColor: '#33347d' } : undefined}
              style={{ borderColor: '#aaa', cursor: 'not-allowed' }}
              onClick={
                // selectedQuestion.imagePosition === 'full-center' ? undefined : () => editQuestionOptimistically({imagePosition: 'full-center'})
                undefined
              }
            >
              <LayoutFullscreenBackground style={{ zIndex: 0 }} />
              <LayoutColumn style={{ zIndex: 1 }}>
                <LayoutText style={{ width: '100%', borderColor: '#fff' }}></LayoutText>
                <LayoutText style={{ width: '70%', borderColor: '#fff' }}></LayoutText>
              </LayoutColumn>
            </LayoutOption>
          </LayoutOptions>
        </>
      )}

      <BottomTray>
        <Button
          variant="contained"
          sx={{
            width: 'calc(100% - 40px)',
            boxSizing: 'border-box',
            margin: '10px 20px',
            textAlign: 'center',
            fontSize: '12px',
            textTransform: 'none',
            backgroundColor: '#cc9797',
          }}
          startIcon={
            <Delete
              sx={{
                color: 'white',
              }}
            />
          }
          color="error"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this question?')) {
              fullyDeleteQuestion(selectedQuestion)
            }
          }}
        >
          {selectedQuestion.type === 'ending' ? 'Delete this ending' : 'Delete this question'}
        </Button>
      </BottomTray>
    </>
  )
}

const LayoutOptions = styled.div`
  width: max-content;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 0fr 0fr 0fr;
  gap: 16px;
`

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 56px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid rgb(115, 115, 115);
  display: flex;
  position: relative;
  cursor: pointer;
  transition: 100ms border-color ease;

  &:hover {
    border-color: #33347d;
  }
`

const LayoutText = styled.div`
  height: 0px;
  width: 80%;
  margin: 2px 0;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: inherit;
  text-align: left;
`

const LayoutImage = styled.div`
  height: 0px;
  border-top-width: 0;
  border-left-width: 0;
  border-bottom-width: 16px;
  border-right-width: 20px;
  border-radius: 2px;
  border-style: solid;
  border-color: inherit;
`

const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(50% - 10px);
  padding: 0 5px;
  border-color: inherit; // pass thru since this is child of LayoutOption (with the :hover rule) and and the parent of LayoutText/Image (which should inherit border-color from their grandparent)
`

const LayoutFullscreenBackground = styled.div`
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-right: 58px;
  border-bottom: 42px;
  border-style: solid;
  border-radius: 2px;
  border-color: inherit;
`

const InputLabel = styled(InputHeader)`
  // todo: consolidate with duplicates in other tabs
  margin: 4px 10px 12px;
  font-weight: 500;
`
