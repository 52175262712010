import { LibraryCategory, LibraryImage, LibraryProvider } from 'photoeditorsdk'

const UNSPLASH_ACCESS_KEY = 'QopRWukXle_ELS-Kz2MLc4GVEuldLraCYKjyYGm2vbE'

export class UnsplashProvider extends LibraryProvider {
  /**
   * This is a method explicitly created for this provider. It makes sure our data
   * JSON has been loaded from the server.
   * @return {Promise}
   * @private
   */
  loadData() {
    if (this.data) {
      return Promise.resolve(this.data)
    }

    return this.loadJSON('https://img.ly/static/libraries/unsplash/metadata.json').then((data) => {
      this.data = data
      return data
    })
  }

  /**
   * Returns the categories
   * @return {Promise}
   * @resolve {LibraryCategory[]}
   * @abstract
   */
  getCategories() {
    return new Promise<LibraryCategory[]>((resolve) =>
      resolve([
        {
          name: 'Unsplash',
          coverImage: undefined,
          coverImageUrl: '',
          loadCoverImage: undefined,
        },
      ])
    )
  }

  /**
   * Returns the images for the given search query
   * @param {String} query
   * @return {Promise}
   * @resolve {LibraryImage[]}
   * @abstract
   */
  searchImages(query) {
    if (query?.length < 3) {
      return new Promise<LibraryImage[]>((resolve) => resolve([]))
    }

    return fetch(`https://api.unsplash.com/search/photos?page=1&per_page=10&orientation=portrait&query=${query}`, {
      // todo: move this ajax into utility
      method: 'GET',
      headers: {
        Authorization: 'Client-ID ' + UNSPLASH_ACCESS_KEY,
      },
    })
      .then((response) => response.text())
      .then((body) => JSON.parse(body))
      .then((body) => {
        return body?.results?.map((unsplashImage) => {
          return new LibraryImage({
            title: unsplashImage.description,
            category: 'Unsplash',
            thumbUrl: unsplashImage.urls.thumb,
            rawUrl: unsplashImage.urls.full,
            authorName: unsplashImage.user.name,
            authorAvatar: unsplashImage.user.profile_image.small,
          })
        })
      })
  }
}
