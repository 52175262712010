import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getS3SignedUrl: build.mutation<{ signedRequest: string; resourceUrl: string }, void>({
      query: () => ({
        url: `util/s3-signed-url`,
        method: 'GET',
      }),
    }),

    runMixedSearch: build.mutation<any, { query: string }>({
      // add MixedSearchResult type to use instead of 'any'
      query: ({ query }) => ({
        url: `mixed-search/`,
        method: 'POST',
        body: { query },
      }),
    }),
  }),
})

export const { useGetS3SignedUrlMutation, useRunMixedSearchMutation } = api
