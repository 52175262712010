import { useAppDispatch } from 'app/hooks'
import { RootState } from 'app/store'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { asyncSignIn, forgotPasswordFlowStarted, signUpFlowStarted } from 'services/authSlice'
import {
  BottomActionText,
  Brand,
  Input,
  InputSubAction,
  LoginBox,
  Seperation,
  Subheader,
  Submit,
} from './LoginComponents'

export const SignIn = ({ style }: { style?: any }) => {
  const dispatch = useAppDispatch()
  const { status } = useSelector((state: RootState) => state.auth)
  const [email, setEmail] = useState<string>('')
  const [emailStatus, setEmailStatus] = useState<string>('default')
  const [password, setPassword] = useState<string>('')
  const [passwordStatus, setPasswordStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const newEmailStatus = email.length === 0 ? 'default' : /\S+@\S+\.\S+/.test(email) ? 'valid' : 'entering'
    setEmailStatus(newEmailStatus)
  }, [email])

  useEffect(() => {
    const newPasswordStatus = password.length === 0 ? 'default' : password.length < 8 ? 'entering' : 'valid'
    setPasswordStatus(newPasswordStatus)
  }, [password])

  useEffect(() => {
    if (status === 'failed') {
      setEmailStatus('entering')
      setPasswordStatus('entering')
    }
  }, [status])

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSubmitting(true)
    dispatch(asyncSignIn({ username: email, password })).finally(() => setSubmitting(false))
  }

  return (
    <LoginBox style={style}>
      <Brand />
      {status === 'failed' && <Subheader>Please check your username and password:</Subheader>}
      <form autoComplete="off" spellCheck="false">
        <Input
          key="email"
          status={emailStatus}
          placeholder="Email"
          autoComplete="username"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
        />
        <Input
          key="password"
          status={passwordStatus}
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputSubAction onClick={() => dispatch(forgotPasswordFlowStarted())}>Forgot password?</InputSubAction>
        <Submit type="submit" value="Log in" disabled={submitting} onClick={handleSubmit} />
        <Seperation>or</Seperation>
        <BottomActionText onClick={() => dispatch(signUpFlowStarted())}>create new account</BottomActionText>
      </form>
    </LoginBox>
  )
}
