import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PhotoEditorState {
  status: 'idle' | 'loading'
  open: boolean
  question?: Question // the question whose image is being edited
  choiceIndex?: number // the index of the question choice whose image is being edited (image-choice and image-rank questions only)
  imageUrl?: string
  image?: File
}

const initialState: PhotoEditorState = {
  status: 'idle',
  open: false,
  imageUrl: '',
  choiceIndex: -1
}

export const photoEditorSlice = createSlice({
  name: 'photoEditor',
  initialState,
  reducers: {
    photoEditorOpened: (
      state: PhotoEditorState,
      action: PayloadAction<{ question: Question; imageUrl?: string; choiceIndex?: number }>
    ) => {
      state.question = action.payload.question
      state.open = true
      if (action.payload.imageUrl) {
        state.imageUrl = action.payload.imageUrl
      }
      if (action.payload.choiceIndex !== undefined) {
        state.choiceIndex = action.payload.choiceIndex
      }
    },
    photoEditorClosed: (state: PhotoEditorState) => {
      state.question = undefined
      state.open = false
      state.imageUrl = ''
      state.choiceIndex = -1
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(asyncUploadImage.pending, (state) => {
  //       console.log('Starting')
  //       state.status = 'loading'
  //     })
  //     .addCase(asyncUploadImage.fulfilled, (state, action) => {
  //       console.log('Finished')
  //       console.log(action.payload)
  //       state.status = 'idle'
  //     })
  // },
})

// export const asyncUploadImage = createAsyncThunk<string, { blob: Blob }>(
//   'photoEditor/imageUploaded',
//   async (blob) => {
//     console.log('Hey there')
//     const [getSignedUrl] = useGetS3SignedUrlMutation()
//     console.log('test')
//     console.log(getSignedUrl)
//     const url = await getSignedUrl().unwrap()
//     console.log(url)
//     return 'fuck'
//   }
// )

// export const asyncUploadImage = createAsyncThunk<
//   { url: string },
//   { s3SignedUrl: { signedRequest: string; resourceUrl: string }; blob: Blob }
// >('photoEditor/imageUploaded', async (s3SignedUrl, blob) => {

// })

export const { photoEditorOpened, photoEditorClosed } = photoEditorSlice.actions
