import { underlinedNavItem } from 'components/Elements/navStyles'
import styled from 'styled-components/macro'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const InputHeader = styled.div`
  margin: 10px 2px;
  font-size: 12px;
  font-weight: 600;
  color: #555555;
  padding: 4px 8px;
`

export const Input = styled.input`
  width: calc(100% - 16px);
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #dddddd;
  outline: none;
  margin: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s;

  &:hover {
    border-color: #cccccc;
  }

  &:focus {
    border-color: #aaaaaa;
  }
`
export const NavItem = styled.div<{ selected: boolean }>`
  ${underlinedNavItem}
  margin: 0 10px;
  height: 40px;
  font-size: 12px;
`

export const Spacer = styled.div`
  margin: 0 10px;
  height: 1px;
  background-color: #eeee;
  box-sizing: border-box;
`

export const BottomTray = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
`

export const ColorPickers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`
