import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { BooleanVitals } from './BooleanVitals'
import { ChoiceVitals } from './ChoiceVitals'
import { RankVitals } from './RankVitals'
import { RateVitals } from './RateVitals'
import { TextVitals } from './TextVitals'

export const QuestionVitals = ({
  question,
  index,
  rankAggregate,
  prerender,
}: {
  question: Question
  index: number
  rankAggregate?: any
  prerender?: any
}) => {
  const renderQuestionVitals = () => {
    switch (question.type) {
      case 'boolean':
        return <BooleanVitals question={question} index={index} votes={question.votes} />
      case 'multiple-choice':
      case 'image-choice':
        return <ChoiceVitals question={question} index={index} votes={question.votes} />
      case 'rate':
        return <RateVitals question={question} index={index} votes={question.votes} />
      case 'rank':
      case 'image-rank':
        return (
          <RankVitals question={question} index={index} votes={question.votes} rankAggregate={rankAggregate} />
        )
      case 'text':
        return <TextVitals question={question} index={index} votes={question.votes} prerender={prerender} />
      default:
        return <></>
    }
  }

  return (
    <View style={questionView.container} wrap={false}>
      <View style={questionView.breakdown}>
        <View style={questionView.headerWrapper}>
          <Text style={questionView.index}>{`Q${index + 1}`}</Text>
          <View>
            <Text style={questionView.header}>{`“${question.prompt}”`}</Text>
            {renderQuestionVitals()}
          </View>
        </View>
      </View>
    </View>
  )
}

const questionView = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 20,
    marginBottom: 20,
  },
  breakdown: {
    flexDirection: 'column',
    marginLeft: 20,
  },
  headerWrapper: {
    flexDirection: 'row',
  },
  index: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000040',
    marginRight: 4,
    width: 30,
  },
  header: {
    marginLeft: 6,
    fontSize: 12,
    color: '#333',
  },
})
