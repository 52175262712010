import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => ({
        url: `user/`,
        method: 'GET',
      }),
      providesTags: ['user'],
    }),

    putUserProfile: build.mutation<User, { delta: Partial<User> }>({
      query: ({ delta }) => ({
        url: `user/profile/`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['user'],
    }),

    createCustomer: build.mutation({
      query: ({ firstName, lastName, email }: { firstName?: string; lastName?: string; email: string }) => ({
        url: `stripe/customer`,
        method: 'POST',
        body: { firstName, lastName, email },
      }),
      invalidatesTags: ['user'],
    }),

    putUser: build.mutation<User, { delta: Partial<User> }>({
      query: ({ delta }) => ({
        url: `user/`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['user'],
    }),

    getPaymentMethods: build.query<any, void>({
      query: () => ({
        url: 'stripe/payment_methods',
        method: 'GET',
      }),
    }),

    removePaymentMethod: build.mutation<any, string>({
      query: (id) => ({
        url: `stripe/payment_methods/${id}`,
        method: 'DELETE',
      }),
    }),

    setDefaultPaymentMethod: build.mutation<any, string>({
      query: (id) => ({
        url: `stripe/payment_methods/default`,
        method: 'POST',
        body: { paymentMethodId: id },
      }),
    }),

    getPlan: build.query<any, void>({
      query: () => ({
        url: `stripe/billingPlan`,
        method: 'GET',
      }),
      providesTags: ['planType'],
    }),

    downgradeToBasic: build.mutation<any, string>({
      query: (planType) => ({
        url: `stripe/billingPlan`,
        method: 'PUT',
        body: { planType },
      }),
      invalidatesTags: ['planType'],
    }),

    getClientSecret: build.query<any, any>({
      query: (price) => ({
        url: `stripe/secret/${price}`,
        method: 'GET',
      }),
    }),

    getAllCustomerInvoices: build.query<any, any>({
      query: ({ customerId }) => ({
        url: `stripe/invoices/${customerId}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  usePutUserProfileMutation,
  useCreateCustomerMutation,
  useGetPaymentMethodsQuery,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useGetPlanQuery,
  useDowngradeToBasicMutation,
  useGetClientSecretQuery,
  useGetAllCustomerInvoicesQuery,
} = api
