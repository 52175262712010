import styled from 'styled-components/macro'

const DEFAULT_WIDTH = 250

export const Carousel = styled.div<{ fieldNumber: number; width?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  transform: ${({ width, fieldNumber }) => `translateX(${-1 * (width || DEFAULT_WIDTH) * fieldNumber}px)`};
  transition: 0.2s all;
  transition: 300ms ease all;
`

export const CarouselClip = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => width || DEFAULT_WIDTH}px;
  /* height: calc(100% - 40px); // 40px is the height of the carousel tab bar @todo: move to css variable or similar */
  overflow: hidden;
  transition: 300ms ease all;
`

export const CarouselPage = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: ${({ width }) => width || DEFAULT_WIDTH}px;
  height: 100%;
  min-width: ${({ width }) => width || DEFAULT_WIDTH}px;
  max-width: ${({ width }) => width || DEFAULT_WIDTH}px;
  transition: 300ms ease all;
  overflow-y: auto;
`
