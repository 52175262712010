import './style.scss'

type Props = {
  title: string
  subtitle: string
  toggleChat: () => void
  showCloseButton: boolean
  titleAvatar?: string
  chatOpen?: boolean
}

function Header({ title, subtitle, toggleChat, showCloseButton, titleAvatar, chatOpen }: Props) {
  return (
    <div className="rcw-header">
      <div>{title}</div>
      <span>{subtitle}</span>
      <div style={{ width: 'calc(100% - 20px)', height: 1, backgroundColor: '#ddd', margin: '0 auto' }}></div>
    </div>
  )
}

export default Header
