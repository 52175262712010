import { useEffect, useRef, useState } from 'react'

import styled from 'styled-components/macro'

import { useResizeDetector } from 'react-resize-detector'

import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { useAppSelector } from 'app/hooks'
import { DeviceSelector } from 'components/Elements/DeviceSelector'
import { PhotoEditor } from 'components/ImageHandling/PhotoEditor'
import { Question } from 'components/Question/QuestionPreview/Question'
import { usePutQuestionMutation } from 'services/questionApi'
import { usePutSurveyMutation } from 'services/surveyApi'
import { centered } from 'styles/layout'
import { useLocalStorage } from 'utils/useLocalStorage'
import { ExperienceMap } from './ExperienceMap'
import { LeftBar } from './LeftBar'
import { RightBar } from './RightBar'

export const ExperienceCanvas = ({ experience }: { experience: any }) => {
  const survey = experience as Partial<Survey>
  const [stagingSurvey, setStagingSurvey] = useState<Partial<Survey> | undefined>(survey)

  const [putSurvey] = usePutSurveyMutation()
  const [editQuestion] = usePutQuestionMutation()
  const [updated, setUpdated] = useState<boolean>(true) // think of this as a negated dirty flag. inits to true to prevent a PUT on initial render

  const photoEditorOpen = useAppSelector((state) => state.photoEditor.open)
  const photoEditorImageUrl = useAppSelector((state) => state.photoEditor.imageUrl)

  const [orientation, setOrientation] = useState<'desktop' | 'mobile'>('desktop')
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null)

  const [leftBarOpen, setLeftBarOpen] = useLocalStorage('storyforms.go.canvas.leftbar.open', true)
  const [rightBarOpen, setRightBarOpen] = useLocalStorage('storyforms.go.canvas.rightbar.open', true)
  const [experienceMapOpen, setExperienceMapOpen] = useState(false)

  const {
    width: resizeObserverWidth,
    height: resizeObserverHeight,
    ref: resizeObserverRef,
  } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 50,
  })

  useEffect(() => {
    // update stagingSurvey based on api survey if not already in sync
    if (!!survey && (!stagingSurvey || survey?.updatedAt! >= stagingSurvey?.updatedAt!)) {
      setStagingSurvey(survey)
      setUpdated(true)
    }
  }, [survey])

  useEffect(() => {
    // update api survey based on stagingSurvey if not already in sync
    if (survey?._id && !!stagingSurvey && stagingSurvey?.updatedAt === survey?.updatedAt && !updated) {
      putSurvey({ _id: survey._id, delta: stagingSurvey })
    }

    // update selected question based on stagingSurvey
    if (!selectedQuestion) {
      if (stagingSurvey?.questions?.length! > 0) {
        setSelectedQuestion(stagingSurvey.questions[0])
      }
    } else {
      // refresh selected question
      if (selectedQuestion._id) {
        // ensure _id exists to filter by
        setSelectedQuestion(
          selectedQuestion.type === 'ending'
            ? stagingSurvey.endings.filter((ending) => ending._id === selectedQuestion._id)[0]
            : stagingSurvey.questions.filter((question) => question._id === selectedQuestion._id)[0]
        )
      }
    }

    setUpdated(false)
  }, [stagingSurvey])

  const toggleSidebarOpen = (position: 'left' | 'right') => {
    if (position === 'left') {
      setLeftBarOpen(!leftBarOpen)
    } else {
      setRightBarOpen(!rightBarOpen)
    }
  }

  const handleAddQuestionChoice = () => {
    const choices = [...selectedQuestion.choices, { label: 'Other', value: selectedQuestion.choices.length }]
    editQuestion({ _id: selectedQuestion._id, delta: { choices } })
    if (setStagingSurvey) {
      setStagingSurvey((currentSurvey: any) => {
        return {
          ...currentSurvey,
          questions: currentSurvey.questions.map((question) =>
            question._id === selectedQuestion._id ? { ...question, choices } : question
          ),
        }
      })
    }
  }

  const isDesktop = orientation === 'desktop'

  const uhhhh = useRef<any>()

  return (
    <StyledExperienceCanvas>
      {photoEditorOpen && selectedQuestion && (
        <PhotoEditor
          image={photoEditorImageUrl || selectedQuestion.image?.url}
          setStagingSurvey={setStagingSurvey}
        />
      )}
      <LeftBar
        experience={stagingSurvey}
        open={leftBarOpen}
        onToggleOpen={() => toggleSidebarOpen('left')}
        experienceMapOpen={experienceMapOpen}
        onToggleExperienceMapOpen={() => setExperienceMapOpen(!experienceMapOpen)}
        selectedQuestion={selectedQuestion}
        onSelectQuestion={setSelectedQuestion}
        setStagingSurvey={setStagingSurvey}
        handleChangeTabsProxy={uhhhh}
      />

      <Canvas style={experienceMapOpen ? { flexDirection: 'column', justifyContent: 'flex-start' } : undefined}>
        <>
          <DeviceSelector orientation={orientation} setOrientation={setOrientation} />

          {survey.questions.length === 0 && (
            <ButtonWrapper>
              <ButtonLabel>
                To get started, add a question or let our AI assistant build your form for you!
              </ButtonLabel>
              <Button
                size="small"
                sx={{
                  fontSize: '12px',
                  color: '#fff',
                  margin: '0 10px',
                  width: '100%',
                  padding: '8px 8px',
                  backgroundColor: '#33347d',
                  '&:hover': {
                    backgroundColor: '#4446a4',
                  },
                }}
                startIcon={<Add />}
                variant="contained"
                tabIndex={-1}
                onClick={() => {
                  if (uhhhh.current) {
                    uhhhh.current(2)
                  }
                  setLeftBarOpen(true)
                }}
              >
                Add a question
              </Button>
            </ButtonWrapper>
          )}

          {experienceMapOpen && (
            <ExperienceMap
              experience={experience}
              orientation={orientation}
              selectedQuestion={selectedQuestion}
              onSelectQuestion={setSelectedQuestion}
              onClose={() => setExperienceMapOpen(false)}
            />
          )}

          {selectedQuestion && (
            <OverlayAnchor>
              <ResizeDetector
                style={{ display: experienceMapOpen ? 'none' : 'flex' }}
                ref={resizeObserverRef}
                ratio={isDesktop ? 16 / 9 : 39 / 61}
              >
                <ZoomWrapper
                  style={{
                    transform: `scale(${
                      !resizeObserverWidth ? 1 : Math.min((resizeObserverWidth || 0) / (isDesktop ? 1000 : 390), 1)
                    })`,
                  }}
                >
                  <AspectRatio ratio={isDesktop ? 16 / 9 : 39 / 61} style={{ width: isDesktop ? 1000 : 390 }}>
                    <Question
                      question={selectedQuestion}
                      survey={stagingSurvey}
                      setStagingSurvey={setStagingSurvey}
                      orientation={orientation}
                      stats={false}
                    />
                  </AspectRatio>
                </ZoomWrapper>

                {['image-choice', 'image-rank'].indexOf(selectedQuestion.type) !== -1 && (
                  <Tooltip title="Add a choice" placement="bottom">
                    <AddChoiceButtonWrapper>
                      <Fab
                        color="info"
                        size="medium"
                        sx={{
                          boxShadow: '0 0 10px #aaaa',
                          color: '#fff',
                          bgcolor: '#33347d',
                          ':hover': { bgcolor: '#48499e' },
                        }}
                        onClick={handleAddQuestionChoice}
                      >
                        <Add />
                      </Fab>
                    </AddChoiceButtonWrapper>
                  </Tooltip>
                )}
              </ResizeDetector>
            </OverlayAnchor>
          )}
        </>
      </Canvas>

      <RightBar
        experience={stagingSurvey}
        open={rightBarOpen}
        onToggleOpen={() => toggleSidebarOpen('right')}
        selectedQuestion={selectedQuestion}
        onSelectQuestion={setSelectedQuestion}
        setStagingSurvey={setStagingSurvey}
      />
    </StyledExperienceCanvas>
  )
}

const StyledExperienceCanvas = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  height: 100%;
`
const Canvas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  height: 100%;
  overflow: auto;
`

const OverlayAnchor = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1000px;
`

const AspectRatio = styled.div<{ ratio: number }>`
  ${({ ratio }) => (ratio >= 1 ? 'width: 90%' : 'height: 80%')};
  aspect-ratio: ${({ ratio }) => ratio};
`

const ResizeDetector = styled(AspectRatio)`
  ${centered}
  flex-direction: column;
  width: max-content;
  height: max-content;
  max-width: calc(100%);
  max-height: calc(100%);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
`

const ZoomWrapper = styled.div`
  ${centered}
  flex-direction: row;
  transform-origin: center center;
  max-width: 1000px;
  aspect-ratio: inherit;
  background: #3333;
  transition: 200ms transform ease;
`

const AddChoiceButtonWrapper = styled.div`
  position: absolute;
  bottom: -70px;
  z-index: 1;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

const ButtonLabel = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  width: 260px;
  text-align: center;
`
