import { Image, Text, View } from '@react-pdf/renderer'

export const SurveyVitals = ({
  survey,
  gaugeChartRender,
  experienceJourneyRender,
}: {
  survey: Survey
  gaugeChartRender?: any
  experienceJourneyRender?: any
}) => {
  const uniqueViews = survey.anonymousVoting?.counts.view || 0
  const uniqueStarts = survey.anonymousVoting?.counts.start || 0
  const uniqueCompletions = survey.anonymousVoting?.counts.completion || 0
  const uniqueStartRate = uniqueStarts / uniqueViews
  const uniqueCompletionRate = uniqueCompletions / uniqueStarts
  const uniqueRetentionRate = uniqueCompletions / uniqueViews

  const formatPercent = (decimal: number) => Math.round(decimal * 10000.0) / 100.0

  const Section = ({ style, children }: { style?: any; children?: any }) => (
    <View style={{ padding: '20px 40px', ...style }}>{children}</View>
  )

  const Spacer = ({ style }: { style?: any }) => (
    <View style={{ borderBottom: '1px solid #ddd', margin: '20px 40px', ...style }}></View>
  )

  const SmallText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 12, ...style }}>{children}</Text>
  )

  const MediumText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 14, ...style }}>{children}</Text>
  )

  const LargeText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 16, ...style }}>{children}</Text>
  )
  return (
    <Section>
      <MediumText style={{ marginBottom: 20 }}>Topline results</MediumText>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          lineHeight: 1.5,
          marginBottom: 20,
        }}
      >
        {/* Vital metrics */}
        <View style={{ paddingLeft: 6, paddingRight: 100 }}>
          <SmallText>
            <LargeText style={{ fontWeight: 'bold' }}>{uniqueViews.toLocaleString()} </LargeText>views
          </SmallText>
          <SmallText>
            <LargeText style={{ fontWeight: 'bold' }}>{uniqueStarts.toLocaleString()} </LargeText>starts
          </SmallText>
          <SmallText>
            <LargeText style={{ fontWeight: 'bold' }}>{uniqueCompletions.toLocaleString()} </LargeText>completions
          </SmallText>
        </View>

        {/* Gauge charts for start and completion rates */}
        <Image
          src={gaugeChartRender}
          style={{
            marginLeft: 30,
            width: 400,
            minWidth: 400,
            backgroundColor: '#fff',
          }}
        />
      </View>

      <SmallText style={{ marginBottom: 20 }}>Question response rates</SmallText>

      {/* Experience journey */}
      <View>
        <Image
          src={experienceJourneyRender}
          style={{
            marginLeft: 15,
            marginRight: 15,
            backgroundColor: '#fff',
          }}
        />
      </View>
    </Section>
  )
}
