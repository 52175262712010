import { useGetAllCustomerInvoicesQuery, useGetUserQuery } from 'services/userApi'
import styled from 'styled-components/macro'

export const StripeInvoices = () => {
  const { data: user, isLoading: userLoading } = useGetUserQuery()
  const { data: invoices, isLoading: invoicesLoading } = useGetAllCustomerInvoicesQuery(
    { customerId: user?.stripeCustomerId },
    { skip: !user?.stripeCustomerId }
  )

  const InvoiceRow = ({ invoice, index }: { invoice: any; index: number }) => {
    const openInvoiceDetails = () => {
      // todo: navigate or show popup
    }

    if (!invoice) {
      return null
    }

    return (
      <Row key={invoice.id}>
        <Cell style={{ flex: 1, marginRight: 40, color: '#33347d' }}>
          {invoice.charge?.description || 'No description'}
        </Cell>
        <Cell>
          {invoice.charge?.created ? new Date(invoice.charge?.created * 1000).toLocaleDateString('en-us') : 'N/A'}
        </Cell>
        <Cell>${(invoice.amount_due / 100).toFixed(2)}</Cell>
        <Cell>{invoice.paid ? 'Yes' : 'No'}</Cell>
      </Row>
    )
  }

  return (
    <>
      {!invoices ? (
        <p>Loading invoices...</p>
      ) : (
        <InvoiceList>
          <Row style={{ borderTop: 'none' }}>
            <ColumnHeader style={{ flex: 1, marginRight: 40 }}>Invoice Description</ColumnHeader>
            <ColumnHeader>Created</ColumnHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Paid</ColumnHeader>
          </Row>

          {invoices?.map((invoice, index) => (
            <InvoiceRow key={'ql-' + index} invoice={invoice} index={index} />
          ))}
        </InvoiceList>
      )}
      {/* {hasMore && <button onClick={loadMoreInvoices}>Load More Invoices</button>} */}
    </>
  )
}

// todo: merge w/ duplicates
const InvoiceList = styled.div`
  max-width: 1200px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 60px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #aaa3;
`

const Cell = styled.div`
  /* box-sizing: border-box; */
  margin-right: 20px;
  min-width: 120px;
  font-size: 14px;
`

const ColumnHeader = styled(Cell)`
  font-weight: 500;
`
