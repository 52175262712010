import { useAppDispatch } from 'app/hooks'
import { QuestionImagePicker } from 'components/ImageHandling/QuestionImagePicker'
import { useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'utils/QuestionUtils'
import { useDebounce } from 'utils/useDebounce'
import {
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  EditButtonBlurWrapper,
  ImageWrapper,
  KeyboardKey,
} from '../SharedDesktopQuestionComponents'

import { Brush, HideImage } from '@mui/icons-material'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Submit,
  Tile,
} from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  editQuestion: any
  survey: Partial<Survey>
  orientation?: 'desktop' | 'mobile'
}

export const TextQuestion = ({ question, editQuestion, survey, orientation = 'mobile' }: Props) => {
  const [prompt, setPrompt] = useState(question.prompt)

  const [dummyText, setDummyText] = useState("Here's an example response. Try editing me!")

  const dispatch = useAppDispatch()

  useDebounce(prompt, () => editQuestion({ prompt }), 500, true)

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        {question.image?.url && (
          <ImageWrapper style={question.imagePosition?.startsWith('float') ? { maxHeight: '70%' } : undefined}>
            <DesktopImage
              style={
                question.imagePosition?.startsWith('float')
                  ? { height: 'unset', maxHeight: '100%', width: 'unset', maxWidth: '359px' }
                  : undefined
              }
              onClick={() => dispatch(photoEditorOpened({ question }))}
              src={question.image?.url || `${window.location.origin}/img/image_handling/add_image.png`}
            />
            <EditButtonBlurWrapper>
              <Tooltip title="Edit image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    dispatch(photoEditorOpened({ question }))
                  }}
                >
                  <Brush />
                </Fab>
              </Tooltip>

              <Tooltip title="Remove image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                  }}
                  onClick={() => {
                    if (window.confirm('Are you sure you want to remove this image?')) {
                      editQuestion({ image: null })
                    }
                  }}
                >
                  <HideImage />
                </Fab>
              </Tooltip>
            </EditButtonBlurWrapper>
          </ImageWrapper>
        )}
        {!question.image?.url && (
          <QuestionImagePicker
            style={
              question.imagePosition?.startsWith('float') ? { maxHeight: '70%', width: 'unset' } : { width: 359 }
            }
            question={question}
            onChangeImageUrl={(url) => editQuestion({ image: { url } })}
          />
        )}
        <DesktopField style={question?.imagePosition?.startsWith('float') ? { flex: 0.7 } : undefined}>
          <DesktopPrompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <span style={{ position: 'relative' }}>
            <DesktopTextArea
              maxLength={
                question?.characterLimitActive && !isNaN(question?.characterLimit)
                  ? question?.characterLimit
                  : undefined
              }
              defaultValue={dummyText}
              placeholder={'Type your thoughts here...'}
              onChange={(event) => setDummyText(event.target.value)}
            />
            {!!question?.characterLimitActive && (
              <p style={{ position: 'absolute', bottom: 20, right: 20, color: '#777', fontSize: 16 }}>
                <span style={{ fontSize: 20 }}>{dummyText?.length || 0}</span>
                <span>/{question?.characterLimit}</span>
              </p>
            )}
          </span>
          <DesktopEngagements>
            <DesktopEngagement style={{ display: 'flex' }}>
              <KeyboardKey>↵</KeyboardKey>Submit
            </DesktopEngagement>
            <DesktopEngagement>Skip</DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
        </OpacityTop>
        <span style={{ position: 'relative' }}>
          <TextInput
            maxLength={
              question?.characterLimitActive && !isNaN(question?.characterLimit)
                ? question?.characterLimit
                : undefined
            }
            defaultValue={dummyText}
            placeholder={'Type your thoughts here...'}
            onChange={(event) => setDummyText(event.target.value)}
          />
          {!!question?.characterLimitActive && (
            <p style={{ position: 'absolute', bottom: 20, right: 40, color: '#777', fontSize: 16 }}>
              <span style={{ fontSize: 20 }}>{dummyText?.length || 0}</span>
              <span>/{question?.characterLimit}</span>
            </p>
          )}
        </span>
        <OpacityBottom>
          <Engagements style={{ marginRight: '20px', marginLeft: '20px' }}>
            <Submit style={{ textAlign: 'center' }}>Skip</Submit>
            <Submit style={{ textAlign: 'center' }}>Submit</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const DesktopTextArea = styled.textarea`
  box-sizing: border-box;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  font-family: 'Avenir';
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #434343;
  background: rgba(255, 255, 255, 0.9);
  outline: none;
  border: none;
  resize: none;
`

const DesktopEngagement = styled.div`
  font-size: 16px;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`

const TextInput = styled.textarea`
  width: calc(100% - 80px);
  margin-left: 20px;
  padding: 20px;
  font-family: 'Avenir';
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #434343;
  background: rgba(255, 255, 255, 0.9);
  outline: none;
  border: none;
  resize: none;
`
