import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useClickOutside } from 'utils/useClickOutside'

interface Props {
  key: string | number
  items: React.ReactNode
  children: React.ReactNode
}

interface Location {
  left: number
  top: number
}

export const ContextMenu = ({ items, children }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [fading, setFading] = useState<boolean>(false)

  const [clickLocation, setClickLocation] = useState<Location>()
  const [menuLocation, setMenuLocation] = useState<Location>()

  const contextOn = useRef<HTMLDivElement>(null)
  const contextMenu = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!clickLocation || !contextMenu.current || !contextOn.current) return
    const location = { left: 0, top: 0 }
    const { top: topOffset, left: leftOffset, width: parentWidth } = contextOn.current!.getBoundingClientRect()
    const { width: renderWidth, height: renderHeight } = contextMenu.current?.getBoundingClientRect() || {
      width: 0,
    }
    const { innerHeight: windowHeight } = window

    const left = clickLocation.left - leftOffset
    const top = clickLocation.top - topOffset
    const reversedLeft = left - renderWidth
    const flooredTop = windowHeight - renderHeight

    location.left = left + renderWidth > parentWidth ? reversedLeft : left
    location.top = top + renderHeight > windowHeight ? flooredTop : top

    setMenuLocation(location)
  }, [clickLocation, contextMenu.current, contextOn.current])

  const closeContextMenu = () => {
    setFading(true)
    setTimeout(() => {
      setOpen(false)
      setFading(false)
    }, 200)
  }

  const openContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setClickLocation({ top: e.pageY, left: e.pageX })
    setOpen(true)
  }

  useClickOutside(contextMenu, closeContextMenu)

  return (
    <MenuWrapper ref={contextOn} onContextMenu={openContextMenu}>
      {children}
      {open && (
        <Menu onClick={() => setOpen(false)} ref={contextMenu} location={menuLocation} fading={fading}>
          {items}
        </Menu>
      )}
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  position: relative;
`

const Menu = styled.div<{ location?: Location; fading: boolean }>`
  width: max-content;
  box-sizing: border-box;
  border: 0.2px solid rgba(200, 200, 200, 0.5);
  outline: 0.1px solid #000000;
  padding: 3px;
  position: absolute;
  top: ${({ location }) => location?.top || 0}px;
  left: ${({ location }) => location?.left || 0}px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 999;
  color: #ffffff;
  border-radius: 4px;
  opacity: ${({ fading }) => (fading ? 0 : 1)};
  transition: 0.2s opacity;
`
