import { Question } from 'components/Question/QuestionPreview/Question'
import styled from 'styled-components/macro'

export const ExperienceMap = ({
  experience,
  orientation,
  selectedQuestion,
  onSelectQuestion,
  onClose,
}: {
  experience: any
  orientation: any
  selectedQuestion: any
  onSelectQuestion: any
  onClose: any
}) => {
  const survey = experience as Partial<Survey>

  return (
    <StyledExperienceMap>
      <QuestionResultsGrid>
        {survey.questions?.map((question, index) => (
          <QuestionZoomWrapper
            key={'qg-' + index}
            orientation={orientation}
            selected={question._id === selectedQuestion?._id}
            onClick={() => {
              onSelectQuestion(question)
              onClose()
            }}
          >
            <NoPointerEvents>
              <Question question={question} survey={survey} orientation={orientation} stats={false} />
            </NoPointerEvents>
            <QuestionNumber>{index + 1}</QuestionNumber>
          </QuestionZoomWrapper>
        ))}
        {survey.endings?.map((ending, index) => (
          <QuestionZoomWrapper
            key={'qg-' + index}
            orientation={orientation}
            selected={ending._id === selectedQuestion?._id}
            onClick={() => {
              onSelectQuestion(ending)
              onClose()
            }}
          >
            <NoPointerEvents>
              <Question question={ending} survey={survey} orientation={orientation} stats={false} />
            </NoPointerEvents>
            <QuestionNumber>
              <span style={{ fontSize: 34, margin: 15 }}>ending</span>
              {index + 1}
            </QuestionNumber>
          </QuestionZoomWrapper>
        ))}
      </QuestionResultsGrid>
    </StyledExperienceMap>
  )
}

const StyledExperienceMap = styled.div`
  height: 100%;
  padding: 40px 70px;
  overflow: auto;
`

const QuestionResultsGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 200px;
`

const QuestionNumber = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #aaaa;
  padding: 20px 30px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  z-index: 1;
  border-radius: 0 0 0 10px;
`

const QuestionZoomWrapper = styled.div<{ orientation?: 'desktop' | 'mobile'; selected?: boolean }>`
  position: relative;
  flex: auto;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 20%) 0px 2px 12px;
  overflow: hidden;
  border-radius: 40px;

  ${({ orientation }) => {
    switch (orientation) {
      case 'desktop':
        return `
          width: 1000px;
          max-width: 1000px;
          aspect-ratio: 16 / 9;
          zoom: 0.3;
        `

      case 'mobile':
        return `
          width: 390px;
          max-width: 390px;
          zoom: 0.4;
          aspect-ratio: 39 / 61;
        `

      default:
        return ''
    }
  }};

  margin: 80px 50px;
  border: 0px solid transparent;
  opacity: 0.95;
  /* transition: 150ms all ease; */

  &:hover {
    margin: 70px 40px;
    border: 10px solid #62cde3;
    opacity: 1;

    ${QuestionNumber} {
      background-color: #62cde3;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    border: 10px solid #62cde3;
    margin: 70px 40px;
    opacity: 1;

    ${QuestionNumber} {
      background-color: #62cde3;
    }
  `}
`

const NoPointerEvents = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
`
