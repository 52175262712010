import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { usePostSurveyMutation } from 'services/surveyApi'

import { Add, AddRounded, AppsRounded, Edit, FormatListBulletedRounded } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'

import { Topbar } from 'components/Elements/Topbar'
import { GrowingInput } from 'components/Input/GrowingInput'
import { AccountMenu } from 'components/UI/AccountMenu'
import { useGetWorkspacesQuery, usePostWorkspaceMutation, usePutWorkspaceMutation } from 'services/workspaceApi'
import { useDebounce } from 'utils/useDebounce'
import { useLocalStorage } from 'utils/useLocalStorage'
import usePageTitle from 'utils/usePageTitle'
import { Upgrade, UpgradeButton } from '../../components/Upgrade'
import { ExperiencesGridItem } from './ExperiencesGridItem'
import { SearchButton } from './MixedSearch'
import { UsageWidget } from './UsageWidget'
import { WorkspaceList } from './WorkspaceList'

const IS_PAID_PLAN = false

const BLURHASHES = [
  'JG2y3|p4%qsqyr*w',
  'JGL,HHAQNhO;@:]3',
  'JG9R-[8]x+XfK?:y',
  'JGpen%v?FO{ky6#q',
  'JGAzEY=y#M8Vzg0.',
  'JG3|.B0N@*P9HbdL',
  'JGDpinm|am5RqdS@',
  'JG4LQN72olY.n47x',
  'JGJuBOSuha0ce+;p',
  'JG7z13Pykiehb#d0',
  'JG34KpU^v?9lAJQG',
  'JG7~0_z.$*wAp,TG',
  'JGD7XqEXQ-7bwO|z',
  'JG2,4n=mU.TQZji%',
  'JGBSA[FFLg7P?JqI',
  'JGGQGYEG;O8je:Ws',
  'JG1|-|.P}T:G|o53',
  'JGC+;-P|XJ[2ttSu',
  'JGF=O.n5ZVE-FbF4',
  'JG72#3DZUH8u7xXm',
  'JGIx6;YP~UB;TCXJ',
  'JG6+_,1Q~hjO*pLI',
]

export const Workspaces = ({ setSearchOpen }: { setSearchOpen: any }) => {
  const navigate = useNavigate()

  const { workspaceId } = useParams()

  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const [viewMode, setViewMode] = useLocalStorage<'grid' | 'list'>('storyforms.go.workspaces.viewmode', 'grid')

  const [upgradeOpen, setUpgradeOpen] = useState(false)

  const [postWorkspace] = usePostWorkspaceMutation()
  const [putWorkspace] = usePutWorkspaceMutation()

  const { data: workspaces, isLoading: workspacesLoading } = useGetWorkspacesQuery()

  const [postExperience] = usePostSurveyMutation()

  const [statusFilter, setStatusFilter] = useState('all')

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>()

  const [workspaceName, setWorkspaceName] = useState<string>('')

  const [draggedExperience, setDraggedExperience] = useState<Survey | undefined>()

  useDebounce(
    workspaceName,
    () => {
      if (selectedWorkspace && selectedWorkspace.name !== workspaceName) {
        putWorkspace({ _id: selectedWorkspace._id, delta: { name: workspaceName } })
      }
    },
    500,
    true
  )

  useEffect(() => {
    if (workspaceId && workspaces?.map((workspace) => workspace._id).includes(workspaceId)) {
      setSelectedWorkspace(workspaces?.find((ws) => ws._id === workspaceId))
    } else if (workspaces?.length > 0) {
      navigate(`/dashboard/workspaces/${workspaces[0]._id}`)
    } else if (workspaces && workspaces.length === 0) {
      navigate(`/dashboard/workspaces/`)
    }
  }, [workspaces, workspaceId])

  useEffect(() => {
    setWorkspaceName(selectedWorkspace?.name)
  }, [selectedWorkspace])

  usePageTitle(`${selectedWorkspace?.name || 'Workspaces'} | Storyforms`)

  const createWorkspace = (workspace: any) => {
    postWorkspace(workspace)
      .unwrap()
      .then((createdWorkspace) => setSelectedWorkspace(createdWorkspace))
    // setWorkspaces([workspace, ...workspaces])
    setSelectedWorkspace(workspace)
  }

  const createExperience = async () => {
    if (!selectedWorkspace || !selectedWorkspace._id) {
      alert('To create a new Storyform, start by selecting a workspace for it to live in')
      return
    }
    const name = window.prompt('What should your Storyform to be called?', 'New Untitled Form')
    if (name) {
      postExperience({ name, thumbnail: BLURHASHES[Math.floor(Math.random() * BLURHASHES.length)] })
        .unwrap()
        .then((createdSurvey) => {
          putWorkspace({
            _id: selectedWorkspace._id,
            delta: { items: [createdSurvey, ...(selectedWorkspace.items || [])] },
          })
        })
    }
  }

  const moveExperience = (destinationWorkspace: Workspace) => {
    if (
      !draggedExperience?._id ||
      !selectedWorkspace?._id ||
      destinationWorkspace._id === selectedWorkspace?._id
    ) {
      return
    }
    if (window.confirm('Move to workspace ' + destinationWorkspace.name + '?')) {
      putWorkspace({
        _id: destinationWorkspace._id,
        delta: { items: [draggedExperience, ...(destinationWorkspace.items || [])] },
      })
      putWorkspace({
        _id: selectedWorkspace._id,
        delta: {
          items: [...selectedWorkspace.items].filter((experience) => experience._id !== draggedExperience._id),
        },
      })
    }
  }

  // @todo: deep-duplicate (questions etc not just survey) via api endpoint
  const duplicateExperience = (experience: Survey) => {
    postExperience(experience)
      .unwrap()
      .then((createdSurvey) => {
        putWorkspace({
          _id: selectedWorkspace._id,
          delta: { items: [createdSurvey, ...(selectedWorkspace.items || [])] },
        })
      })
  }

  const privateWorkspaces: Workspace[] = workspaces?.filter((ws) => ws.visibility === 'private') || []

  const sharedWorkspaces: Workspace[] = workspaces?.filter((ws) => ws.visibility === 'private') || []

  const ExperienceRow = ({ experience, index }: { experience: Partial<Survey>; index: number }) => {
    const openExperienceDetails = () => {
      navigate('/dashboard/forms/' + experience._id + '/design')
    }

    if (!experience) {
      return null
    }

    return (
      <Row>
        <Cell style={{ flex: 1, marginRight: 40 }}>
          <WorkspaceName onClick={openExperienceDetails}>{experience?.name}</WorkspaceName>
        </Cell>
        <Cell>{experience.anonymousVoting?.counts.start || 0}</Cell>
        <Cell>
          {(
            (100 * (experience.anonymousVoting?.counts.start || 0)) /
            (experience.anonymousVoting?.counts.view || 1)
          ).toFixed(1)}
          %
        </Cell>
        <Cell>
          {(
            (100 * (experience.anonymousVoting?.counts.completion || 0)) /
            (experience.anonymousVoting?.counts.start || 1)
          ).toFixed(1)}
          %
        </Cell>
      </Row>
    )
  }

  return (
    <StyledWorkspaces>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      />
      <WorkspacesSidebar expanded={sidebarExpanded}>
        <SearchButton onClick={() => setSearchOpen(true)} />
        <PrivateWorkspaces>
          <WorkspaceList
            visibility="private"
            workspaces={privateWorkspaces}
            selectedWorkspace={selectedWorkspace}
            onCreateWorkspace={createWorkspace}
            onMoveExperience={moveExperience}
          />
        </PrivateWorkspaces>

        {IS_PAID_PLAN && (
          <SharedWorkspaces>
            <WorkspaceList
              visibility="shared"
              workspaces={sharedWorkspaces}
              selectedWorkspace={selectedWorkspace}
              onCreateWorkspace={createWorkspace}
              onMoveExperience={moveExperience}
            />
          </SharedWorkspaces>
        )}

        <BottomTray>
          <UsageWidget onUpgrade={() => setUpgradeOpen(true)} />
        </BottomTray>
      </WorkspacesSidebar>

      <Workspace>
        {!selectedWorkspace ? (
          <WorkspaceHeader
            style={{
              marginTop: 40,
              color: '#777a',
              textAlign: 'center',
              backgroundColor: 'unset',
              cursor: 'default !important',
            }}
          >
            No workspace selected
          </WorkspaceHeader>
        ) : (
          <div>
            <Overview>
              <WorkspaceHeader>
                <GrowingInput
                  key={selectedWorkspace._id}
                  style={{
                    fontSize: 16,
                    padding: '10px',
                    border: 'none',
                    borderRadius: 10,
                    transition: '0.2s all',
                    fontWeight: 600,
                    width: 'max-content',
                    minHeight: 16,
                    lineHeight: '16px',
                  }}
                  defaultValue={selectedWorkspace.name}
                  onChange={setWorkspaceName}
                />
                <Tooltip title="Name your workspace" placement="bottom">
                  <Edit sx={{ width: 14, marginLeft: '3px', marginRight: '8px', marginBottom: '1px' }} />
                </Tooltip>
              </WorkspaceHeader>

              {/* <Button
                // onClick={() => setUpgradeOpen(true)}
                onClick={() => alert('Coming soon!')}
                size="small"
                sx={{ fontSize: '12px', color: '#444a' }}
                startIcon={<GroupAdd />}
                variant="text"
              >
                Invite team members
              </Button> */}
              <Button
                onClick={createExperience}
                size="small"
                sx={{
                  fontSize: '12px',
                  color: '#fff',
                  margin: '0 10px',
                  // width: '100%',
                  padding: '8px 12px',
                  backgroundColor: '#33347d',
                  '&:hover': {
                    backgroundColor: '#4446a4',
                  },
                }}
                startIcon={<Add />}
              >
                New Storyform
              </Button>

              <ViewModeToggle>
                <ViewMode
                  selected={viewMode === 'grid'}
                  onClick={viewMode === 'grid' ? undefined : () => setViewMode('grid')}
                >
                  <AppsRounded sx={{ marginRight: '4px' }} /> Grid
                </ViewMode>
                <ViewMode
                  selected={viewMode === 'list'}
                  onClick={viewMode === 'list' ? undefined : () => setViewMode('list')}
                >
                  <FormatListBulletedRounded sx={{ marginRight: '4px' }} /> List
                </ViewMode>
              </ViewModeToggle>
            </Overview>

            {!!selectedWorkspace && (
              <>
                {viewMode === 'grid' && (
                  <ExperienceGrid>
                    <AddCard onClick={createExperience}>
                      <AddRounded sx={{ fontSize: 'inherit' }} />
                      <p style={{ marginTop: 10, fontWeight: 500 }}>New Storyform</p>
                    </AddCard>
                    {selectedWorkspace?.items?.map((survey) => (
                      <ExperiencesGridItem
                        key={'egi-' + survey._id}
                        experience={survey}
                        onDuplicate={() => duplicateExperience(survey)}
                        onDragStart={() => setDraggedExperience(survey)}
                      />
                    ))}
                  </ExperienceGrid>
                )}

                {viewMode === 'list' && (
                  <ExperienceList>
                    <Row style={{ borderTop: 'none', paddingTop: '10px' }}>
                      <ColumnHeader
                        style={{
                          display: 'flex',
                          flex: 1,
                          marginRight: 40,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        Storyform
                      </ColumnHeader>

                      <Tooltip
                        title={
                          'The number of people who started the Storyform (ie. voted on the first question) after viewing it'
                        }
                        placement={'top'}
                      >
                        <ColumnHeader
                          style={{
                            width: 'auto',
                            textDecoration: 'underline 1.5px dotted rgb(170, 170, 170)',
                            textUnderlinePosition: 'under',
                          }}
                        >
                          Engagements
                        </ColumnHeader>
                      </Tooltip>
                      <Tooltip
                        title={
                          'The percentage of people who started the Storyform (ie. voted on the first question) after viewing it'
                        }
                        placement={'top'}
                      >
                        <ColumnHeader
                          style={{
                            width: 'auto',
                            textDecoration: 'underline 1.5px dotted rgb(170, 170, 170)',
                            textUnderlinePosition: 'under',
                          }}
                        >
                          Start rate
                        </ColumnHeader>
                      </Tooltip>
                      <Tooltip
                        title={'The percentage of people who finished the Storyform after starting it'}
                        placement={'top'}
                      >
                        <ColumnHeader
                          style={{
                            width: 'auto',
                            textDecoration: 'underline 1.5px dotted rgb(170, 170, 170)',
                            textUnderlinePosition: 'under',
                          }}
                        >
                          Completion rate
                        </ColumnHeader>
                      </Tooltip>
                    </Row>

                    {selectedWorkspace?.items?.map((experience, index) => (
                      <ExperienceRow key={'ql-' + index} experience={experience} index={index} />
                    ))}
                  </ExperienceList>
                )}
              </>
            )}
          </div>
        )}
      </Workspace>

      {upgradeOpen && <Upgrade handleClose={() => setUpgradeOpen(false)} />}
    </StyledWorkspaces>
  )
}

const StyledWorkspaces = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /* position: fixed; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* background: linear-gradient(45deg, #2dc2c8, #30176b); */
`

const WorkspacesSidebar = styled.div<{ expanded?: boolean }>`
  position: relative;
  top: 0;
  left: 0;
  min-width: ${({ expanded }) => (!expanded ? 250 : 500)}px;
  height: calc(100vh - 50px);
  background: white;
  box-shadow: rgb(0 0 0 / 7%) -1px 0px inset;
`

const WorkspaceHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #33347d;
  font-weight: 700;
  margin-right: 40px;
  border-radius: 10px;

  &:hover {
    background: #33347d10; // 0x40 is 0.25 alpha (rgba)
    cursor: text !important;
  }
`

const Overview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #aaa3;
`

const ExperienceGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`

const AddCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  height: 220px;
  margin: 0 20px 20px 0;
  justify-content: center;
  box-shadow: none;
  font-size: 80px;
  cursor: pointer;
  background: transparent;
  color: #33347d;
  border: 2px dashed #5555;
  border-radius: 8px;
  box-sizing: border-box;
`

const ExperienceList = styled.div`
  max-width: 1200px;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const PrivateWorkspaces = styled.div`
  margin: 20px 0;
`

const SharedWorkspaces = styled.div`
  margin: 20px 0;
`

const Workspace = styled.div`
  flex: 1;
  height: calc(100vh - 50px);
  padding: 20px 30px;
  box-sizing: border-box;
  overflow: auto;
  background: #fafafa;
`

const ViewModeToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #33347d;
`

const ViewMode = styled.p<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 10px;

  ${({ selected }) =>
    selected
      ? `
    color: white;
    background-color: #33347d;
    cursor: default;
  `
      : `
  color: #33347d;
    background-color: transparent;
    cursor: pointer;
  `}
`

const BottomTray = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
`

// todo: merge w/ duplicates
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #aaa3;
`

const Cell = styled.div`
  /* box-sizing: border-box; */
  margin-right: 20px;
  min-width: 120px;
  font-size: 14px;
`

const ColumnHeader = styled(Cell)`
  font-weight: 500;
`

const WorkspaceName = styled.p`
  width: max-content;
  cursor: pointer;
  color: #33347d;
`
