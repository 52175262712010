import { useState } from 'react'
import styled from 'styled-components/macro'
import { useDebounce } from 'utils/useDebounce'
import { DesktopPrompt } from '../SharedDesktopQuestionComponents'
import { Engagements, Submit, Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  editQuestion: any
  survey: Partial<Survey>
}

export function MultipleChoiceQuestion({ question, editQuestion, survey }: Props) {
  const [prompt, setPrompt] = useState(question.prompt)

  useDebounce(prompt, () => editQuestion({ prompt }), 500, true)

  const [choice, setChoice] = useState<number>(-1)

  return (
    <Tile id={question._id}>
      <DesktopPrompt
        color={survey.styles?.questionTextColor || '#fff'}
        placeholder="What would you like to ask?"
        value={prompt}
        onChange={(event: any) => setPrompt(event.target.value)}
      />
      <Choices>
        {question.choices.map((choice: any, index: any) => {
          return <Choice key={index} choice={choice} />
        })}
      </Choices>
      <Engagements>
        <Submit>{'Submit'}</Submit>
      </Engagements>
    </Tile>
  )

  function Choice(props: { choice: Choice }) {
    return (
      <ChoiceContainer
        selected={props.choice.value === choice}
        onClick={() => (choice === props.choice.value ? setChoice(-1) : setChoice(props.choice.value))}
      >
        {props.choice.image?.url && <ChoiceImage src={props.choice.image.url} />}
        <ChoiceLabel>{props.choice.label}</ChoiceLabel>
      </ChoiceContainer>
    )
  }
}

const Choices = styled.div`
  padding: 0px;
`

const ChoiceContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px;
  width: calc(100% - 80px);
  padding: 6px 40px;
  background: ${(props) => (props.selected ? 'rgba(255,255,255,0.7)' : 'none')};
  color: ${(props) => (props.selected ? '#08324C' : '#ffffff')};
  transition: all 0.25s;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImage = styled.img`
  align-self: center;
  height: 68px;
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  border-radius: 40px;
  border: solid 2px #ffffff;
`

const ChoiceLabel = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
`
