export const storyform = {
  light: {
    centerSplit: '#33347d',
    inputBackground: '#ffffff',
    splashBackgroundColor: 'linear-gradient(45deg, #165e8b, #81cbe3)',
    splashBackground: 'url("/img/branding/splash_light.png")',
    engagement: '#33347d',
    engagementAlt: '#e814d8',
    inputLogo: 'url("/img/storyforms_logo_fox.png")',
    inputLogoFilter: 'brightness(0.7)',
    inputHeader: '#1b113f',
    inputSubheader: '#33347d',
    inputPlaceholder: '#33347d',
    inputActive: '#1b113f',
    inputEntering: '#ff6400',
    inputValid: '#00ba00',
    subaction: '#33347d',
    subactionFocus: '#1b113f',
    submitButton: '#33347d',
    submitButtonHover: '#4446a4',
    submitButtonText: '#ffffff',
  },
  dark: {
    centerSplit: '#2c155d',
    inputBackground: 'linear-gradient(#1b054ef2, #955ad6)',
    splashBackgroundColor: 'linear-gradient(45deg, #165e8b, #81cbe3)',
    splashBackground: 'url("/img/branding/splash_dark.png")',
    engagement: '#ffffff',
    engagementAlt: '#e814d8',
    inputLogo: 'url("/img/storyforms_logo_fox.png")',
    inputLogoFilter: 'brightness(3)',
    inputHeader: '#ffffff',
    inputSubheader: '#ffffff',
    inputPlaceholder: '#afc8d8',
    inputActive: '#ffffff',
    inputEntering: '#ff6400',
    inputValid: '#00ba00',
    subaction: '#afc8d8',
    subactionFocus: '#ffffff',
    submitButton: '#e8e2ff',
    submitButtonHover: '#ffffff',
    submitButtonText: '#322172',
  },
}
