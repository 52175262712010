export const getImagePositionDesktopStyles = (question) => {
  switch (question?.imagePosition || '') {
    case 'float-right':
      return { flexDirection: 'row-reverse' }
    case 'float-left':
      return {}
    case 'float-center':
      return {}
    case 'full-right':
      return { flexDirection: 'row-reverse' }
    case 'full-center':
      return {}
    case 'full-left':
    default:
      return {}
  }
}
