import { Topbar } from 'components/Elements/Topbar'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import usePageTitle from 'utils/usePageTitle'

export const Users = () => {
  usePageTitle('Users | Storyforms')

  return (
    <>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      />
    </>
  )
}
