import { Brush, HideImage } from '@mui/icons-material'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { useAppDispatch } from 'app/hooks'
import { QuestionImagePicker } from 'components/ImageHandling/QuestionImagePicker'
import { GrowingInput } from 'components/Input/GrowingInput'
import { useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'utils/QuestionUtils'
import { useDebounce } from 'utils/useDebounce'
import {
  DesktopEngagement,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  EditButtonBlurWrapper,
  ImageWrapper,
  KeyboardKey,
} from '../SharedDesktopQuestionComponents'
import { Background, OpacityBottom, OpacityTop, Prompt, Submit, Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  editQuestion: any
  survey: Partial<Survey>
  orientation?: 'desktop' | 'mobile'
}

export function RateQuestion({ question, editQuestion, survey, orientation = 'mobile' }: Props) {
  const [prompt, setPrompt] = useState(question.prompt)
  const [leftLabel, setLeftLabel] = useState((question.choices?.length > 0 && question.choices[0].label) || '')
  const [rightLabel, setRightLabel] = useState((question.choices?.length > 1 && question.choices[1].label) || '')

  const dispatch = useAppDispatch()

  useDebounce(
    [prompt, leftLabel, rightLabel],
    () =>
      editQuestion({
        prompt,
        choices: [
          { label: leftLabel, value: 0 },
          { label: rightLabel, value: 1 },
        ],
      }),
    500,
    true
  )

  const [slider, setSlider] = useState<number>(0.5)

  const Slider = () => {
    return (
      <SlideBar value={slider}>
        <Selector value={slider} />
      </SlideBar>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        {question.image?.url && (
          <ImageWrapper style={question.imagePosition?.startsWith('float') ? { maxHeight: '70%' } : undefined}>
            <DesktopImage
              style={
                question.imagePosition?.startsWith('float')
                  ? { height: 'unset', maxHeight: '100%', width: 'unset', maxWidth: '359px' }
                  : undefined
              }
              onClick={() => dispatch(photoEditorOpened({ question }))}
              src={question.image?.url || `${window.location.origin}/img/image_handling/add_image.png`}
            />
            <EditButtonBlurWrapper>
              <Tooltip title="Edit image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    dispatch(photoEditorOpened({ question }))
                  }}
                >
                  <Brush />
                </Fab>
              </Tooltip>

              <Tooltip title="Remove image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                  }}
                  onClick={() => {
                    if (window.confirm('Are you sure you want to remove this image?')) {
                      editQuestion({ image: null })
                    }
                  }}
                >
                  <HideImage />
                </Fab>
              </Tooltip>
            </EditButtonBlurWrapper>
          </ImageWrapper>
        )}
        {!question.image?.url && (
          <QuestionImagePicker
            style={
              question.imagePosition?.startsWith('float') ? { maxHeight: '70%', width: 'unset' } : { width: 359 }
            }
            question={question}
            onChangeImageUrl={(url) => editQuestion({ image: { url } })}
          />
        )}
        <DesktopField style={question.imagePosition?.startsWith('float') ? { flex: 0.7, margin: 0 } : undefined}>
          <DesktopPrompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <DesktopEngagements>
            <Slider />
            <RateLabels>
              <GrowingInput
                key="test0"
                style={{ textAlign: 'left', marginRight: '5%' }}
                defaultValue={leftLabel}
                onChange={setLeftLabel}
              />
              <GrowingInput
                style={{ textAlign: 'right', marginLeft: '5%' }}
                defaultValue={rightLabel}
                onChange={setRightLabel}
              />
            </RateLabels>
            <DesktopEngagement style={{ color: '{survey.styles?.answerTextColor', display: 'flex' }}>
              <KeyboardKey>↵</KeyboardKey>Submit
            </DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt
            style={{ height: '100%' }}
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Slider />
            <RateLabels>
              <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
                {question.choices[0]?.label || ''}
              </p>
              <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
                {question.choices[1]?.label || ''}
              </p>
            </RateLabels>
            <Submit>{'Submit'}</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const Engagements = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;
`

const SlideBar = styled.div<{ value: number }>`
  position: relative;
  height: 10px;
  border-radius: 5px;
  width: calc(100% - 80px);
  margin-bottom: 20px;
  background: linear-gradient(90deg, #0000ff 1%, #e2ccdf 45%, #e5b7c7 55%, #ff0000 100%);
  z-index: 2;
`

const Selector = styled.div<{ value: number }>`
  position: absolute;
  height: 30px;
  width: 30px;
  margin-left: calc(${(props) => props.value * 100 + '%'} - 15px);
  margin-top: -10px;
  background: #ffffff;
  border-radius: 15px;
`

const RateLabels = styled.div`
  width: calc(100% - 80px);
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 40px;
`

const DesktopEngagements = styled.div`
  display: block;
  margin-top: 30px;
`
