import * as d3 from 'd3'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

const testData = []
for (let i = 0; i < 50; i++) {
  testData.push(Math.random() * 100)
}

export const ExperienceJourney = ({
  // data = [100, 100, 100],
  // data = [100, 90, 97, 100, 44, 75, 93, 88, 95, 100, 90, 97, 84, 100, 88, 75, 93, 88, 95, 80, 40],
  data = testData,
  width = 800,
  height = 200,
  startColor = '#10d731',
  middleColor = '#ffa500',
  endColor = '#d72f00',
  animate = true,
}: {
  data?: any[]
  width?: number
  height?: number
  startColor?: string
  middleColor?: string
  endColor?: string
  animate?: boolean
}) => {
  const [activeTooltip, setActiveTooltip] = useState<{ event: any; datapoint: any; index: number }>(undefined)

  const svgRef = useRef(null)

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          red: parseInt(result[1], 16),
          green: parseInt(result[2], 16),
          blue: parseInt(result[3], 16),
        }
      : null
  }

  // param: the displacement along the gradient, as a decimal from 0 to 1
  const getInterpolatedColor = (decimalDisplacement) => {
    var color1 = hexToRgb(startColor)
    var color2 = hexToRgb(middleColor)

    // Do we have 3 colors for the gradient? Need to adjust the params.

    // Find which interval to use and adjust the fade percentage
    var sectionDisplacement
    if (decimalDisplacement >= 0.65) {
      // gradient middle color is centered 65% down the gradient
      color1 = color2
      color2 = hexToRgb(endColor)
      sectionDisplacement = (decimalDisplacement - 0.65) / 0.35
    } else {
      sectionDisplacement = decimalDisplacement / 0.65
    }

    var diffRed = color2.red - color1.red
    var diffGreen = color2.green - color1.green
    var diffBlue = color2.blue - color1.blue

    var gradient = {
      red: parseInt('' + Math.floor(color1.red + diffRed * sectionDisplacement), 10),
      green: parseInt('' + Math.floor(color1.green + diffGreen * sectionDisplacement), 10),
      blue: parseInt('' + Math.floor(color1.blue + diffBlue * sectionDisplacement), 10),
    }

    return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')'
  }

  // calculate values used to determine spacing of datapoints
  const min = Math.min(...data)
  const max = Math.max(...data)
  const valueDelta = max - min || 1
  const dataCount = data.length
  const xPixelDelta = 800 / (dataCount - 1)
  const yPixelDeltas = data.map((value, index) => {
    const yValueDelta = max - value
    const yPixelDelta = 5 + height * (yValueDelta / valueDelta)
    return [xPixelDelta * index, yPixelDelta]
  })

  useEffect(() => {
    // specify the curve function
    var bezierLine = d3
      .line()
      .x(function (d) {
        return d[0]
      })
      .y(function (d) {
        return d[1]
      })
      // .curve(d3.curveCatmullRom.alpha(0.7))
      // .curve(d3.curveBumpX)
      .curve(data.length > 20 ? d3.curveMonotoneX : d3.curveCatmullRom.alpha(0.7))

    // create the svg canvas
    svgRef.current = d3
      .select('#bezier-svg')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('overflow', 'visible')

    // create the gradient (green-to-red for high-to-low data values)
    var defs = svgRef.current.append('defs')

    // configure the linear gradent (top-to-bottom)
    var gradient = defs
      .append('linearGradient')
      .attr('id', 'svgGradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')

    // color at the start of the gradient
    gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', startColor)
      .attr('stop-opacity', 1)

    // color in the middle of the gradient
    gradient.append('stop').attr('offset', '65%').attr('stop-color', middleColor).attr('stop-opacity', 1)

    // color at the end of the gradient
    gradient
      .append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', endColor)
      .attr('stop-opacity', 1)

    // create smooth curve through datapoints. to avoid a bug whereby the svg line does not appear when all y-values are the same, we check for this edge case and add a miniscule amount to one of the values
    const first = yPixelDeltas[0]
    let different = false
    for (let delta of yPixelDeltas) {
      if (delta[1] != first[1]) {
        different = true
        break
      }
    }
    if (!different) {
      yPixelDeltas[0] = [first[0], first[1] + 0.001]
    }
    var dataLine = svgRef.current
      .append('path')
      .attr('d', bezierLine(yPixelDeltas))
      .attr('stroke', different ? 'url(#svgGradient)' : startColor)
      .attr('stroke-width', 3)
      .attr('fill', 'none')

    // animate in the curve
    if (animate) {
      dataLine
        .transition()
        .duration(3000)
        .attrTween('stroke-dasharray', function () {
          var len = this.getTotalLength()
          return function (t) {
            return d3.interpolateString('0,' + len, len + ',0')(t)
          }
        })
    }

    // add datapoints. x base value starts at 0 at increases by 80 with each datapoint. x values are then decremented by 12 for percentage text and 8 for question number text. lower y values correspond with higher placement on the graph. 5 is the base value for the highest percentage value and 5 + height is the base value for the lowest percentage value. this is then decremented by 12 for percentage text and incremented by 20 for question number text.

    const drawDatapoints = () => {
      data.forEach((dataValue, index) => {
        const datapoint = {
          value: dataValue,
          valueString: `${Math.max(dataValue, 0).toFixed(0)}%`,
          label: `Q${index + 1}`,
        }
        drawDatapoint(datapoint, index, data.length < 20)
        const svgDatapoint = document.getElementById(`svg-datapoint-${index}`)
        svgDatapoint.addEventListener('mouseenter', (event) => {
          setActiveTooltip({
            event,
            datapoint: datapoint,
            index,
          })
        })
        svgDatapoint.addEventListener('mouseleave', (event) => {
          setActiveTooltip(undefined)
        })
      })
    }

    const drawDatapoint = (
      datapoint: { value: number; valueString?: string; label?: string },
      index: number,
      showText?: boolean
    ) => {
      const xPixelOffset = xPixelDelta * index
      const yValueDelta = max - datapoint.value
      const yPixelOffset = 5 + height * (yValueDelta / valueDelta)
      svgRef.current
        .append('circle')
        .attr('cx', xPixelOffset)
        .attr('cy', yPixelOffset)
        .attr('r', 5)
        .attr('fill', getInterpolatedColor((1.0 * yValueDelta) / valueDelta))
      svgRef.current
        .append('circle')
        .attr('cx', xPixelOffset)
        .attr('cy', yPixelOffset)
        .attr('r', 3)
        .attr('fill', '#fff')
      svgRef.current
        .append('circle')
        .attr('class', 'svg-datapoint')
        .attr('id', `svg-datapoint-${index}`)
        .attr('cx', xPixelOffset)
        .attr('cy', yPixelOffset)
        .attr('r', 12)
        .attr('fill', 'transparent')

      if (showText) {
        svgRef.current
          .append('text')
          .attr('x', xPixelOffset - 12)
          .attr('y', yPixelOffset - 12)
          .attr('font-family', 'Montserrat')
          .attr('font-size', '14px')
          .text(datapoint.valueString || `${Math.max(datapoint.value, 0).toFixed(0)}%`)

        svgRef.current
          .append('text')
          .attr('x', xPixelOffset - 8)
          .attr('y', yPixelOffset + 20)
          .attr('font-family', 'Montserrat')
          .attr('font-size', '12px')
          .attr('fill', '#777')
          .text(datapoint.label || `Q${index + 1}`)
      }
    }

    drawDatapoints()

    // remove the svg when unmounting this component
    return () => {
      svgRef.current.remove()
    }
  }, [])

  return (
    <BezierSvg id="bezier-svg">
      {activeTooltip && (
        <DataTooltip
          style={{
            position: 'absolute',
            top: activeTooltip.event.offsetY + 30,
            left: activeTooltip.index * xPixelDelta - 75,
          }}
          position="bottom"
        >
          <p>
            <span style={{ fontWeight: 500, marginRight: 10 }}>{activeTooltip.datapoint.label}. </span>
            <span>{`${Math.max(activeTooltip.datapoint.value, 0).toFixed(1)}%`}</span>
          </p>
        </DataTooltip>
      )}
    </BezierSvg>
  )
}

const BezierSvg = styled.div`
  position: relative;
`

const DataTooltip = styled.span<{ position: 'left' | 'right' | 'top' | 'bottom' }>`
  pointer-events: none;
  min-width: 120px;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px 10px;
  position: absolute;
  z-index: 999;
  top: -15px;
  font-size: 12px;
  font-weight: normal;
  left: ${({ position }) => (position === 'right' ? '110%' : 'unset')};
  right: ${({ position }) => (position === 'left' ? '110%' : 'unset')};

  ${({ position }) =>
    position === 'right' || position === 'bottom' || position === 'top' ? '&:after' : '&:before'} {
    content: '';
    position: absolute;
    top: ${({ position }) => (position === 'bottom' ? '-5px' : position === 'top' ? 'unset' : '25px')};
    bottom: ${({ position }) => (position === 'top' ? '-5px' : 'unset')};

    margin-top: ${({ position }) => (position === 'top' ? 'unset' : '-5px')};
    margin-bottom: ${({ position }) => (position === 'bottom' ? 'unset' : '-5px')};
    border-width: 5px;
    border-style: solid;
    left: ${({ position }) =>
      position === 'bottom' || position === 'top' ? '70px' : position === 'right' ? 'unset' : '100%'};
    right: ${({ position }) => (position === 'left' ? 'unset' : '100%')};
    border-color: ${({ position }) =>
      position === 'right'
        ? 'transparent #000000 transparent transparent'
        : 'transparent transparent transparent #000000'};
    transform: ${({ position }) =>
      position === 'bottom' ? 'rotate(-90deg)' : position === 'top' ? 'rotate(90deg)' : 'unset'};
  }
`
