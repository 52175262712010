import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    putEnding: build.mutation({
      query: ({ _id, delta }) => ({
        url: `question/${_id}`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['ending'],
    }),

    postEnding: build.mutation<Question, Partial<Question>>({
      query: (ending) => ({
        url: `question/`,
        method: 'POST',
        body: {
          ...ending,
          type: 'ending',
        },
      }),
      invalidatesTags: ['ending'],
    }),

    deleteEnding: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        url: `question/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ending'],
    }),
  }),
})

export const { usePutEndingMutation, usePostEndingMutation, useDeleteEndingMutation } = api
