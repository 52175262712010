import { useEffect, useState } from 'react'

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Account } from 'pages/Admin/Account'
import { Users } from 'pages/Admin/Users'
import { Support } from './Admin/Support'

import { SuspendedAccount } from './Workspaces/SuspendedAccount'

import { SurveyReportViewer } from 'components/PDF/SurveyReportViewer'
import { useGetUserQuery } from 'services/userApi'
import { useKey } from 'utils/useKey'
import { Integrations } from './Admin/Integrations'
import { SurveyDetails } from './Engage/SurveyDetails'
import { Shenanigans } from './Playground/Shenanigans'
import { MixedSearch } from './Workspaces/MixedSearch'
import { Workspaces } from './Workspaces/Workspaces'

export const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [searchOpen, setSearchOpen] = useState(false)

  const { data: user, isLoading: userLoading } = useGetUserQuery()

  useEffect(() => {
    if (location.pathname.indexOf('/engage/') !== -1) {
      navigate(location.pathname.replaceAll('/engage/', '/forms/'))
    }
  }, [location, navigate])

  useKey('k', () => setSearchOpen(!searchOpen), true)

  return (
    <Panel>
      {/* <Sidebar /> */}
      {user?.suspended && <SuspendedAccount />}
      {searchOpen && <MixedSearch onClose={() => setSearchOpen(false)} />}
      <Content>
        <Routes>
          <Route path="/workspaces/:workspaceId" element={<Workspaces setSearchOpen={setSearchOpen} />} />
          <Route path="/workspaces/" element={<Workspaces setSearchOpen={setSearchOpen} />} />
          <Route path="/forms/:surveyId/*" element={<SurveyDetails />} />
          <Route path="/forms/reports/:surveyId" element={<SurveyReportViewer />} />
          <Route path="/playground" element={<Shenanigans />} />

          <Route path="/account/*" element={<Account />} />
          <Route path="/users" element={<Users />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/support" element={<Support />} />

          {/* default route */}
          <Route path="/*" element={<Navigate to="/dashboard/workspaces" />} />
        </Routes>
      </Content>
    </Panel>
  )
}

const Panel = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Content = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  padding-top: 50px;
  width: 100%;
  overflow-y: auto;
`
