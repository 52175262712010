import { useAppDispatch, useAppSelector } from 'app/hooks'
import React, { useEffect, useRef, useState } from 'react'
import { asyncConfirmCodeUpdatePassword, asyncForgotPassword, forgotPasswordFlowEnded } from 'services/authSlice'
import {
  BottomActionText,
  Brand,
  Input,
  InputSubAction,
  LoginBox,
  Seperation,
  Subheader,
  Submit,
} from './LoginComponents'

export const ResetPassword = ({ style }: { style?: any }) => {
  const dispatch = useAppDispatch()
  const { recoveryUsername } = useAppSelector((state) => state.auth)
  const [code, setCode] = useState<string>('')
  const [codeStatus, setCodeStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [password, setPassword] = useState<string>('')
  const [passwordStatus, setPasswordStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [submitting, setSubmitting] = useState(false)

  const codeInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const newStatus = code.length === 0 ? 'default' : code.length < 6 ? 'entering' : 'valid'
    setCodeStatus(newStatus)
  }, [code])

  useEffect(() => {
    const newStatus = password.length === 0 ? 'default' : password.length < 8 ? 'entering' : 'valid'
    setPasswordStatus(newStatus)
  }, [password])

  useEffect(() => {
    const newStatus = confirmPassword.length === 0 ? 'default' : confirmPassword.length < 8 ? 'entering' : 'valid'
    setConfirmPasswordStatus(newStatus)
  }, [confirmPassword])

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (codeStatus !== 'valid') return alert('Please make sure your verification code is correct.')
    if (passwordStatus !== 'valid') return alert('Please make sure your password is at least 8 characters long.')
    if (password !== confirmPassword) return alert('Please make sure your passwords match.')
    setSubmitting(true)
    dispatch(
      asyncConfirmCodeUpdatePassword({
        username: recoveryUsername,
        code: code,
        newPassword: password,
      })
    )
      .then((result: any) => {
        if (!result.payload?.success) {
          alert('Password reset failed. Please check your verification code.')
          setSubmitting(false)
          codeInput.current.focus()
          return
        }
        setCode('')
        setPassword('')
        setConfirmPassword('')
        alert('Your password has been reset successfully. You can now login with your new password.')
        setSubmitting(false)
        dispatch(forgotPasswordFlowEnded())
      })
      .catch((e) => {
        alert('Unknown error. Please contact support.')
        console.error(e)
        setSubmitting(false)
      })
  }

  const handleCancel = () => {
    dispatch(forgotPasswordFlowEnded())
  }

  const handleResendCode = () => {
    dispatch(asyncForgotPassword({ username: recoveryUsername }))
      .then((result: any) => {
        if (result.error) {
          return alert(result.error.message)
        } else {
          return alert('A new code has been sent to the email address you provided')
        }
      })
      .catch((e) => {
        alert(`Unable to send a new code. Please contact us if this issue persists.\n\nError info: ${e}`)
      })
  }

  return (
    <LoginBox>
      <Brand />
      <Subheader>Please enter the verification code we emailed you, and enter a new password</Subheader>

      <form autoComplete="off" spellCheck="false">
        <Input
          key="verificationCode"
          ref={codeInput}
          status={codeStatus}
          name="verificationCode"
          type="text"
          placeholder="Verification code"
          autoComplete="none"
          value={code}
          onChange={(e) => setCode(e.target.value.trim())}
        />
        <InputSubAction onClick={handleResendCode}>Resend code</InputSubAction>

        <Input
          key="password"
          status={passwordStatus}
          name="password"
          type="password"
          placeholder="New password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value.trim())}
        />

        <Input
          key="confirmPassword"
          status={confirmPasswordStatus}
          name="confirmPassword"
          type="password"
          placeholder="Confirm password"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value.trim())}
        />

        <div>
          <Submit type="submit" value="Reset password" disabled={submitting} onClick={handleSubmit} />
          <Seperation>or</Seperation>
          <BottomActionText onClick={handleCancel}>return to sign in</BottomActionText>
        </div>
      </form>
    </LoginBox>
  )
}
