import { BooleanResult } from 'components/Question/QuestionMetrics/BooleanResult'
import { MultipleChoiceResult } from 'components/Question/QuestionMetrics/MultipleChoiceResult'
import { RankResult } from 'components/Question/QuestionMetrics/RankResult'
import { RateResult } from 'components/Question/QuestionMetrics/RateResult'
import { TextResult } from 'components/Question/QuestionMetrics/TextResult'
import { BooleanQuestion } from 'components/Question/QuestionPreview/BooleanQuestion'
import { CardChoiceQuestion } from 'components/Question/QuestionPreview/CardChoiceQuestion'
import { ImageChoiceQuestion } from 'components/Question/QuestionPreview/ImageChoiceQuestion'
import { MultipleChoiceQuestion } from 'components/Question/QuestionPreview/MultipleChoiceQuestion'
import { RankQuestion } from 'components/Question/QuestionPreview/RankQuestion'
import { RateQuestion } from 'components/Question/QuestionPreview/RateQuestion'
import { TextQuestion } from 'components/Question/QuestionPreview/TextQuestion'
import { usePutQuestionMutation } from 'services/questionApi'
import { EndingQuestion } from './EndingQuestion'
import { IntroQuestion } from './IntroQuestion'

interface Props {
  question: Question
  survey: Partial<Survey>
  setStagingSurvey?: any
  orientation?: 'desktop' | 'mobile'
  stats?: boolean
}

export const Question = ({ question, survey, setStagingSurvey, orientation = 'mobile', stats = true }: Props) => {
  const [editQuestion] = usePutQuestionMutation()

  const editQuestionAndRefreshSurvey = (delta) => {
    editQuestion({ _id: question._id, delta })
    if (setStagingSurvey) {
      setStagingSurvey((currentSurvey: any) => {
        return {
          ...currentSurvey,
          questions: currentSurvey.questions.map((current) =>
            current._id === question._id ? { ...current, ...delta } : current
          ),
        }
      })
    }
  }

  if (question.votes?.total > 0 && stats) {
    switch (question.type) {
      case 'boolean':
        return <BooleanResult question={question} survey={survey} />
      case 'multiple-choice':
        return <MultipleChoiceResult question={question} survey={survey} />
      case 'image-choice':
        return <MultipleChoiceResult question={question} survey={survey} />
      case 'rank':
        return <RankResult question={question} survey={survey} />
      case 'image-rank':
        return <RankResult question={question} survey={survey} />
      case 'rate':
        return <RateResult question={question} survey={survey} />
      case 'text':
        return <TextResult question={question} survey={survey} />
      default:
        return <></>
    }
  } else {
    switch (question.type) {
      case 'intro':
        return (
          <IntroQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'boolean':
        return (
          <BooleanQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'multiple-choice':
        return (
          <MultipleChoiceQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
          />
        )
      case 'image-choice':
        return (
          <ImageChoiceQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'rank':
        return (
          <RankQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'image-rank':
        return (
          <ImageChoiceQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'rate':
        return (
          <RateQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'text':
        return (
          <TextQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'card-multiple-choice':
        return (
          <CardChoiceQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      case 'ending':
        return (
          <EndingQuestion
            key={question._id}
            question={question}
            editQuestion={editQuestionAndRefreshSurvey}
            survey={survey}
            orientation={orientation}
          />
        )
      default:
        return <></>
    }
  }
}
