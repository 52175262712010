import { decode } from 'blurhash'
import { MutableRefObject, useRef } from 'react'

export const useBlurhash: (
  getBlurhash?: () => string
) => [MutableRefObject<HTMLCanvasElement | null>, () => void] = (getBlurhash?) => {
  const blurhashCanvasRef = useRef(null)

  const drawBlurhash = () => {
    try {
      const blurhash = getBlurhash ? getBlurhash() : getRandomBlurhash()
      const pixels = decode(blurhash, 390, 610)
      const canvas = blurhashCanvasRef.current
      const ctx = canvas.getContext('2d')
      const imageData = ctx.createImageData(390, 610)
      imageData.data.set(pixels)
      ctx.putImageData(imageData, 0, 0)
    } catch (err) {
      console.error(err)
    }
  }

  const getRandomBlurhash = (length: number = 16) => {
    let result = 'JQ04nL' // todo: choose from palette of avg colors, ditto for other components of the algorithm
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$%*+,-.:;=?@[]^_{|}~'
    const charactersLength = characters.length
    let counter = 6
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  return [blurhashCanvasRef, drawBlurhash]
}
