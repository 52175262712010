import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Fab, Tooltip } from '@mui/material'
import styled from 'styled-components/macro'

interface Props {
  expanded: boolean
  position: 'left' | 'right'
  width?: number
  children?: React.ReactNode
  onToggleOpen?: any
}

export const Sidebar = ({ expanded, position, width = 250, children, onToggleOpen }: Props) => {
  return (
    <StyledSidebar expanded={expanded} position={position} width={width}>
      <OverflowWrapper expanded={expanded} position={position}>
        <SidebarContents position={position} width={width}>
          {children}
        </SidebarContents>
      </OverflowWrapper>
      <SidebarToggle position={position} expanded={expanded} onClick={onToggleOpen} />
    </StyledSidebar>
  )
}

const SidebarToggle = ({
  position,
  expanded,
  onClick,
}: {
  position: 'left' | 'right'
  expanded: boolean
  onClick?: any
}) => (
  <Tooltip title={expanded ? 'Close sidebar' : 'Open sidebar'} placement={position === 'left' ? 'right' : 'left'}>
    <Fab
      size="small"
      sx={{
        position: 'absolute',
        bottom: 20,
        right: position === 'left' ? '-60px' : 'unset',
        left: position === 'right' ? '-60px' : 'unset',
        color: '#444',
        transform: expanded ? 'rotateY(0deg)' : 'rotateY(180deg)',
        zIndex: 900,
      }}
      onClick={onClick}
    >
      {position === 'left' ? <ChevronLeft /> : <ChevronRight />}
    </Fab>
  </Tooltip>
)

const StyledSidebar = styled.div<{ width: number; expanded?: boolean; position?: 'left' | 'right' }>`
  position: relative;
  height: calc(100vh - 50px);
  width: ${({ width, expanded }) => (!expanded ? 0 : width)}px;
  transition: 300ms width ease;
  background: white;
  ${({ position }) => (position === 'left' ? 'border-right' : 'border-left')}: 1px solid #e5e5e5;
`

const OverflowWrapper = styled.div<{ position: 'left' | 'right'; expanded?: boolean }>`
  position: relative;
  height: 100%;
  float: ${({ position }) => (position === 'left' ? 'right' : 'left')};
  overflow: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
`

const SidebarContents = styled.div<{ width: number; expanded?: boolean; position?: 'left' | 'right' }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: ${({ width }) => width}px;
  height: 100%;
  transition: 300ms ease all;
`

export const SidebarDivision = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #dddddd solid;
`

export const SidebarElementX = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 14px;
  width: 100%;
  box-sizing: border-box;
`
