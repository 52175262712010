export const appendCountingSuffix = (choice: number) => {
  if (choice > 10 && choice < 21) return `${choice}th`
  if (choice % 10 === 1) return `${choice}st`
  if (choice % 10 === 2) return `${choice}nd`
  if (choice % 10 === 3) return `${choice}rd`
  else return `${choice}th`
}

// Supports international numbers and preserves the country code (US included). Input formats are eg. 5556667777 or +15556667777. S/O https://stackoverflow.com/a/8358141
export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

const gsmCodePoints = new Set([
  0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a,
  0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038,
  0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046,
  0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052, 0x0053, 0x0054,
  0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063,
  0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f, 0x0070, 0x0071,
  0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1,
  0x00a3, 0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9, 0x00d1, 0x00d6, 0x00d8, 0x00dc,
  0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
  0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9, 0x20ac,
])

export const listNonGSM7Characters = (text: string) => {
  const unicodeChars = new Set<string>([])
  for (const s of text) {
    const codePoint = s.codePointAt(0)
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      unicodeChars.add(s)
    }
  }
  return Array.from(unicodeChars)
}

export const isGSM7Message = (text: string) => {
  for (const s of text) {
    const codePoint = s.codePointAt(0)
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      return false
    }
  }
  return true
}

export const replaceNonGSM7Characters = (text: string) => {
  return text
    .replaceAll('`', "'")
    .replaceAll('´', "'")
    .replaceAll('‘', "'")
    .replaceAll('’', "'")
    .replaceAll('″', '"')
    .replaceAll('‶', '"')
    .replaceAll('”', '"')
    .replaceAll('“', '"')
    .replaceAll('…', '...')
}
// S/O https://stackoverflow.com/a/12673229
// const isGSMAlphabet = (text: string) => {
//   const regexp = new RegExp(
//     '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$'
//   )
//   'hi'.match(regexp)
//   return regexp.test(text)
// }
