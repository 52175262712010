import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { QuestionVitals } from './Question/QuestionVitals'
import { SurveyVitals } from './SurveyVitals'

Font.register({
  family: 'Georgia',
  fonts: [
    { src: '/fonts/Georgia/georgia.ttf', fontWeight: 400 },
    { src: '/fonts/Georgia/georgiab.ttf', fontWeight: 700 },
    { src: '/fonts/Georgia/georgiai.ttf', fontWeight: 400, fontStyle: 'italic' },
  ],
})

export const SurveyReportPDF = ({
  survey,
  gaugeChartRender,
  experienceJourneyRender,
  rankQuestionAggregates,
  textQuestionResponses,
  tagCloudRenders,
}: {
  survey: Survey
  gaugeChartRender?: any
  experienceJourneyRender?: any
  rankQuestionAggregates?: any
  textQuestionResponses?: string[]
  tagCloudRenders
}) => {
  let previousTotal = survey.anonymousVoting?.counts.view || 0
  const questionsWithDropoff = survey
    .questions!.filter((question) => question.type !== 'intro')
    .map((question, index) => {
      const total = question.votes?.total || 0
      const dropoffCount = previousTotal - total
      const dropoffRate = !previousTotal ? 0 : Math.abs(dropoffCount / previousTotal)
      previousTotal = total
      return { ...question, dropoffCount, dropoffRate }
    })

  const Section = ({ style, children }: { style?: any; children?: any }) => (
    <View style={{ padding: '20px 40px', ...style }}>{children}</View>
  )

  const Spacer = ({ style }: { style?: any }) => (
    <View style={{ borderBottom: '1px solid #ddd', margin: '20px 40px', ...style }}></View>
  )

  const SmallText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 12, ...style }}>{children}</Text>
  )

  const MediumText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 14, ...style }}>{children}</Text>
  )

  const LargeText = ({ style, children }: { style?: any; children?: any }) => (
    <Text style={{ fontSize: 16, ...style }}>{children}</Text>
  )

  return (
    <Document title={`Results - ${survey.name || 'Untitled Survey'}`}>
      <Page style={styles.page} wrap>
        {/* document header */}
        <Section style={{ backgroundColor: '#000040', marginBottom: 20, marginTop: -40 }}>
          <LargeText style={{ margin: '10px 0', color: '#fff' }}>{survey.name || 'Untitled Storyform'}</LargeText>
        </Section>

        {/* page number footer */}
        <Text
          style={{ position: 'absolute', bottom: 10, right: 10, fontSize: 10, color: '#777' }}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />

        {/* branding footer */}
        <View
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: 10,
            width: '100%',
            color: '#777',
          }}
          fixed
        >
          <Image
            // src={'/img/branding/stars_light.png'}
            src={'/img/storyforms_logo_fox.png'}
            style={{
              height: 36,
              minHeight: 36,
              maxHeight: 36,
              marginTop: 5,
              marginRight: 5,
              color: 'red',
            }}
          />
          <Text style={{ fontSize: 10, letterSpacing: 0.9, color: '#114a6b' }}>Storyforms</Text>
        </View>

        {/* survey metrics section */}
        <SurveyVitals
          survey={survey}
          gaugeChartRender={gaugeChartRender}
          experienceJourneyRender={experienceJourneyRender}
        />

        <Spacer />

        {/* question metrics section */}
        <Section>
          <MediumText style={{ marginBottom: 20 }}>Questions</MediumText>
          {questionsWithDropoff.map((question: any, index) => (
            <QuestionVitals
              key={'qv-' + question._id}
              question={question}
              rankAggregate={
                ['rank', 'image-rank'].indexOf(question.type) !== -1 ? rankQuestionAggregates[question._id] : null
              }
              index={index}
              prerender={question.type !== 'text' ? undefined : tagCloudRenders[`q-${question._id}-render`]}
            />
          ))}
        </Section>
      </Page>

      {/* text question responses section */}
      {/* {textQuestionResponses && textQuestionResponses.length > 0 && (
        <Page style={styles.page} wrap>
          <View fixed>
            <LegacyHeader
              survey={survey}
              id={{ client: survey.client || '', readableId: survey.readableId || '' }}
              first={false}
            ></LegacyHeader>
          </View>
          <View style={[styles.contentPadding]}>
            <Text
              style={{
                fontSize: 20,
                marginBottom: 20,
                textAlign: 'center',
              }}
            >
              Free-response question replies
            </Text>
            {textQuestionResponses.map((response, index) => {
              return <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 10 }}>"{response}"</Text>
            })}
          </View>
          <View fixed style={{ backgroundColor: 'blue', marginTop: 80 }}>
            <Text
              style={{
                position: 'absolute',
                bottom: 18,
                right: 36,
                color: '#aaa',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              report generated {new Date(Date.now()).toLocaleString()}
            </Text>
          </View>
        </Page>
      )} */}
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 20,
    fontFamily: 'Georgia',
    //   display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // background-color: #000;
  },
  content: {
    color: '#08334c',
    justifyContent: 'space-between',
  },
  contentPadding: {
    paddingHorizontal: 36,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
  },
})
