import { useAppDispatch } from 'app/hooks'
import { QuestionImagePicker } from 'components/ImageHandling/QuestionImagePicker'
import { useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'utils/QuestionUtils'
import { useDebounce } from 'utils/useDebounce'

import { Brush, HideImage } from '@mui/icons-material'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import {
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  EditButtonBlurWrapper,
  ImageWrapper,
  KeyboardKey,
} from '../SharedDesktopQuestionComponents'
import { Background, Prompt, Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  editQuestion: any
  survey: Partial<Survey>
  orientation?: 'desktop' | 'mobile'
}

export function BooleanQuestion({ question, editQuestion, survey, orientation = 'mobile' }: Props) {
  const [prompt, setPrompt] = useState(question.prompt)

  const dispatch = useAppDispatch()

  useDebounce(prompt, () => editQuestion({ prompt }), 500, true)

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        {question.image?.url && (
          <ImageWrapper style={question.imagePosition?.startsWith('float') ? { maxHeight: '70%' } : undefined}>
            <DesktopImage
              style={
                question.imagePosition?.startsWith('float')
                  ? { height: 'unset', maxHeight: '100%', width: 'unset', maxWidth: '359px' }
                  : undefined
              }
              onClick={() => dispatch(photoEditorOpened({ question }))}
              src={question.image?.url || `${window.location.origin}/img/image_handling/add_image.png`}
            />
            <EditButtonBlurWrapper>
              <Tooltip title="Edit image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    dispatch(photoEditorOpened({ question }))
                  }}
                >
                  <Brush />
                </Fab>
              </Tooltip>

              <Tooltip title="Remove image" placement="bottom">
                <Fab
                  color="info"
                  size="small"
                  sx={{
                    boxShadow: '0 0 10px #aaaa',
                    color: '#333',
                    bgcolor: '#fff',
                    ':hover': { bgcolor: '#e3e3e3' },
                  }}
                  onClick={() => {
                    if (window.confirm('Are you sure you want to remove this image?')) {
                      editQuestion({ image: null })
                    }
                  }}
                >
                  <HideImage />
                </Fab>
              </Tooltip>
            </EditButtonBlurWrapper>
          </ImageWrapper>
        )}
        {!question.image?.url && (
          <QuestionImagePicker
            style={
              question.imagePosition?.startsWith('float') ? { maxHeight: '70%', width: 'unset' } : { width: 359 }
            }
            question={question}
            onChangeImageUrl={(url) => editQuestion({ image: { url } })}
          />
        )}
        <DesktopField style={question?.imagePosition?.startsWith('float') ? { flex: 0.7, margin: 0 } : undefined}>
          <DesktopPrompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <DesktopEngagements>
            <DesktopEngagement style={{ alignContent: 'center', color: '{survey.styles?.answerTextColor}' }}>
              <KeyboardKey>Y</KeyboardKey>Yes
            </DesktopEngagement>
            <DesktopEngagement color={survey.styles?.answerTextColor}>
              <KeyboardKey>N</KeyboardKey>No
            </DesktopEngagement>
            <DesktopEngagement color={survey.styles?.answerTextColor}>
              <KeyboardKey>?</KeyboardKey>Not sure
            </DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          >
            {question.prompt}
          </Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Engagement
              src={'/img/engagement_icons/no.png'}
              style={{ filter: 'brightness(0.8) sepia(0.6) hue-rotate(25deg)' }}
            />
            <div style={{ textAlign: 'center' }}>
              <Engagement
                src={'/img/engagement_icons/not-sure.png'}
                style={{ height: 45, filter: 'brightness(1.3) saturate(0)' }}
              />
              <p style={{ color: '#333232', fontSize: 12, fontWeight: 'bold' }}>Not sure</p>
            </div>
            <Engagement
              src={'/img/engagement_icons/yes.png'}
              style={{ filter: 'brightness(0.9) hue-rotate(-75deg)' }}
            />
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 100px;
  padding: 40px 40px 20px 40px;
  filter: brightness(3.5);
`

const Engagement = styled.img`
  height: 70px;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`

const DesktopEngagement = styled.div<{ color?: string }>`
  font-size: 16px;
  padding: 10px 20px;
  color: ${({ color }) => color || '#ffffff'};
  border: 1px solid ${({ color }) => color || '#ffffff'};
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;
  width: max-content;

  &:hover {
    background: ${({ color }) => (color || '#ffffff') + '40'}; // 0x40 is 0.25 alpha (rgba)
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
`
