import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { authSlice } from 'services/authSlice'

import { baseApi } from 'services/baseApi'
import { photoEditorSlice } from 'services/photoEditorSlice'

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [photoEditorSlice.name]: photoEditorSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
