import styled from 'styled-components/macro'
import { fillAbsolutely } from 'styles/layout'

export const Shimmer = styled.div<{ x: number; y: number; size: number; delay: number }>`
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  top: ${({ x }) => `${x}%`};
  left: ${({ y }) => `${y}%`};
  opacity: 0;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#99ffff, #99ffff 10%, hsla(180, 100%, 80%, 0) 56%);

  animation: fadein-frames 200ms infinite, scale-frames 8s infinite;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: ${({ delay }) => `${delay}ms`};

  @keyframes scale-frames {
    0% {
      opacity: 0;
      transform: scale3d(0.4, 0.4, 1);
    }

    50% {
      opacity: 0.4;
      transform: scale3d(1.4, 1.4, 1);
    }

    100% {
      opacity: 0;
      transform: scale3d(0.4, 0.4, 1);
    }
  }
`

export const ShimmerField = () => {
  const fromX = Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
  const fromY = Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))

  const sizes = Array.from({ length: 10 }, () => Math.floor((Math.random() + 5) * 10))
  const delay = Array.from({ length: 10 }, () => Math.floor(Math.random() * 4000))

  return (
    <StyledShimmerField>
      {fromX.map((x, index) => (
        <Shimmer key={'shimmer-' + index} x={x} y={fromY[index]} size={sizes[index]} delay={delay[index]} />
      ))}
    </StyledShimmerField>
  )
}

const StyledShimmerField = styled.div`
  ${fillAbsolutely};
`

/*


  $particleBaseSize: 8;

  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
        }

        to {
          transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
        }
      }

    animation-delay: random(4000) + ms;
    }
  }
`


*/
