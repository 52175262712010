import { useEffect } from 'react'

export const useClickOutside = (element: React.MutableRefObject<HTMLDivElement | null>, action: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (element.current && !element.current.contains(event.target)) {
        action()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [element])
}
