import { useEffect } from 'react'

export const useKey = (key, callback, modifyingKey?) => {
  const keyHandler = (event) => {
    if (
      (modifyingKey && event.key === key && (event.metaKey || event.ctrlKey)) || //If we need a modifying key, check if cmd or ctrl is down
      (!modifyingKey && event.key === key) //Else, check if the relevant key is pressed
    ) {
      callback(event)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)

    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [callback])
}
