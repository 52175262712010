import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { Popup } from 'components/Popup'
import { Card } from 'pages/Playground/CardDeckSelector'
import { CSSProperties, useState } from 'react'
import {
  useGetPaymentMethodsQuery,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from 'services/userApi'
import styled from 'styled-components/macro'
import { useKey } from 'utils/useKey'

export const StripePaymentMethod = () => {
  const {
    data: paymentMethods,
    isLoading: paymentMethodsLoading,
    refetch: refreshPaymentMethods,
  } = useGetPaymentMethodsQuery()

  const [removePaymentMethod] = useRemovePaymentMethodMutation()
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation()

  const [showAll, setShowAll] = useState(false)

  useKey('Escape', () => setShowAll(false))

  const PaymentCard = ({ paymentMethod, style }: { paymentMethod: any; style?: any }) => {
    const handleSetDefault = () => {
      setDefaultPaymentMethod(paymentMethod.id)
        .unwrap()
        .then(() => refreshPaymentMethods())
    }

    // const handleEdit = () => {}

    const handleRemove = () => {
      if (window.confirm('Are you sure you want to remove this payment method?')) {
        removePaymentMethod(paymentMethod.id)
        refreshPaymentMethods()
      }
    }

    return (
      <Card
        style={Object.assign(cardStyleOverrides, style)}
        options={{ color: '#0a349c', secondaryColor: '#7b84e8' }}
      >
        <Actions>
          {paymentMethod.default ? (
            <DefaultTag>Default</DefaultTag>
          ) : (
            <Action style={{ marginRight: 'auto' }} onClick={handleSetDefault}>
              Set as default
            </Action>
          )}
          {/* <Action style={{ marginRight: 15 }} onClick={handleEdit}>
            Edit
          </Action> */}
          {!paymentMethod.default && <Action onClick={handleRemove}>Remove</Action>}
        </Actions>

        <CardNumber>**** **** **** {paymentMethod.last4}</CardNumber>

        <Columns>
          <div>
            <CardExpiry>
              Expires {paymentMethod.exp_month < 10 ? '0' : ''}
              {paymentMethod.exp_month || '??'}/{paymentMethod?.exp_year?.toString().slice(2) || '??'}
            </CardExpiry>
            {/* <CardHolder>Spingus Deege</CardHolder> */}
          </div>
          <CardBrand>{paymentMethod.brand}</CardBrand>
        </Columns>
      </Card>
    )
  }

  return (
    <Panel style={{ justifyContent: paymentMethods?.length > 1 ? 'space-between' : 'center' }}>
      {paymentMethodsLoading && <p>Loading...</p>}

      {!paymentMethodsLoading && (
        <>
          {!paymentMethods || (paymentMethods.length === 0 && <p>No payment methods</p>)}

          {paymentMethods?.length > 0 && (
            <>
              <PaymentCard paymentMethod={paymentMethods[0]} />
              {paymentMethods.length > 1 && (
                <Button
                  onClick={() => setShowAll(!showAll)}
                  size="large"
                  sx={{ textTransform: 'none' }}
                  variant="text"
                >
                  Show all
                </Button>
              )}
            </>
          )}
        </>
      )}

      {showAll && (
        <Popup handleBackgroundClick={() => setShowAll(false)}>
          <Panel style={{ position: 'relative', height: 'unset', paddingBottom: 10 }}>
            {paymentMethodsLoading && <p>Loading...</p>}

            {!paymentMethodsLoading && (
              <>
                <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setShowAll(false)}>
                  <Close />
                </IconButton>

                <PopupHeader>Payment methods ({paymentMethods.length})</PopupHeader>

                {paymentMethods?.map((paymentMethod, index) => (
                  <PaymentCard key={index} style={{ marginBottom: 30 }} paymentMethod={paymentMethod} />
                ))}
              </>
            )}
          </Panel>
        </Popup>
      )}
    </Panel>
  )
}

const cardStyleOverrides: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'stretch',
  width: 240,
  height: 120,
  minHeight: 120,
  padding: 10,
  transform: 'rotate(0)',
  boxShadow: '6px 6px 30px rgba(34,50,84,0.5), 1px 1px 0px 1px #0a349c',
  cursor: 'default',
  color: '#fffb',
}

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 200px;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const CardNumber = styled.p`
  font-size: 20px;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const CardExpiry = styled.p``

const CardHolder = styled.p``

const CardBrand = styled.div``

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: auto;
`

const Action = styled.p`
  color: #fffd;
  background-color: #fff3;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

const DefaultTag = styled.p`
  margin-right: auto;
  color: #fff;
  font-weight: 500;
`

const PopupHeader = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
`
