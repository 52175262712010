import { useState } from 'react'
import { CardShenanigans } from './CardShenanigans'
import { DragShenanigans } from './DragShenanigans'

export const Shenanigans = () => {
  const [viewMode, setViewMode] = useState<string>('nfl')

  switch (viewMode) {
    case 'nfl':
      return <CardShenanigans background="/img/nfl-bg.jpeg" />
    case 'nhl':
      return <DragShenanigans background="/img/nhl-bg.jpeg" />
    case 'mls':
      return <DragShenanigans background="/img/mls-bg.jpeg" />
    default:
      return null
  }
}
