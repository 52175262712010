import { useEffect, useRef } from 'react'

// observable: what to observe for changes. supports single value + array
// action: function to run after debounce interval
// debounceTimeout: how long to wait to autosave after a user has paused input
export const useDebounce = (
  observable: any | any[],
  action: () => void,
  debounceTimeout: number = 1000,
  skipInitial: boolean = false
) => {
  const debounceTaskId = useRef<any>()
  const initialSkipped = useRef<boolean>(false)

  useEffect(
    () => {
      if (skipInitial && !initialSkipped.current) {
        initialSkipped.current = true
        return // skip
      }
      if (debounceTaskId.current) {
        clearTimeout(debounceTaskId.current)
      }
      debounceTaskId.current = setTimeout(action, debounceTimeout)
    },
    Array.isArray(observable) ? [...observable] : [observable]
  )
}
