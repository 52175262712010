import { Brush, Delete, Edit, HideImage } from '@mui/icons-material'
import { Fab, Tooltip } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { QuestionImagePicker } from 'components/ImageHandling/QuestionImagePicker'
import { GrowingTextarea } from 'components/Input/GrowingTextarea'
import { useEffect, useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'utils/QuestionUtils'
import { useDebounce } from 'utils/useDebounce'
import {
  DesktopField,
  DesktopPrompt,
  EditButtonBlurWrapper,
  ImageWrapper,
  KeyboardKey,
} from '../SharedDesktopQuestionComponents'
import { Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  editQuestion: any
  survey: Partial<Survey>
  orientation?: 'desktop' | 'mobile'
}

const DEFAULT_CHOICES = [
  {
    image: {
      // url: 'https://bellwether-media.s3.amazonaws.com/f5f1339341b2842219d125224bd3cadf.png',
      attribution: '',
    },
    label: 'Business',
    value: 0,
    _id: '6328bef01146b74c06b57b1d',
  },
  {
    image: {
      // url: 'https://bellwether-media.s3.amazonaws.com/32691ddb020de0961bde8f0106ed6a24.png',
      attribution: '',
    },
    label: 'Visiting Friends',
    value: 1,
    _id: '6328bef01146b74c06b57b1e',
  },
  {
    image: {
      // url: 'https://bellwether-media.s3.amazonaws.com/d72481364d9b8faa44603b5e927e1d5b.png',
      attribution: '',
    },
    label: 'Vacation',
    value: 2,
    _id: '6328bef01146b74c06b57b1f',
  },
  {
    image: {
      // url: 'https://bellwether-media.s3.amazonaws.com/3cdab989b508cc3660d23c0a62256a19.png',
      attribution: '',
    },
    label: 'Other',
    value: 3,
    _id: '6328bef01146b74c06b57b20',
  },
]

export const ImageChoiceQuestion = ({ question, editQuestion, survey, orientation = 'mobile' }: Props) => {
  const [prompt, setPrompt] = useState(question.prompt || '')
  const [stagingChoices, setStagingChoices] = useState(
    question.choices.length > 0 ? question.choices : DEFAULT_CHOICES
  )

  const dispatch = useAppDispatch()

  useDebounce(prompt, () => editQuestion({ prompt }), 500, true)

  useEffect(() => {
    setStagingChoices(question.choices)
  }, [question])

  useDebounce([stagingChoices], () => editQuestion({ choices: stagingChoices }), 1, true) // leverage the skipInitial function of useDebounce. this must be registered after the useEffect that calls setStagingChoices on question update to work properly

  const isQuad = stagingChoices.length === 4

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          !!question.image ? getImagePositionDesktopStyles(question) : {},
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopField>
          <DesktopPrompt
            color={survey.styles?.questionTextColor || '#fff'}
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
        </DesktopField>
        <DesktopChoices>
          {stagingChoices.map((choice, choiceIndex) => (
            <DesktopChoiceWrapper key={choiceIndex + '.' + choice.value + '.' + choice.label}>
              <DesktopChoiceLabel
                onClick={() => {
                  const newLabel = window.prompt('Edit choice', choice.label)
                  if (newLabel?.trim().length > 0) {
                    setStagingChoices(
                      stagingChoices.map((stagingChoice, stagingChoiceIndex) =>
                        stagingChoiceIndex === choiceIndex
                          ? { ...stagingChoice, label: newLabel }
                          : { ...stagingChoice }
                      )
                    )
                  }
                }}
              >
                <KeyboardKey
                  style={{
                    background: 'linear-gradient(-225deg, #000000, #33347d)',
                    boxShadow:
                      'inset 0 -2px 0 0 #121216, inset 0 0 1px 1px #33347d, 0 1px 2px 1px rgba(30, 35, 90, 0.4)',
                  }}
                >
                  {choice.value + 1}
                </KeyboardKey>
                {choice.label}
                <DesktopEditTextIcon style={{ fill: survey.styles?.backgroundColor || '#33347d' }} />
              </DesktopChoiceLabel>
              <DesktopChoice
                style={
                  choice.image?.url
                    ? {
                        backgroundImage: `url(${choice.image.url})`,
                      }
                    : undefined
                }
              >
                {!choice.image?.url && (
                  <QuestionImagePicker
                    fancy={false}
                    compact={true}
                    question={question}
                    choiceIndex={choiceIndex}
                    keyword={choice.label}
                    onChangeImageUrl={(url) =>
                      setStagingChoices(
                        stagingChoices.map((stagingChoice, stagingChoiceIndex) =>
                          stagingChoiceIndex === choiceIndex
                            ? { ...stagingChoice, image: { url } }
                            : { ...stagingChoice }
                        )
                      )
                    }
                  />
                )}
                {choice.image?.url && (
                  <ImageWrapper style={{ width: '100%' }}>
                    <EditButtonBlurWrapper style={{ padding: 10 }}>
                      <Tooltip title="Edit image" placement="bottom">
                        <Fab
                          color="info"
                          size="small"
                          sx={{
                            boxShadow: '0 0 10px #aaaa',
                            color: '#333',
                            bgcolor: '#fff',
                            ':hover': { bgcolor: '#e3e3e3' },
                            marginRight: '5px',
                            transform: 'scale(0.8)',
                          }}
                          onClick={() => {
                            dispatch(photoEditorOpened({ question, imageUrl: choice.image?.url, choiceIndex }))
                          }}
                        >
                          <Brush />
                        </Fab>
                      </Tooltip>

                      <Tooltip title="Remove image" placement="bottom">
                        <Fab
                          color="info"
                          size="small"
                          sx={{
                            boxShadow: '0 0 10px #aaaa',
                            color: '#333',
                            bgcolor: '#fff',
                            ':hover': { bgcolor: '#e3e3e3' },
                            transform: 'scale(0.8)',
                          }}
                          onClick={() => {
                            if (window.confirm('Are you sure you want to remove this image?')) {
                              setStagingChoices(
                                stagingChoices.map((stagingChoice, stagingChoiceIndex) =>
                                  stagingChoiceIndex === choiceIndex
                                    ? { ...stagingChoice, image: null }
                                    : { ...stagingChoice }
                                )
                              )
                            }
                          }}
                        >
                          <HideImage />
                        </Fab>
                      </Tooltip>
                    </EditButtonBlurWrapper>
                  </ImageWrapper>
                )}
              </DesktopChoice>

              <Tooltip title="Remove choice" placement="bottom">
                <DesktopRemoveChoiceButton>
                  <Fab
                    color="info"
                    size="small"
                    sx={{
                      boxShadow: '0 0 10px #aaaa',
                      color: '#333',
                      bgcolor: '#fff',
                      ':hover': { bgcolor: '#e3e3e3' },
                    }}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to remove this choice?')) {
                        setStagingChoices(
                          [...stagingChoices]
                            .filter((stagingChoice, stagingChoiceIndex) => stagingChoiceIndex !== choiceIndex)
                            .map((stagingChoice, stagingChoiceIndex) => ({
                              ...stagingChoice,
                              value: stagingChoiceIndex,
                            }))
                        )
                      }
                    }}
                  >
                    <Delete />
                  </Fab>
                </DesktopRemoveChoiceButton>
              </Tooltip>
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {stagingChoices.map((choice: any, index: number) => (
            <ChoiceContainer key={index} isQuad={isQuad}>
              <ChoiceLabel position={(isQuad && index < 2) || (!isQuad && index < 1) ? 'top' : 'bottom'}>
                {choice.label}
              </ChoiceLabel>
              <ChoiceImage src={choice?.image?.url} />
            </ChoiceContainer>
          ))}
        </Choices>
        {question.prompt && (
          <MobilePromptWrapper>
            <MobilePrompt
              placeholder="What would you like to ask?"
              value={prompt}
              onChange={(event: any) => setPrompt(event.target.value)}
            />
          </MobilePromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #33347d;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 3;
`

const DesktopChoice = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden; // prevents blur bleed
`

const DesktopChoiceLabel = styled.p`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 14px 10px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

const DesktopChoiceImage = styled.img`
  width: 100%;
  transition: filter 0.2s ease-in-out !important;
`

const DesktopEditTextIcon = styled(Edit)`
  position: absolute;
  right: 8px;
  height: 20px !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out !important;
`

const DesktopEditImageIcon = styled(Fab)`
  position: absolute !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out !important;
  box-shadow: 0px 0px 30px 0px #fff7 !important;
`

const DesktopRemoveChoiceButton = styled.div`
  position: absolute;
  bottom: 20px;
  opacity: 0;
  transition: 300ms opacity ease;
  z-index: 1;
`

const MobilePromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const MobilePrompt = styled(GrowingTextarea)`
  width: 100%;
  border-radius: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean }>`
  position: relative;
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceLabel = styled.div<{ position: 'top' | 'bottom' }>`
  width: 100%;
  font-weight: 600;
  color: #555555;
  backdrop-filter: blur(3px);
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  ${({ position }) => (position === 'top' ? `top: 0;` : `bottom: 0;`)}
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`

const DesktopChoiceWrapper = styled.div`
  // this is at the end because it references other styled-components in its :hover selector
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  /* cursor: pointer; */
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    border: 1px solid #fff;

    ${DesktopChoiceLabel} {
      color: #33347d;
      background: #ffffff;
    }
  }

  &:hover ${DesktopEditTextIcon} {
    opacity: 1;
  }

  &:hover ${DesktopEditImageIcon} {
    opacity: 1;
  }

  &:hover ${DesktopChoiceImage} {
    filter: brightness(0.8) blur(2px);
  }

  &:hover ${DesktopRemoveChoiceButton} {
    opacity: 1;
    pointer-events: all;
  }
`
