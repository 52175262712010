import { useAppDispatch } from 'app/hooks'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { asyncSignUp, signUpFlowCanceled } from 'services/authSlice'
import { BottomActionText, Brand, Input, LoginBox, Seperation, Subheader, Submit } from './LoginComponents'

export const SignUp = ({ style }: { style?: any }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailStatus, setEmailStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [password, setPassword] = useState<string>('')
  const [passwordStatus, setPasswordStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState<'default' | 'entering' | 'valid'>('default')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const newStatus = email.length === 0 ? 'default' : /\S+@\S+\.\S+/.test(email) ? 'valid' : 'entering'
    setEmailStatus(newStatus)
  }, [email])

  useEffect(() => {
    const newStatus = password.length === 0 ? 'default' : password.length < 8 ? 'entering' : 'valid'
    setPasswordStatus(newStatus)
  }, [password])

  useEffect(() => {
    const newStatus = confirmPassword.length === 0 ? 'default' : confirmPassword.length < 8 ? 'entering' : 'valid'
    setConfirmPasswordStatus(newStatus)
  }, [confirmPassword])

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPassword('')
    setConfirmPassword('')
    if (emailStatus !== 'valid') return alert('Please enter a valid email address.')
    if (passwordStatus !== 'valid') return alert('Please make sure your password is at least 8 characters long.')
    if (password !== confirmPassword) return alert('Please make sure your passwords match.')
    setSubmitting(true)
    dispatch(asyncSignUp({ username: email, password, firstName: firstName.trim(), lastName: lastName.trim() }))
      .then((result: any) => {
        if (result.error) {
          console.error(result.error)
          alert(
            `Failed to create account. Do you already have an existing account? If not, please contact us if the error persists.\n\nError info: ${result.error.message}`
          )
        }
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <LoginBox style={style}>
      <Brand />
      <Subheader>Please fill out the following to create your free account:</Subheader>
      <form spellCheck="false">
        <Input
          key="firstName"
          status={firstName.length > 0 ? 'valid' : 'default'}
          placeholder="First name"
          autoComplete="none"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          key="lastName"
          status={lastName.length > 0 ? 'valid' : 'default'}
          placeholder="Last name"
          autoComplete="none"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          key="email"
          status={emailStatus}
          placeholder="Email"
          autoComplete="username"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
        />
        <Input
          key="password"
          status={passwordStatus}
          type="password"
          placeholder="Password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          key="confirmPassword"
          status={confirmPasswordStatus}
          type="password"
          placeholder="Confirm password"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Submit type="submit" value="Sign up" disabled={submitting} onClick={handleSubmit} />
        <Seperation>or</Seperation>
        <BottomActionText onClick={() => dispatch(signUpFlowCanceled())}>
          sign in with existing account
        </BottomActionText>
      </form>
    </LoginBox>
  )
}
