import { StyleSheet, Text, View } from '@react-pdf/renderer'

export const BooleanVitals = ({ question, index, votes }: { question: Question; index: number; votes: any }) => {
  const values = {
    yesVotes: 0,
    yesPct: 0,

    noVotes: 0,
    noPct: 0,

    notSureVotes: 0,
    notSurePct: 0,

    totalVotes: votes?.total || 0,
    dropoffRate: (question.dropoffRate || 0) * 100,
  }

  if (votes) {
    if (votes['2']) {
      values.yesVotes = votes['2']
      values.yesPct = !values.totalVotes ? 0 : (values.yesVotes / values.totalVotes) * 100
    }
    if (votes['0']) {
      values.noVotes = votes['0']
      values.noPct = !values.totalVotes ? 0 : (values.noVotes / values.totalVotes) * 100
    }
    if (votes['1']) {
      values.notSureVotes = votes['1']
      values.notSurePct = !values.totalVotes ? 0 : (values.notSureVotes / values.totalVotes) * 100
    }
  }
  // const totalVotesOrScore = question.type === 'rank' ? (totalVotes * (totalVotes + 1)) / 2 : totalVotes
  return (
    <View style={count.container}>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${values.yesPct}%`, backgroundColor: '#92d0a2' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(values.yesPct)}% Yes (${(
            values.yesVotes || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${values.noPct}%`, backgroundColor: '#e79995' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(values.noPct)}% No (${(
            values.noVotes || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${values.notSurePct}%`, backgroundColor: '#c4c4c4' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(values.notSurePct)}% Not Sure (${(
            values.notSureVotes || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      {/* {index !== 0 && (
          <View style={[count.bar, { marginTop: 20 }]}>
            <View style={[count.barFill, { width: `${100 - values.dropoffRate}%`, backgroundColor: '#0fc2cd' }]}>
              <Text style={count.barLabelOverflow}>{`${Math.round(100 - values.dropoffRate)}% Retention`}</Text>
            </View>
          </View>
        )} */}
    </View>
  )
}

const count = StyleSheet.create({
  container: {
    marginTop: 18,
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 300,
    height: 22,
    // border: '1px solid #ddd',
    marginLeft: 8,
    marginBottom: 6,
  },
  barFill: {
    height: 20,
    border: 'none',
    color: '#ffffff',
    minWidth: 10,
  },
  barLabel: {
    width: '100%',
    color: 'rgb(255, 255, 255)',
    zIndex: 999,
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  barLabelOverflow: {
    fontSize: 10,
    color: '#333',
    left: '100%',
    marginLeft: 4,
    marginTop: 3,
    width: 300,
  },
})
