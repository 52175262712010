import { Popup } from 'components/Popup'
import { usePutUserProfileMutation } from 'services/userApi'

export const SuspendedAccount = () => {
  const [editUser] = usePutUserProfileMutation()

  return (
    <Popup
      handleBackgroundClick={() => {
        alert('you done fucked up')
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#ffffff',
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/Sj4gTCVx3l8?start=15&autoplay=1"
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <div
        style={{
          fontSize: '40px',
          color: '#000000',
          cursor: 'pointer',
          marginTop: '40px',
          fontWeight: 800,
        }}
        onClick={() => {
          alert('you done unfucked up')
          editUser({ delta: { suspended: false } })
        }}
      >
        Unfuck Up
      </div>
    </Popup>
  )
}
