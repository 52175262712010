import { AppsRounded, FormatListBulletedRounded, PictureAsPdfRounded, TextSnippet } from '@mui/icons-material'
import { Loading, MaxWidthAndCenter } from 'components/SharedPageComponents'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Question } from '../../components/Question/QuestionPreview/Question'
import { ExperienceJourney } from './ExperienceJourney'
import { ExperienceMetrics } from './ExperienceMetrics'

export const ExperienceResults = ({ experience }: { experience: any }) => {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('list')

  const survey = experience as Partial<Survey>

  const QuestionRow = ({ question, index }: { question: Partial<Question>; index: number }) => {
    if (!question || !survey.questions) {
      return null
    }
    const prevQuestionVotes = index === 0 ? 0 : survey.questions[index - 1]?.votes?.total || 0
    const abandons = index === 0 ? 0 : prevQuestionVotes - (question.votes?.total || 0)
    const dropoffPct =
      index === 0 || abandons === 0
        ? 0
        : (question.votes?.total || 0) === 0
        ? 100
        : 100 * (1 - (question.votes?.total || 0) / (prevQuestionVotes || 1))
    return (
      <Row>
        <Cell style={{ flex: 1, marginRight: 50 }}>
          {index + 1}. {question?.prompt}
        </Cell>
        <Cell>{question.votes?.total || 0}</Cell>
        <Cell style={{ width: 160 }}>{(4 + Math.random() * 10).toFixed(1)}s</Cell>
        <Cell style={{ minWidth: 180 }}>
          {abandons <= 0 ? '0' : `-${abandons}`}{' '}
          <span style={{ color: '#3337' }}>({dropoffPct <= 0 ? 0 : dropoffPct.toFixed(1)}% of audience)</span>{' '}
        </Cell>
      </Row>
    )
  }

  if (!survey) {
    return <Loading>Loading...</Loading>
  }

  let previousTotal = survey.anonymousVoting?.counts.view || 0
  const questionEngagementPcts = survey
    .questions!.filter((question) => question.type !== 'intro')
    .map((question, index) => {
      const total = question.votes?.total || 0
      if (total === 0) {
        // no votes on previous -> 0% engagement
        return 0
      }

      if (previousTotal === 0) {
        // no votes on previous but votes on this question -> 100% engagement
        return 100
      }

      const dropoffCount = Math.max(previousTotal - total, 0)
      const dropoffRate = previousTotal === 0 ? 0 : Math.abs(dropoffCount / previousTotal)
      previousTotal = total
      return 100 * (1 - dropoffRate)
    })

  return (
    <MaxWidthAndCenter style={{ width: 'unset', maxWidth: 1000 }}>
      <Main>
        <SectionTitle style={{ marginTop: 10 }}>At a glance</SectionTitle>
        <ExperienceMetrics
          experience={experience}
          style={{ width: '100%', boxSizing: 'border-box', justifyContent: 'space-around' }}
        ></ExperienceMetrics>

        <SectionTitle style={{ marginTop: 60 }}>Engagement levels</SectionTitle>

        <ExperienceJourneyWrapper>
          <ExperienceJourney data={questionEngagementPcts} />
        </ExperienceJourneyWrapper>

        <QuestionResultsSectionTitle>
          Question by question
          <ViewModeToggle>
            <ViewMode
              selected={viewMode === 'grid'}
              onClick={viewMode === 'grid' ? undefined : () => setViewMode('grid')}
            >
              <AppsRounded sx={{ marginRight: '4px' }} /> Grid
            </ViewMode>
            <ViewMode
              selected={viewMode === 'list'}
              onClick={viewMode === 'list' ? undefined : () => setViewMode('list')}
            >
              <FormatListBulletedRounded sx={{ marginRight: '4px' }} /> List
            </ViewMode>
          </ViewModeToggle>
        </QuestionResultsSectionTitle>
        <Section>
          {viewMode === 'grid' && (
            <QuestionResultsGrid>
              {survey.questions?.map((question, index) => {
                return (
                  <QuestionZoomWrapper key={'qg-' + index} style={{ pointerEvents: 'none' }}>
                    <Question
                      question={{ ...question, prompt: '' + (index + 1) + '. ' + question.prompt }}
                      survey={survey}
                      orientation="mobile"
                    />
                  </QuestionZoomWrapper>
                )
              })}
            </QuestionResultsGrid>
          )}

          {viewMode === 'list' && (
            <QuestionResultsList>
              <Row style={{ borderTop: 'none' }}>
                <ColumnHeader style={{ flex: 1, marginRight: 50 }}>Questions</ColumnHeader>
                <ColumnHeader>Responses</ColumnHeader>
                <ColumnHeader style={{ width: 160 }}>Avg. time on question</ColumnHeader>
                <ColumnHeader style={{ minWidth: 180 }}>Dropoff</ColumnHeader>
              </Row>

              {survey.questions?.map((question, index) => (
                <QuestionRow key={'ql-' + index} question={question} index={index} />
              ))}
            </QuestionResultsList>
          )}
        </Section>

        <SectionTitle style={{ marginTop: 70 }}>Reports & Exports</SectionTitle>
        <Section>
          <NavLink
            to={'/dashboard/forms/reports/' + survey._id}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <ClickableCard>
              <PdfIcon />
              <CardTitle>PDF Report</CardTitle>
              <CardText>
                Export overall and question-by-question results in a PDF tailored by your Brand Kit.
              </CardText>
            </ClickableCard>
          </NavLink>
          <ClickableCard onClick={() => alert('Coming soon!')}>
            <CsvIcon />
            <CardTitle>CSV Export</CardTitle>
            <CardText>
              Download results as a CSV file. Choose which columns to include, and filter rows based on your
              parameters.
            </CardText>
          </ClickableCard>
          <ClickableCard onClick={() => alert('Coming soon!')}>
            <img style={{ height: 40 }} src="/img/gsheets.svg" />
            <CardTitle>Google Sheet</CardTitle>
            <CardText>Export your Storyform completion data to a Google Sheet</CardText>
          </ClickableCard>
          <ClickableCard onClick={() => alert('Coming soon!')}>
            <img style={{ height: 40 }} src="/img/excel.svg" />
            <CardTitle>Excel Online</CardTitle>
            <CardText>Export your Storyform completion data to an Excel Sheet</CardText>
          </ClickableCard>
          <ClickableCard onClick={() => alert('Coming soon!')}>
            <img style={{ height: 40 }} src="/img/airtable.svg" />
            <CardTitle>Airtable</CardTitle>
            <CardText>Export your Storyform completion data to an Airtable table</CardText>
          </ClickableCard>
        </Section>
      </Main>
    </MaxWidthAndCenter>
  )
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding: 40px 40px 100px;
`

const SectionTitle = styled.div`
  font-size: 20px;
  margin: 30px 0;
  width: 100%;
`

const QuestionResultsSectionTitle = styled(SectionTitle)`
  margin-top: 90px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ViewModeToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: right;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #33347d;
  font-size: 14px;
`

const ViewMode = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 10px;

  ${({ selected }) =>
    selected
      ? `
    color: white;
    background-color: #33347d;
    cursor: default;
  `
      : `
  color: #33347d;
    background-color: transparent;
    cursor: pointer;
  `}
`

const QuestionResultsGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 900px;
`

const QuestionResultsList = styled.div`
  max-width: 900px;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #aaa3;
`

const Cell = styled.div`
  /* box-sizing: border-box; */
  margin-right: 20px;
  min-width: 100px;
  font-size: 14px;
`

const ColumnHeader = styled(Cell)`
  font-weight: 500;
`

const QuestionZoomWrapper = styled.div`
  position: relative;
  flex: auto;
  width: 390px;
  max-width: 390px;
  height: 610px;
  margin: 30px;
  zoom: 0.5;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 20%) 0px 2px 12px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  height: 140px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.div`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
`

const CardText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #555;
`

const PdfIcon = styled(PictureAsPdfRounded)`
  fill: #333 !important;
`

const CsvIcon = styled(TextSnippet)`
  fill: #333 !important;
`

const ClickableCard = styled(Card)<{ hoverBackground?: string }>`
  width: 240px;
  transition: 200ms all ease;
  z-index: 1;

  cursor: pointer;

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px,
      rgb(0 0 0 / 2%) 0px 12px 16px;

    ${CardTitle} {
      color: #fff;
    }

    ${CardText} {
      color: #fffd;
    }

    ${PdfIcon} {
      filter: brightness(0) invert();
    }

    ${CsvIcon} {
      filter: brightness(0) invert();
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ hoverBackground }) => hoverBackground || 'linear-gradient(45deg,#4838a4,#0c98ff 150%)'};
    z-index: -1;
    transition: 200ms opacity ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
`

const ExperienceJourneyWrapper = styled.div`
  padding: 50px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`
