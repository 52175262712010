import { NavItem, Topbar } from 'components/Elements/Topbar'
import { MaxWidthAndCenter } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'
import usePageTitle from 'utils/usePageTitle'
import { Billing } from '../Billing'
import { Profile } from './Profile'
import { Settings } from './Settings'

export const Account = () => {
  usePageTitle('Account | Storyforms')
  return (
    <Page>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      >
        <NavItem path="/dashboard/account">account</NavItem>
        <NavItem path="billing">billing</NavItem>
      </Topbar>
      <MainArea>
        <MaxWidthAndCenter style={{ minWidth: 800, maxWidth: 800 }}>
          <Routes>
            <Route
              path=""
              element={
                <>
                  <Profile />
                  <Settings />
                </>
              }
            />
            <Route path="billing" element={<Billing />} />
          </Routes>
        </MaxWidthAndCenter>
      </MainArea>
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  height: calc(100vh - 50px);
  color: #08324c;
  background-color: #f7f7f7;
  user-select: none;
  overflow-y: scroll;
  z-index: 11;
`

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  height: 100%;
`
