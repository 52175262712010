import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'

export const TextVitals = ({
  question,
  index,
  votes,
  prerender,
}: {
  question: Question
  index: number
  votes: any
  prerender?: any
}) => {
  const totalVotes = votes?.total || 0
  const dropoffRate = (question.dropoffRate || 0) * 100

  return (
    <View style={count.container}>
      <Text
        style={{
          marginLeft: 10,
          fontSize: 11,
          color: '#777',
          fontStyle: 'italic',
        }}
        break={true}
        wrap={true}
      >
        Text responses can be exported from your Storyforms dashboard.
      </Text>
      <Text
        style={{
          marginLeft: 10,
          marginBottom: 20,
          marginTop: 15,
          fontSize: 11,
          color: '#333',
        }}
      >
        Frequently mentioned keywords
        <Text style={{ color: '#777', fontStyle: 'italic' }}> (larger means more mentions)</Text>:
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Image
          src={prerender}
          style={{
            width: 200,
            minWidth: 200,
            maxWidth: 200,
            marginLeft: 80,
            backgroundColor: '#fff',
          }}
        />
      </View>
    </View>
  )
}

const count = StyleSheet.create({
  container: {
    marginTop: 18,
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 300,
    height: 22,
    // border: '1px solid #ddd',
    marginLeft: 8,
    marginBottom: 6,
  },
  barFill: {
    height: 20,
    border: 'none',
    color: '#ffffff',
    minWidth: 10,
  },
  barLabel: {
    width: '100%',
    color: 'rgb(255, 255, 255)',
    zIndex: 999,
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  barLabelOverflow: {
    fontSize: 10,
    color: '#333',
    left: '100%',
    marginLeft: 4,
    marginTop: 3,
    width: 300,
  },
})
