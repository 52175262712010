import styled from 'styled-components/macro'

export const Docs = () => {
  return (
    <StyledDocs>
      <Topbar>
        <Header>Two Factor Authentication</Header>
        <Subheader>bwi docs</Subheader>
      </Topbar>
      <Scaffolding>Let's Make this Modular and Let's Fill it in!</Scaffolding>
      <Bottombar>
        <Footer>version 0.0.1</Footer>
        <Footer>updated - august 25, 2022</Footer>
      </Bottombar>
    </StyledDocs>
  )
}

const StyledDocs = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - (60px + 32px) * 2);
  width: 840px;
  padding: calc(60px + 32px) calc((100vw - 840px) / 2);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
`

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 840px;
  padding: 0px calc((100vw - 840px) / 2);
  background: #89705c;
`

const Scaffolding = styled.div`
  font-size: 18px;
  color: #6d6d6d;
`

const Header = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
`

const Subheader = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
`

const Bottombar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: 36px;
  width: 840px;
  border-top: 1px solid #cccccc;
`

const Footer = styled.div`
  size: 10px;
  color: #6d6d6d;
  font-weight: 400;
`
