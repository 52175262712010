import { MoreHoriz } from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteSurveyMutation, usePutSurveyMutation } from 'services/surveyApi'
import styled from 'styled-components/macro'
import { useBlurhash } from 'utils/useBlurhash'
import { WorkspaceGridItem } from './WorkspaceGridItem'

export function ExperiencesGridItem({
  experience,
  onDuplicate,
  onDragStart,
}: {
  experience: any
  onDuplicate?: any
  onDragStart?: any
}) {
  const navigate = useNavigate()

  const [putExperience] = usePutSurveyMutation()
  const [deleteExperience] = useDeleteSurveyMutation()

  const [blurhashCanvasRef, drawBlurhash] = useBlurhash(() => {
    if (experience.thumbnail?.length === 16) {
      return experience.thumbnail
    }
    let result = 'JG' // todo: choose from palette of avg colors, ditto for other components of the algorithm
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$%*+,-.:;=?@[]^_{|}~'
    const charactersLength = characters.length
    let counter = 2
    let target = 16
    while (counter < target) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(drawBlurhash, [])

  const handleMoreClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (action: 'copy-link' | 'rename' | 'duplicate' | 'delete') => {
    // todo: item-specific logic
    switch (action) {
      case 'copy-link':
        navigator.clipboard.writeText(`https://dev-engage.trystoryforms.com/form/${experience.customId}`)
        alert('Sharable link copied to clipboard!')
        break
      case 'rename':
        putExperience({
          _id: experience._id,
          delta: {
            name: window.prompt('What should this Storyform be called?', experience.name) || experience.name!,
          },
        })
        break
      case 'duplicate':
        if (onDuplicate) {
          onDuplicate()
        }
        break
      case 'delete':
        window.confirm('Are you sure you want to delete this Storyform?') &&
          deleteExperience({ _id: experience._id })
        break
      default:
        break
    }
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openExperienceDetails = () => {
    navigate('/dashboard/forms/' + experience._id + '/design')
  }

  return (
    <WorkspaceGridItem onDragStart={onDragStart}>
      <Thumbnail onClick={openExperienceDetails}>
        <BlurhashCanvas ref={blurhashCanvasRef} width={390} height={610} />
        <ExperienceName>{experience.name}</ExperienceName>
      </Thumbnail>
      <Footer>
        <p style={{ color: '#555' }}>{(experience.anonymousVoting.counts.completion || 'No') + ' responses'}</p>
        <MoreHoriz sx={{ cursor: 'pointer', color: '#888' }} onClick={handleMoreClick} />
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ borderRadius: '8px' }}>
          <MenuItem onClick={() => handleMenuItemClick('copy-link')}>Copy Link</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('rename')}>Rename</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('duplicate')}>Duplicate</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('delete')} sx={{ color: '#a11' }}>
            Delete
          </MenuItem>
        </Menu>
      </Footer>
    </WorkspaceGridItem>
  )
}

const Thumbnail = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: url(/img/login_bg.jpeg); */
  background-color: #aaaa;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  overflow: hidden;
`

const BlurhashCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.8);
`

const ExperienceName = styled.p`
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  z-index: 1;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60;
  bottom: 0;
  padding: 15px 10px;
  background-color: white;
  border-radius: 0 0 8px 8px;
`

const FooterText = styled.div`
  color: #555;
  font-size: 12px;
`
