import { GrowingTextarea } from 'components/Input/GrowingTextarea'
import styled from 'styled-components/macro'

export const DesktopTile = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const DesktopImage = styled.img`
  cursor: pointer;
  height: 100%;
  width: 359px;
  background: rgba(255, 255, 255, 0.3);
  object-fit: cover;
`

export const EditButtonBlurWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  // backgroundColor: #000a;
  backdrop-filter: blur(2px) brightness(0.9);
  /* border-radius: 20px; */
  pointer-events: none;
  opacity: 0;
  transition: 300ms opacity ease;
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &:hover ${EditButtonBlurWrapper} {
    opacity: 1;
    pointer-events: all;
  }
`

export const DesktopField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 50px;
`

export const DesktopPrompt = styled(GrowingTextarea)<{ color?: string; fontSize?: string; fontWeight?: any }>`
  font-size: ${({ fontSize }) => fontSize || '28px'};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  ${({ color }) => color && `color: ${color};`}
  &::placeholder {
    color: inherit;
  }
`

export const DesktopEngagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
`

export const DesktopEngagement = styled.div<{ color?: string }>`
  font-size: 16px;
  padding: 10px 20px;
  color: ${({ color }) => color || '#ffffff'};
  border: 1px solid ${({ color }) => color || '#ffffff'};
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;
  width: max-content;

  &:hover {
    background: ${({ color }) => (color || '#ffffff') + '40'}; // 0x40 is 0.25 alpha (rgba)
  }
`

export const KeyboardKey = styled.kbd`
  align-items: center;
  background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
  border: 0;
  border-radius: 2px;
  background: linear-gradient(-225deg, #181950, #33347d);
  box-shadow: inset 0 -2px 0 0 #23236d, inset 0 0 1px 1px #33347d, 0 1px 2px 1px rgba(30, 35, 90, 0.4);
  color: #b8c3d5;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-right: 12px;
  padding: 0 0 1px;
  width: 20px;
  font-size: 13px;
`
