import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { appendCountingSuffix } from 'utils/StringUtils'

export const RankVitals = ({
  question,
  index,
  votes,
  rankAggregate,
}: {
  question: Question
  index: number
  votes: any
  rankAggregate: any
}) => {
  if (!rankAggregate) {
    return (
      <Text style={{ marginTop: 20, marginLeft: 10, fontSize: 11, color: '#777', fontStyle: 'italic' }}>
        No responses collected
      </Text>
    )
  }

  const dropoffRate = (question.dropoffRate || 0) * 100

  const labels: string[] = ['table']
  const matrix: number[][] = []

  for (let i = 0; i < question.choices.length; i++) {
    labels[i + 1] = rankAggregate['' + i]?.label || ''
    matrix[i] = Object.keys(rankAggregate['' + i])
      .filter((key) => key !== 'label' && key !== 'total')
      .map((key) => rankAggregate['' + i][key] || 0)
  }

  return (
    <View>
      <View style={rankMatrix.container}>
        <View style={rankMatrix.row}>
          {labels.map((label, index) => (
            <View
              key={question._id + '-label-' + index}
              style={[
                rankMatrix.cell,
                {
                  height: 160 / labels.length,
                  width: `${(1 / labels.length) * 100}%`,
                  backgroundColor: '#DAE1E4',
                  color: '#08334c',
                  border: '1px solid #ffffff',
                },
              ]}
            >
              {index > 0 && <Text style={{ fontWeight: 'bold' }}>{appendCountingSuffix(index)}</Text>}
            </View>
          ))}
        </View>
        {matrix.map((rank, index) => (
          <View style={rankMatrix.row}>
            <View
              style={[
                rankMatrix.cell,
                {
                  height: 160 / (rank.length + 1),
                  width: `${(1 / (rank.length + 1)) * 100}%`,
                  backgroundColor: '#DAE1E4',
                  color: '#08334c',
                  border: '1px solid #ffffff',
                },
              ]}
            >
              <Text
                style={{ fontWeight: 'bold', fontSize: 6, marginLeft: 2, marginRight: 2, textAlign: 'center' }}
              >
                {labels[index + 1]}
              </Text>
            </View>
            {rank.map((choice) => (
              <View
                style={[
                  rankMatrix.cell,
                  {
                    height: 160 / (rank.length + 1),
                    width: `${(1 / (rank.length + 1)) * 100}%`,
                    backgroundColor: `rgba(8,51,76,${choice * 1.5 + 0.2})`,
                    // opacity: (choice + 0.7) / 1.5,
                  },
                ]}
              >
                <Text style={{ color: '#ffffff' }}>{`${Math.round(choice * 1000) / 10}%`}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>

      {/* {index !== 0 && (
          <View style={[count.bar, { marginTop: 20, marginLeft: 0 }]}>
            <View style={[count.barFill, { width: `${100 - dropoffRate}%`, backgroundColor: '#0fc2cd' }]}>
              <Text style={count.barLabelOverflow}>{`${Math.round(100 - dropoffRate)}% Retention`}</Text>
            </View>
          </View>
        )} */}
    </View>
  )
}

const rankMatrix = StyleSheet.create({
  container: {
    border: '1px solid #08324C',
    width: 300,
    // height: 160,
    marginTop: 18,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    border: '1px solid #ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 8,
  },
})
