import {
  useGetSurveyRankQuestionAggregatesQuery,
  useGetSurveysQuery,
  useGetSurveyTextQuestionResponsesQuery,
} from 'services/surveyApi'

import { PDFViewer } from '@react-pdf/renderer'
import { SurveyReportPDF } from 'components/PDF/SurveyReportPDF'

import { ArrowBackIosRounded } from '@mui/icons-material'
import { BackButton } from 'components/BackButton'
import { Topbar } from 'components/Elements/Topbar'
import { Page } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import { toPng } from 'html-to-image'
import GaugeChart from 'lib/GaugeChart'
import { ExperienceJourney } from 'pages/Engage/ExperienceJourney'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TagCloud } from 'react-tagcloud'

export const SurveyReportViewer = () => {
  const { data: surveys = [], error: surveysLoadError, isLoading: surveysLoading } = useGetSurveysQuery()
  const { surveyId } = useParams()
  let routedSurvey = surveys.filter((survey) => survey._id === surveyId)[0]
  return surveys.length > 0 ? <Survey survey={routedSurvey} /> : null
}

const Survey = ({ survey }: { survey: Survey }) => {
  const uniqueViews = survey.anonymousVoting?.counts.view || 0
  const uniqueStarts = survey.anonymousVoting?.counts.start || 0
  const uniqueCompletions = survey.anonymousVoting?.counts.completion || 0
  const uniqueStartRate = uniqueStarts / (uniqueViews || 1)
  const uniqueCompletionRate = uniqueCompletions / (uniqueStarts || 1)
  const uniqueRetentionRate = uniqueCompletions / (uniqueViews || 1)

  const {
    data: rankQuestionAggregates = {},
    error: rankQuestionAggregatesLoadError,
    isLoading: rankQuestionAggregatesLoading,
  } = useGetSurveyRankQuestionAggregatesQuery(survey._id || '')

  const {
    data: textQuestionResponses = [],
    error: textQuestionResponsesError,
    isLoading: textQuestionResponsesLoading,
  } = useGetSurveyTextQuestionResponsesQuery(survey._id || '')

  const [isLoading, setLoading] = useState<boolean>(true)
  const [gaugeChartRender, setGaugeChartRender] = useState<string>()
  const [experienceJourneyRender, setExperienceJourneyRender] = useState<string>()
  const [tagCloudRenders, setTagCloudRenders] = useState<any>({})

  useEffect(() => {
    if (!survey) {
      return
    }
    // setGaugeChartRender(survey.questions.map((question, index) => index.toString()))
    setTimeout(() => {
      ;[
        'prerender-gauge-charts',
        'prerender-experience-journey',
        ...survey.questions.filter((q) => q.type === 'text').map((q) => `q-${q._id}-render`),
      ].forEach((renderTargetId) => {
        const node = document.getElementById(renderTargetId)
        if (!node) return null
        const headers: HeadersInit = new Headers()
        // headers.set('Origin', 'http://localhost')
        toPng(node, { fetchRequestInit: { headers: headers }, cacheBust: true })
          .then((dataUrl) => {
            if (renderTargetId === 'prerender-gauge-charts') {
              setGaugeChartRender(dataUrl as string)
            } else if (renderTargetId === 'prerender-experience-journey') {
              setExperienceJourneyRender(dataUrl as string)
            } else if (renderTargetId.startsWith('q-')) {
              setTagCloudRenders({ ...tagCloudRenders, [renderTargetId]: dataUrl as string })
            }
          })
          .catch((err) => {
            console.error(err)
          })
      })
    }, 100)
    setTimeout(() => setLoading(false), 1000) // todo: callback approach
  }, [survey])

  let previousTotal = survey.anonymousVoting?.counts.view || 0
  const questionEngagementPcts = survey
    .questions!.filter((question) => question.type !== 'intro')
    .map((question, index) => {
      const total = question.votes?.total || 0
      if (total === 0) {
        // no votes on previous -> 0% engagement
        return 0
      }

      if (previousTotal === 0) {
        // no votes on previous but votes on this question -> 100% engagement
        return 100
      }

      const dropoffCount = Math.max(previousTotal - total, 0)
      const dropoffRate = previousTotal === 0 ? 0 : Math.abs(dropoffCount / previousTotal)
      previousTotal = total
      return 100 * (1 - dropoffRate)
    })

  const backLabel = (
    <span
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#555',
      }}
    >
      <ArrowBackIosRounded sx={{ color: '#aaa', height: 20 }} /> Back to dashboard
    </span>
  )

  return (
    <Page style={{ padding: 0 }}>
      <Topbar
        style={{ borderBottom: 'none' }}
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      >
        <BackButton
          backLabel={backLabel}
          homeLabel={backLabel}
          homePath={`/dashboard/forms/${survey._id}/results`}
        />
      </Topbar>
      {!survey ? (
        <p style={{ textAlign: 'center', marginTop: 60, fontSize: 20 }}>Invalid URL</p>
      ) : (
        <div>
          {/* Loading overlay */}
          {(rankQuestionAggregatesLoading || textQuestionResponsesLoading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#323639',
                zIndex: 999,
              }}
            >
              <div className="loading-spinner" style={{ marginTop: 200 }}></div>
              <p style={{ color: 'white', fontSize: 20, marginTop: 20 }}>Generating your report...</p>
            </div>
          )}

          {/* PDF viewer */}
          <div style={{ position: 'fixed', height: '100%', width: '100%', background: '#323639' }}>
            <PDFViewer style={{ height: 'calc(100vh - 60px)', width: '100%', border: 'none' }}>
              <SurveyReportPDF
                survey={survey}
                gaugeChartRender={gaugeChartRender}
                experienceJourneyRender={experienceJourneyRender}
                tagCloudRenders={tagCloudRenders}
                rankQuestionAggregates={rankQuestionAggregates}
                textQuestionResponses={textQuestionResponses}
              />
            </PDFViewer>
          </div>

          {/* JSX source for JSX -> PNG -> PDF prerender pipeline */}
          <div style={{ opacity: 0, background: '#fff', padding: 50 }}>
            {/* Gauge charts for start and completion rates */}
            <div
              id="prerender-gauge-charts"
              style={{
                position: 'fixed',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                // top: '100vh',
                scale: 0.8,
                backgroundColor: '#fff',
                fontFamily: 'Georgia',
                pointerEvents: 'none',
              }}
            >
              <GaugeChart
                percent={uniqueStartRate}
                nrOfLevels={40}
                // arcWidth={0.3}
                arcPadding={0}
                cornerRadius={0}
                colors={['#71ceff', '#71ffd1']}
                hideText={false}
                textColor={'#555'}
                needleColor={'#888'}
                label={'Start rate'}
                fontSize={'58'}
                labelFontSize={'30'}
              />
              <GaugeChart
                id="prerender-gauge-charts"
                percent={uniqueCompletionRate}
                nrOfLevels={40}
                // arcWidth={0.3}
                arcPadding={0}
                cornerRadius={0}
                colors={['#71ceff', '#71ffd1']}
                hideText={false}
                textColor={'#555'}
                needleColor={'#888'}
                label={'Completion rate'}
                fontSize={'58'}
                labelFontSize={'30'}
              />
            </div>

            <div
              // id="prerender-experience-journey"
              style={{
                position: 'fixed',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                top: 10,
                left: 10,
                padding: '30px 10px',
                width: 900,
                // top: '100vh',
                scale: 2,
                backgroundColor: '#fff',
                fontFamily: 'Georgia',
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                overflow: 'hidden',
                pointerEvents: 'none',
              }}
            >
              <div id="prerender-experience-journey" style={{ padding: 30 }}>
                <ExperienceJourney data={questionEngagementPcts} animate={false} />
              </div>
            </div>

            {/* Tag clouds for text questions */}
            {(survey.questions || [])
              .filter((q) => q.type === 'text')
              .map((question: Question, index) => {
                var data
                if (Object.keys(question.responseKeywords || {}).length > 0) {
                  data = Object.keys(question.responseKeywords).map((keyword) => ({
                    value: keyword,
                    count: question.responseKeywords[keyword],
                  }))
                } else {
                  data = []
                }

                const elementId = `q-${question._id}-render`
                return (
                  <div
                    id={elementId}
                    key={elementId}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      backgroundColor: '#fff',
                      width: 'max-content',
                    }}
                  >
                    <TagCloud
                      style={{ width: 300, textAlign: 'center' }}
                      minSize={16}
                      maxSize={26}
                      tags={data}
                      colorOptions={{ hue: 'blue', luminosity: 'dark' }}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </Page>
  )
}
