import { Image } from '@mui/icons-material'
import { ColorPicker } from 'components/Input/ColorPicker'
import { Toggle } from 'components/Input/Toggle'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { useDebounce } from 'utils/useDebounce'
import { ColorPickers, InputHeader, InputWrapper, Spacer } from './components'

interface Props {
  survey: Partial<Survey>
  setStagingSurvey: any
}

const DEFAULT_STYLES: SurveyStyles = {
  questionTextColor: '#fff',
  answerTextColor: '#fff',
  buttonColor: '#11afda',
  buttonTextColor: '#fff',
  progressBarColor: '#122a54',
  backgroundColor: '#33347d',
}

export const DesignTab = ({ survey, setStagingSurvey }: Props) => {
  const [surveyStyles, setSurveyStyles] = useState({ ...(survey.styles || DEFAULT_STYLES) })

  useDebounce(
    surveyStyles,
    () => {
      setStagingSurvey({ ...survey, styles: surveyStyles })
    },
    100
  )

  const handleChangeSurveyStyles = (delta: Partial<SurveyStyles>) => {
    setSurveyStyles({ ...surveyStyles, ...delta })
  }

  return (
    <>
      <InputHeader style={{ marginBottom: 10 }}>Interface</InputHeader>
      <InputWrapper>
        <InputLabel>Progress bar</InputLabel>
        <Toggle
          type="checkbox"
          defaultChecked={!!survey.styles?.progressBarEnabled}
          onChange={(event) => handleChangeSurveyStyles({ progressBarEnabled: event.target.checked })}
          // defaultChecked={false}
          // onClick={(event) => {
          //   event.preventDefault()
          //   alert('Coming soon!')
          // }}
        />
      </InputWrapper>

      {/* <InputWrapper>
        <InputLabel>Question numbers</InputLabel>
        <Toggle
          type="checkbox"
          defaultChecked={!!survey.styles?.questionNumbersEnabled}
          onChange={(event) => handleChangeSurveyStyles({ questionNumbersEnabled: event.target.checked })}
        />
      </InputWrapper> */}

      <InputWrapper style={{ marginBottom: 20 }}>
        <InputLabel>Navigation arrows</InputLabel>
        <Toggle
          type="checkbox"
          defaultChecked={!!survey.styles?.navigationArrowsEnabled}
          onChange={(event) => handleChangeSurveyStyles({ navigationArrowsEnabled: event.target.checked })}
          // defaultChecked={false}
          // onClick={(event) => {
          //   event.preventDefault()
          //   alert('Coming soon!')
          // }}
        />
      </InputWrapper>

      <Spacer />

      <InputHeader>Colors</InputHeader>
      <ColorPickers>
        <ColorPicker
          label="Questions"
          defaultValue={surveyStyles.questionTextColor || '#fff'}
          onChange={(color: any) => handleChangeSurveyStyles({ questionTextColor: color })}
        />
        <ColorPicker
          label="Answers"
          defaultValue={surveyStyles.answerTextColor || '#fff'}
          onChange={(color: any) => handleChangeSurveyStyles({ answerTextColor: color })}
        />
        <ColorPicker
          label="Buttons"
          defaultValue={surveyStyles.buttonColor || '#11afda'}
          onChange={(color: any) => handleChangeSurveyStyles({ buttonColor: color })}
        />
        <ColorPicker
          label="Button text"
          defaultValue={surveyStyles.buttonTextColor || '#fff'}
          onChange={(color: any) => handleChangeSurveyStyles({ buttonTextColor: color })}
        />
        <ColorPicker
          label="Progress Bar"
          defaultValue={surveyStyles.progressBarColor || '#122a54'}
          onChange={(color: any) => handleChangeSurveyStyles({ progressBarColor: color })}
        />
        <ColorPicker
          label="Background"
          defaultValue={surveyStyles.backgroundColor || '#33347d'}
          onChange={(color: any) => handleChangeSurveyStyles({ backgroundColor: color })}
        />
      </ColorPickers>

      {/* <Spacer />

      <InputWrapper>
        <InputHeader>
          <Image sx={{ display: 'inline-block', verticalAlign: 'middle', color: '#aaa' }} />
          <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 4 }}>Background image</span>
        </InputHeader>
        <InputHeader style={{ fontWeight: 'bold', cursor: 'pointer' }}>+Add</InputHeader>
      </InputWrapper> */}

      <Spacer />

      <InputWrapper>
        <InputHeader>
          <Image sx={{ display: 'inline-block', verticalAlign: 'middle', color: '#aaa' }} />
          <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 4 }}>Brand Logo</span>
        </InputHeader>
        <InputHeader style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => alert('Coming soon!')}>
          +Add
        </InputHeader>
      </InputWrapper>
    </>
  )
}

const InputLabel = styled(InputHeader)`
  margin: 4px 10px;
  font-weight: 500;
`
