import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkspaces: build.query<Workspace[], void>({
      query: () => ({
        url: `workspace/`,
        method: 'GET',
      }),
      providesTags: ['workspace'],
    }),

    getWorkspace: build.query<Workspace, { _id: string }>({
      query: (_id) => ({
        url: `workspace/${_id}`,
        method: 'GET',
      }),
      providesTags: ['workspace'],
    }),

    putWorkspace: build.mutation({
      query: ({ _id, delta }) => ({
        url: `workspace/${_id}`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['workspace'],
    }),

    postWorkspace: build.mutation({
      query: (workspace) => ({
        url: `workspace/`,
        method: 'POST',
        body: workspace,
      }),
      invalidatesTags: ['workspace'],
    }),

    deleteWorkspace: build.mutation<void, { _id: string }>({
      query: (_id) => ({
        url: `workspace/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['workspace'],
    }),
  }),
})

export const {
  useGetWorkspacesQuery,
  useGetWorkspaceQuery,
  usePutWorkspaceMutation,
  usePostWorkspaceMutation,
  useDeleteWorkspaceMutation,
} = api
