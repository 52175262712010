import { useAppDispatch } from 'app/hooks'
import { RootState } from 'app/store'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { asyncConfirmCode, asyncResendCode, asyncSignIn, clearCredentials } from 'services/authSlice'
import { useCreateCustomerMutation } from 'services/userApi'
import { Brand, Input, InputSubAction, LoginBox, Subheader, Submit } from './LoginComponents'

export const ConfirmEmail = () => {
  const [createCustomer] = useCreateCustomerMutation()
  const [submitting, setSubmitting] = useState(false)

  const { username, password, firstName, lastName } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [code, setCode] = useState<string>('')
  const [codeStatus, setCodeStatus] = useState<'default' | 'entering' | 'valid'>('default')

  useEffect(() => {
    const newStatus = code.length === 0 ? 'default' : code.length === 6 ? 'valid' : 'entering'
    setCodeStatus(newStatus)
  }, [code])

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSubmitting(true)
    const { from } = (location.state as any) || { from: { pathname: '/' } }
    if (code.length !== 6) return alert('Please make sure your verification code is correct.')
    if (!username || !password) return alert('Error: credentials not found, please try again')
    dispatch(asyncConfirmCode({ code, username }))
      .then((result: any) => {
        if (!result?.payload?.success) {
          alert('Incorrect or expired verification code')
        } else {
          dispatch(asyncSignIn({ username, password }))
            .then(async (result: any) => {
              dispatch(clearCredentials())
              if (result.error) return alert(result.error.message)
              await createCustomer({
                firstName: firstName || 'Story',
                lastName: lastName || 'Forms',
                email: username,
              })
              setSubmitting(false)
              navigate(from.pathname)
            })
            .catch((e) => {
              alert(
                `Unable to sign in at this time. Please contact us if this issue persists.\n\nError info: ${e}`
              )
            })
        }
      })
      .catch((e) => {
        console.error(e)
        alert(`Unable to verify code at this time. Please contact us if this issue persists.\n\nError info: ${e}`)
        setCode('')
      })
  }

  const handleResendCode = () => {
    dispatch(asyncResendCode({ username: username }))
      .then((result: any) => {
        if (result.error) {
          return alert(result.error.message)
        } else {
          return alert('A new code has been sent to the email address you provided')
        }
      })
      .catch((e) => {
        alert(`Unable to send a new code. Please contact us if this issue persists.\n\nError info: ${e}`)
      })
  }

  return (
    <LoginBox>
      <Brand />
      <Subheader>Please enter the code that we sent to your email</Subheader>
      <form autoComplete="off" spellCheck="false">
        <Input
          key="code"
          status={codeStatus}
          placeholder="Verification code"
          autoComplete="none"
          value={code}
          onChange={(e) => setCode(e.target.value.trim())}
        />
        <InputSubAction onClick={handleResendCode}>Resend code</InputSubAction>

        <Submit type="submit" value="Confirm code" onClick={handleSubmit} disabled={submitting} />
      </form>
    </LoginBox>
  )
}
