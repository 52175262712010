import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { underlinedNavItem } from './navStyles'

interface Props {
  left?: React.ReactNode
  children?: React.ReactNode
  right?: React.ReactNode
  style?: any
  showLogo?: boolean
  showText?: boolean
}

export const Topbar = ({ left, children, right, style, showLogo = true, showText = true }: Props) => {
  return (
    <>
      <StyledTopbar style={style}>
        <Left>
          <LogoAndBrand>
            {showLogo && (
              <Logo to="/dashboard/workspaces">
                <LogoImage src="/img/storyforms_logo_fox.png" />
              </Logo>
            )}
            {showText && <Brand to="/dashboard/workspaces">Storyforms</Brand>}
          </LogoAndBrand>
          {left}
        </Left>
        <Center>{children}</Center>
        <Right>{right}</Right>
      </StyledTopbar>
      <Outlet />
    </>
  )
}

export const NavItem = ({ path, children, style }: { path: string; children: React.ReactNode; style?: any }) => {
  const { pathname } = useLocation()
  const selected = pathname.endsWith(path) && path.length > 0 ? !(path.length === 0) : path.length === 0

  return (
    <StyledNavItem style={style} to={path} selected={selected}>
      {children}
    </StyledNavItem>
  )
}

const StyledNavItem = styled(NavLink)<{ selected: boolean }>`
  ${underlinedNavItem}
`

const StyledTopbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  /* border-bottom: 1px solid #413b57; */
  border-bottom: 1px solid #413b5721;
  z-index: 997;
  background: #ffffff;
  padding: 0;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: auto; */
  width: 25%;
  height: 100%;
  /* background: linear-gradient(90deg, rgba(50, 33, 114, 1) 0%, rgba(46, 128, 165, 1) 60%, rgba(46, 128, 165, 0)); */
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 50%;
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: calc(25% - 20px);
  padding-right: 20px;
`

const LogoAndBrand = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 50px;
  padding-left: 0;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(50, 33, 114, 1) 0%, rgba(46, 128, 165, 1) 60%, rgba(46, 128, 165, 0));
`

const Logo = styled(NavLink)`
  height: 100px;
  margin: 0 7px 0 20px;
`

const LogoImage = styled.img`
  height: 100px;
  margin: 5px 20px 0 -10px;
  filter: brightness(3);
`

const Brand = styled(NavLink)`
  margin-right: 60px;
  margin-left: -15px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  user-select: none;
  font-family: 'Avenir';
  text-decoration: none;

  background: #ffffff;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`
