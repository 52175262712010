import { Carousel, CarouselClip, CarouselPage } from 'components/Elements/Carousel'
import { underlinedNavItem } from 'components/Elements/navStyles'
import { Sidebar, SidebarDivision } from 'components/Elements/Sidebar'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { DesignTab } from './DesignTab'
import { QuestionTab } from './QuestionTab'
import { SettingsTab } from './SettingsTab'

interface Props {
  experience: Partial<Survey>
  open: boolean
  onToggleOpen: any
  selectedQuestion: any
  onSelectQuestion: any
  setStagingSurvey: any
}

export const RightBar = ({
  experience,
  open,
  onToggleOpen,
  selectedQuestion,
  onSelectQuestion,
  setStagingSurvey, // todo: revisit this because the pass-down for direct call is a different paradigm than the pass-down of callback handlers so the business logic can stay upstairs
}: Props) => {
  const survey = experience as Partial<Survey>

  const sidebarSections = !selectedQuestion
    ? ['design', 'settings']
    : [selectedQuestion.type === 'ending' ? 'ending' : 'question', 'design', 'settings']

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const Tabs = () => {
    return (
      <SidebarDivision>
        {sidebarSections.map((tabName, tabIndex) => (
          <NavItem
            key={tabIndex}
            selected={tabIndex === activeTabIndex}
            onClick={tabIndex === activeTabIndex ? undefined : () => setActiveTabIndex(tabIndex)}
          >
            {tabName}
          </NavItem>
        ))}
      </SidebarDivision>
    )
  }

  return (
    <Sidebar position="right" expanded={!!open} onToggleOpen={onToggleOpen}>
      <Tabs />
      <CarouselClip style={{ height: 'calc(100% - 40px)' }}>
        <Carousel fieldNumber={activeTabIndex}>
          {!!selectedQuestion && (
            <CarouselPage>
              <QuestionTab
                survey={survey}
                setStagingSurvey={setStagingSurvey}
                selectedQuestion={selectedQuestion}
                onSelectQuestion={onSelectQuestion}
              />
            </CarouselPage>
          )}
          <CarouselPage>
            <DesignTab survey={survey} setStagingSurvey={setStagingSurvey} />
          </CarouselPage>
          <CarouselPage>
            <SettingsTab survey={survey} setStagingSurvey={setStagingSurvey} />
          </CarouselPage>
        </Carousel>
      </CarouselClip>
    </Sidebar>
  )
}

const NavItem = styled.div<{ selected: boolean }>`
  ${underlinedNavItem}
  margin: 0 10px;
  height: 40px;
  font-size: 12px;
`
