import { StyleSheet, Text, View } from '@react-pdf/renderer'

export const ChoiceVitals = ({ question, index, votes }: { question: Question; index: number; votes: any }) => {
  const total = votes?.total || 0
  const dropoffRate = (question.dropoffRate || 0) * 100

  const choiceBars = question.choices.map((choice, choiceIndex) => {
    const choiceVotes = votes?.[choice.value] || 0
    const choicePercent = (100.0 * choiceVotes) / total || 0
    return (
      <View style={count.bar} key={question._id + '-choice-' + choiceIndex}>
        <View
          style={[
            count.barFill,
            {
              width: `${choicePercent}%`,
              backgroundColor: `rgba(25, 66, 201, ${0.2 + (0.8 * choiceVotes) / (total || 1)})`,
            },
          ]}
        >
          <Text style={count.barLabelOverflow}>{`${Math.round(choicePercent)}% ${
            choice.label
          } (${choiceVotes.toLocaleString()} votes)`}</Text>
        </View>
      </View>
    )
  })

  return (
    <View style={count.container}>
      {choiceBars}
      {/* {index !== 0 && (
          <View style={[count.bar, { marginTop: 20 }]}>
            <View style={[count.barFill, { width: `${100 - dropoffRate}%`, backgroundColor: '#0fc2cd' }]}>
              <Text style={count.barLabelOverflow}>{`${Math.round(100 - dropoffRate)}% Retention`}</Text>
            </View>
          </View>
        )} */}
    </View>
  )
}

const count = StyleSheet.create({
  container: {
    marginTop: 18,
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 300,
    height: 22,
    // border: '1px solid #ddd',
    marginLeft: 8,
    marginBottom: 6,
  },
  barFill: {
    height: 20,
    border: 'none',
    color: '#ffffff',
    minWidth: 10,
  },
  barLabel: {
    width: '100%',
    color: 'rgb(255, 255, 255)',
    zIndex: 999,
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  barLabelOverflow: {
    fontSize: 10,
    color: '#333',
    left: '100%',
    marginLeft: 4,
    marginTop: 3,
    width: 300,
  },
})
