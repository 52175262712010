import styled from 'styled-components/macro'

export function WorkspaceGridItem({
  children,
  style,
  onDragStart,
}: {
  children?: any
  style?: any
  onDragStart?: any
}) {
  return (
    <StyledWorkspaceGridItem style={style} draggable={true} onDragStart={onDragStart}>
      {children}
    </StyledWorkspaceGridItem>
  )
}

const StyledWorkspaceGridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 180px;
  height: 220px;
  border-radius: 8px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  transition: 200ms all ease;
  margin: 0 20px 20px 0;

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px,
      rgb(0 0 0 / 2%) 0px 12px 16px;
  }
`
