import markdownItClass from '@toycode/markdown-it-class'
import format from 'date-fns/format'
import markdownIt from 'markdown-it'
import markdownItLinkAttributes from 'markdown-it-link-attributes'
import markdownItSanitizer from 'markdown-it-sanitizer'
import markdownItSup from 'markdown-it-sup'

import { MessageTypes } from 'lib/ChatWidget/src/store/types'

import './styles.scss'

type Props = {
  message: MessageTypes
  showTimeStamp: boolean
}

function Message({ message, showTimeStamp }: Props) {
  const sanitizedHTML = markdownIt({ break: true })
    .use(markdownItClass, {
      img: ['rcw-message-img'],
    })
    .use(markdownItSup)
    .use(markdownItSanitizer)
    .use(markdownItLinkAttributes, { attrs: { target: '_blank', rel: 'noopener' } })
    .render(message.text)

  return (
    <div className={`rcw-${message.sender}`}>
      <div className="rcw-message-text" dangerouslySetInnerHTML={{ __html: sanitizedHTML.replace(/\n$/, '') }} />
      {showTimeStamp && <span className="rcw-timestamp">{format(message.timestamp, 'hh:mm')}</span>}
    </div>
  )
}

export default Message
