import { Delete } from '@mui/icons-material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Toggle } from 'components/Input/Toggle'
import { useNavigate } from 'react-router-dom'
import { useDeleteSurveyMutation } from 'services/surveyApi'
import { usePutWorkspaceMutation } from 'services/workspaceApi'
import styled from 'styled-components/macro'
import { BottomTray, InputHeader, InputWrapper, Spacer } from './components'

interface Props {
  survey: Partial<Survey>
  setStagingSurvey: any
}

export const SettingsTab = ({ survey, setStagingSurvey }: Props) => {
  const navigate = useNavigate()

  const [editWorkspace] = usePutWorkspaceMutation()
  const [deleteSurvey] = useDeleteSurveyMutation()

  const fullyDeleteSurvey = () => {
    // todo: remove survey from the workspace it belongs to
    // editWorkspace({
    //   _id: workspace._id!,
    //   delta: {
    //     items: workspace.items?.filter((currentItem) => currentItem._id !== survey._id),
    //   },
    // })
    deleteSurvey({ _id: survey._id })
  }

  return (
    <>
      <InputHeader>Availability</InputHeader>
      <InputWrapper>
        <InputLabel>Scheduled start</InputLabel>
        <Toggle
          type="checkbox"
          // defaultChecked={!!survey.startDateActive}
          // onChange={() => setStagingSurvey({ ...survey, startDateActive: !survey.startDateActive })}
          defaultChecked={false}
          onClick={(event) => {
            event.preventDefault()
            alert('Coming soon!')
          }}
        />
      </InputWrapper>

      {!!survey.startDateActive && (
        <InputWrapper style={{ flexDirection: 'column', margin: '5px auto 15px' }}>
          <TextField
            id="date"
            label="Start at"
            type="datetime-local"
            disabled={!survey.startDateActive}
            value={survey.startDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setStagingSurvey({ ...survey, startDate: event.target.value })}
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <InputLabel>Scheduled end</InputLabel>
        <Toggle
          type="checkbox"
          // defaultChecked={!!survey.endDateActive}
          // onChange={() => setStagingSurvey({ ...survey, endDateActive: !survey.endDateActive })}
          defaultChecked={false}
          onClick={(event) => {
            event.preventDefault()
            alert('Coming soon!')
          }}
        />
      </InputWrapper>

      {!!survey.endDateActive && (
        <InputWrapper style={{ flexDirection: 'column', margin: '10px auto 15px' }}>
          <TextField
            id="date"
            label="End at"
            type="datetime-local"
            disabled={!survey.endDateActive}
            value={survey.endDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setStagingSurvey({ ...survey, endDate: event.target.value })}
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <InputLabel>Response limit</InputLabel>
        <Toggle
          type="checkbox"
          defaultChecked={!!survey.responseLimitActive}
          onChange={() => setStagingSurvey({ ...survey, responseLimitActive: !survey.responseLimitActive })}
          // defaultChecked={false}
          // onClick={(event) => {
          //   event.preventDefault()
          //   alert('Coming soon!')
          // }}
        />
      </InputWrapper>

      {!!survey.responseLimitActive && (
        <InputWrapper style={{ justifyContent: 'flex-start', margin: '0px 10px 20px' }}>
          <input
            type="number"
            min="0"
            disabled={!survey.responseLimitActive}
            defaultValue={survey.responseLimit}
            onChange={(event) => {
              const responseLimit = Number.parseInt(event.target.value)
              if (!isNaN(responseLimit)) {
                setStagingSurvey({ ...survey, responseLimit })
              }
            }}
            onBlur={(event) => {
              if (!event.target.value?.trim()) {
                // if empty when user focus leaves the input, restore its value from the survey
                event.target.value = survey.responseLimit + ''
              }
            }}
            style={{ width: 60, marginLeft: 10, marginRight: 10, padding: '4px 6px' }}
          />
          <span style={{ fontSize: 12, fontWeight: 500 }}>responses</span>
        </InputWrapper>
      )}

      <Spacer style={{ marginTop: 20 }} />

      <InputWrapper>
        <InputHeader>
          {/* <TooltipText
            style={{ padding: 10, width: 210, left: 0, top: 'calc(100% + 10px)' }}
            text="Displays a cookie consent prompt at the start of your Storyform. We suggest enabling cookie consent to meet the applicable legal requirements."
            position="bottom"
          > */}
          Cookie consent
          {/* </TooltipText> */}
        </InputHeader>
        <Toggle
          type="checkbox"
          // checked={survey.cookieConsentActive}
          // onChange={(event) => setStagingSurvey({ ...survey, cookieConsentActive: event.target.checked })}
          defaultChecked={false}
          onClick={(event) => {
            event.preventDefault()
            alert('Coming soon!')
          }}
        />
      </InputWrapper>

      <BottomTray>
        <Button
          variant="contained"
          sx={{
            width: 'calc(100% - 40px)',
            boxSizing: 'border-box',
            margin: '10px 20px',
            textAlign: 'center',
            fontSize: '12px',
            textTransform: 'none',
            backgroundColor: '#cc9797',
          }}
          startIcon={
            <Delete
              sx={{
                color: 'white',
              }}
            />
          }
          color="error"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this Storyform?')) {
              fullyDeleteSurvey()
              navigate('/dashboard/workspaces')
            }
          }}
        >
          Delete this Storyform
        </Button>
      </BottomTray>
    </>
  )
}

const InputLabel = styled(InputHeader)`
  margin: 4px 10px 12px;
  font-weight: 500;
`
