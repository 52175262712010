import { Email } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { Topbar } from 'components/Elements/Topbar'
import { MaxWidthAndCenter } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import styled from 'styled-components/macro'
import usePageTitle from 'utils/usePageTitle'

const SUPPORT_EMAIL = 'support@trystoryforms.com'

export const Support = () => {
  usePageTitle('Support | Storyforms')
  return (
    <>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
      />
      <MaxWidthAndCenter style={{ width: 'unset', maxWidth: 600, padding: '0 30px' }}>
        <SectionTitle>Get in touch</SectionTitle>
        <SectionSubtitle>Have questions about Storyforms? We're here to help.</SectionSubtitle>
        <Section style={{ marginTop: 40 }}>
          <Card>
            <Email />
            <CardTitle>Write to us</CardTitle>

            <ButtonWrapper>
              <MailtoLink href={'mailto:' + SUPPORT_EMAIL}>
                <Button
                  onClick={() => {}}
                  size="large"
                  sx={{ textTransform: 'lowercase', userSelect: 'text' }}
                  variant="text"
                >
                  {SUPPORT_EMAIL}
                </Button>
              </MailtoLink>
            </ButtonWrapper>

            <CardText>We'll reply as soon as possible.</CardText>
          </Card>
        </Section>
      </MaxWidthAndCenter>
    </>
  )
}

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 70px 0 20px 0;
`

const SectionSubtitle = styled.p`
  font-size: 16px;
  margin: 20px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.p`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  cursor: default;
  user-select: none;
`

const CardText = styled.p`
  font-size: 14px;
  color: #888;
  margin: 10px 0;
  cursor: default;
  user-select: none;
`

const ButtonWrapper = styled.div`
  color: #0d83d9;
  margin: 10px 0;
  align-self: center;
  place-self: center;
  cursor: pointer;
  font-weight: 400;
`

const MailtoLink = styled.a`
  text-decoration: none;
  color: unset;
`
