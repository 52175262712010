import { Edit, Email, Password, PersonRemove, PhonelinkLock } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import { GrowingInput } from 'components/Input/GrowingInput'
import { Toggle } from 'components/Input/Toggle'
import { Popup } from 'components/Popup'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUserQuery, usePutUserProfileMutation } from 'services/userApi'
import styled from 'styled-components/macro'
import { useDebounce } from 'utils/useDebounce'
import usePageTitle from 'utils/usePageTitle'

export const Profile = () => {
  const navigate = useNavigate()

  const { data: user, isLoading: userLoading } = useGetUserQuery()
  const [editUser] = usePutUserProfileMutation()

  const [deleteProfileOpen, setDeleteProfileOpen] = useState(false)
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')

  useDebounce(firstName, () => editUser({ delta: { firstName } }), 500, true)
  useDebounce(lastName, () => editUser({ delta: { lastName } }), 500, true)

  const onChangeEmailClick = () => {
    // todo: open change email dialog
    alert('Coming soon!')
  }

  const onChangePasswordClick = () => {
    // todo: open change password dialog
    alert('Coming soon!')
  }

  const toggleRef = useRef(null)

  const onToggle2FA = () => {
    setTimeout(() => {
      alert('Coming soon!')
      if (toggleRef.current) {
        toggleRef.current.checked = !toggleRef.current?.checked
      }
    }, 250)
  }

  usePageTitle('Account | Storyforms')

  return (
    <>
      {userLoading ? (
        <SystemMessage>Loading...</SystemMessage>
      ) : !user ? (
        <SystemMessage>Error loading profile</SystemMessage>
      ) : (
        <StyledProfile>
          {deleteProfileOpen && (
            <Popup
              handleBackgroundClick={() => {
                setDeleteProfileOpen(false)
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/SWSQS06i7-w?start=33&autoplay=1"
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div
                style={{
                  fontSize: '40px',
                  color: '#ffffff',
                  cursor: 'pointer',
                  marginTop: '40px',
                  fontWeight: 800,
                }}
                onClick={() => {
                  alert('you fucked up')
                  editUser({ delta: { suspended: true } })
                  navigate('/')
                }}
              >
                Done Fuck Up
              </div>
            </Popup>
          )}
          <Flex direction="row" align="center" style={{ marginBottom: 40 }}>
            {/* <Avatar src={user.avatarUrl} /> */}
            <Flex direction="column" justify="center">
              <AccountName>
                <GrowingInput
                  style={{
                    display: 'inline-block',
                    fontSize: 28,
                    padding: '2px',
                    border: 'none',
                    borderRadius: 0,
                    transition: '0.2s all',
                    fontWeight: 400,
                    letterSpacing: 0.4,
                  }}
                  defaultValue={user.firstName}
                  onChange={setFirstName}
                />
                <GrowingInput
                  style={{
                    display: 'inline-block',
                    fontSize: 28,
                    padding: '2px 10px 2px 6px',
                    border: 'none',
                    borderRadius: 0,
                    transition: '0.2s all',
                    fontWeight: 400,
                    letterSpacing: 0.4,
                  }}
                  defaultValue={user.lastName}
                  onChange={setLastName}
                />
                <Tooltip title="Edit your first name and last name individually" placement="bottom">
                  <Edit sx={{ width: 20 }} />
                </Tooltip>
              </AccountName>
              <AccountEmail>{user.email}</AccountEmail>
            </Flex>
          </Flex>
          <SectionTitle>Security</SectionTitle>
          <Section>
            <Card>
              <Email />
              <CardTitle>Email</CardTitle>

              <ButtonWrapper>
                <Button
                  onClick={onChangeEmailClick}
                  size="large"
                  sx={{ textTransform: 'capitalize' }}
                  variant="text"
                >
                  Change
                </Button>
              </ButtonWrapper>
            </Card>
            <Card>
              <PasswordIcon />
              <CardTitle>Password</CardTitle>

              <ButtonWrapper>
                <Button
                  onClick={onChangePasswordClick}
                  size="large"
                  sx={{ textTransform: 'capitalize' }}
                  variant="text"
                >
                  Change
                </Button>
              </ButtonWrapper>
            </Card>
            <Card>
              <PhonelinkLock />
              <CardTitle>Two-factor authentication</CardTitle>

              <ButtonWrapper>
                <Toggle ref={toggleRef} type="checkbox" style={{ width: 60, height: 32 }} onClick={onToggle2FA} />
              </ButtonWrapper>
            </Card>
          </Section>

          <SectionTitle>Danger Zone</SectionTitle>
          <Section>
            <Card style={{ maxWidth: 300 }}>
              <PersonRemove color="error" />
              <CardTitle>Delete account</CardTitle>
              <CardText>
                Permanently delete your account, including all Storyforms you've created. This cannot be undone.
              </CardText>

              <ButtonWrapper>
                <Button
                  onClick={() => {
                    setDeleteProfileOpen(true)
                  }}
                  size="large"
                  sx={{ textTransform: 'capitalize', marginTop: '20px' }}
                  variant="text"
                  color="error"
                >
                  Delete
                </Button>
              </ButtonWrapper>
            </Card>
          </Section>
        </StyledProfile>
      )}
    </>
  )
}

const StyledProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 80px auto;
  width: max-content;
`

const Flex = styled.div<{ direction?: string; justify?: string; align?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`

const Avatar = styled.div<{ src?: string }>`
  margin: 0 15px 0 0;
  background-image: ${({ src }) => 'url(' + (src || '/img/image_handling/add_image.png') + ')'};
  background-repeat: no-repeat;
  background-size: 120px;
  background-position: -20px -55px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  cursor: pointer;
`

const AccountName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`

const AccountEmail = styled.p`
  margin-top: 4px;
  font-size: 14px;
  color: #555;
`

const SystemMessage = styled.p`
  margin: 80px auto;
  text-align: center;
  font-size: 18px;
  color: #888;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ButtonWrapper = styled.div`
  color: #0d83d9;
  margin-top: auto;
  align-self: center;
  place-self: center;
  cursor: pointer;
  font-weight: 400;
  /* text-decoration: underline; */
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  min-height: 140px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.p`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  cursor: default;
`

const CardText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #555;
`

const PasswordIcon = styled(Password)``
