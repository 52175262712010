import cn from 'classnames'
import { useEffect } from 'react'
import SineWaves from 'sine-waves'

import './styles.scss'

type Props = {
  typing: boolean
}

function Loader({ typing }: Props) {
  useEffect(() => {
    var waves = new SineWaves({
      el: document.getElementById('loader-canvas'),

      speed: 5,

      ease: 'SineInOut',

      wavesWidth: '75%',

      waves: [
        {
          timeModifier: 4,
          lineWidth: 1,
          amplitude: -25,
          wavelength: 25,
        },
        {
          timeModifier: 2,
          lineWidth: 1,
          amplitude: -10,
          wavelength: 30,
        },
        {
          timeModifier: 1,
          lineWidth: 1,
          amplitude: -30,
          wavelength: 30,
        },
        {
          timeModifier: 3,
          lineWidth: 1,
          amplitude: 40,
          wavelength: 40,
        },
        {
          timeModifier: 0.6,
          lineWidth: 1,
          amplitude: -48,
          wavelength: 60,
        },
        {
          timeModifier: 1.3,
          lineWidth: 1,
          amplitude: -40,
          wavelength: 40,
        },
      ],

      // Called on window resize
      resizeEvent: function () {
        var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0)
        gradient.addColorStop(0, 'rgba(25, 255, 255, 0)')
        gradient.addColorStop(0.5, 'rgba(255, 25, 255, 0.75)')
        gradient.addColorStop(1, 'rgba(255, 255, 25, 0')

        var index = -1
        var length = this.waves.length
        while (++index < length) {
          this.waves[index].strokeStyle = gradient
        }

        // Clean Up
        index = void 0
        length = void 0
        gradient = void 0
      },
    })
  }, [])

  return (
    <div className={cn('loader', { active: typing })}>
      <div className="loader-container">
        <canvas height={50} id="loader-canvas" />
      </div>
    </div>
  )
}

export default Loader
