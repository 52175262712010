import styled from 'styled-components/macro'
import { Prompt, Tile } from '../SharedMobileQuestionComponents'

export function RankResult({ question, survey }: { question: Question; survey: Partial<Survey> }) {
  const totalVotes = question.votes?.total || 0
  const totalScore = (totalVotes * question.choices.length * (question.choices.length + 1)) / 2
  const choices_array = question.choices.map((choice: any) => choice)
  const choices_with_votes = choices_array.map((choice: any) => {
    return {
      value: choice.value,
      label: choice.label,
      votes: (!question.votes ? 0 : question.votes['' + choice.value]) || 0,
    }
  })

  return (
    <Tile>
      <Prompt color={survey.styles?.questionTextColor || '#fff'}>{question.prompt}</Prompt>
      <Metrics>
        {totalVotes} total votes
        {choices_with_votes.map((choice: { label: string; value: number; votes: number }, index: number) => {
          // const ratio = totalVotes === 0 ? 0 : Math.round((choice.votes * 100) / totalScore)
          const ratio = totalVotes === 0 ? 0 : Math.round((100 * question.votes[index + '']) / totalVotes)
          return (
            <Metric key={index}>
              <MetricInfo>
                <MetricName>{choice.label}</MetricName>
                <MetricValue>{ratio}%</MetricValue>
              </MetricInfo>
              <MetricBar width={ratio / 100} />
            </Metric>
          )
        })}
      </Metrics>
    </Tile>
  )
}

const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  padding: 0 40px 40px 40px;
`

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0;
`

const MetricInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MetricName = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
`

const MetricValue = styled.div`
  font-weight: 500;
  color: #ffffff;
  font-size: 18px;
`

const MetricBar = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: ${({ width }) => `${width * 100}%`};
  background: #ffffff;
  border-radius: 3px;
  margin-top: 5px;
`
