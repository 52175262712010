import styled from 'styled-components/macro'

interface Props {
  type?: 'normal' | 'dangerous'
  action?: () => void
  children: React.ReactNode
}

export const ContextMenuItem = ({ type = 'normal', action, children }: Props) => {
  return (
    <MenuItem type={type} onClick={action}>
      {children}
    </MenuItem>
  )
}

const MenuItem = styled.div<{ type: 'normal' | 'dangerous' }>`
  min-width: 100px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
  transition: 0.1s all;

  &:hover {
    background: ${({ type }) => (type === 'normal' ? '#4e8ceb' : '#eb4e4e')};
  }
`
