import { AddPhotoAlternateRounded } from '@mui/icons-material'
import { useAppDispatch } from 'app/hooks'
import { useEffect, useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import styled from 'styled-components/macro'
import { useBlurhash } from 'utils/useBlurhash'

const UNSPLASH_ACCESS_KEY = 'QopRWukXle_ELS-Kz2MLc4GVEuldLraCYKjyYGm2vbE'

export const QuestionImagePicker = ({
  question,
  choiceIndex,
  keyword = 'abstract',
  onChangeImageUrl,
  fancy = true,
  compact = false,
  style,
}: {
  question: Question
  choiceIndex?: number // index of the question choice
  keyword?: string
  onChangeImageUrl?: any
  fancy?: boolean
  compact?: boolean
  style?: any
}) => {
  const dispatch = useAppDispatch()
  const [suggestedImage, setSuggestedImage] = useState<any>()
  const [blurhashCanvasRef, drawBlurhash] = useBlurhash()

  useEffect(() => {
    if (fancy) {
      drawBlurhash()
    }
    loadSuggestion(keyword)
  }, [])

  const loadSuggestion = (keyword) => {
    if (keyword) {
      fetch(
        `https://api.unsplash.com/search/photos?page=1&per_page=1&orientation=portrait&order_by=relevant&query=${keyword}`,
        {
          // todo: move this ajax into utility
          method: 'GET',
          headers: {
            Authorization: 'Client-ID ' + UNSPLASH_ACCESS_KEY,
          },
        }
      )
        .then((response) => response.text())
        .then((body) => JSON.parse(body))
        .then((body) => {
          if (body.results.length > 0) {
            setSuggestedImage(body.results[0])
          }
        })
    }
  }

  return (
    <StyledQuestionImagePicker style={style} compact={compact}>
      {fancy && <BlurhashCanvas ref={blurhashCanvasRef} width={390} height={610} />}

      <Foreground>
        <AddImageSection>
          {/* <Button variant="contained" startIcon={<AddPhotoAlternateRounded />}>Add an image</Button> */}
          <AddImageButton>
            <AddPhotoAlternateRounded
              sx={{
                color: '#fffc',
                marginRight: '10px',
                width: compact ? '24px' : '32px',
                height: compact ? '24px' : '32px',
              }}
            />
            <AddImage compact={compact} onClick={() => dispatch(photoEditorOpened({ question, choiceIndex }))}>
              Add an image
            </AddImage>
          </AddImageButton>
        </AddImageSection>
        <Divider>
          <Spacer />
          <p style={{ margin: '0 10px', color: '#eeee' }}>OR</p>
          <Spacer />
        </Divider>
        <SuggestedImageSection>
          {!suggestedImage && <p style={{ color: '#fffa', fontWeight: 'normal' }}>Loading suggestion...</p>}
          {suggestedImage && (
            <>
              <SuggestionText compact={compact}>suggested image</SuggestionText>
              <SuggestionSubtext compact={compact}>click to use</SuggestionSubtext>
              <FaderMask onClick={() => onChangeImageUrl(suggestedImage.urls.full)}>
                <SuggestedImage src={suggestedImage.urls.small} />
              </FaderMask>
            </>
          )}
        </SuggestedImageSection>
      </Foreground>
    </StyledQuestionImagePicker>
  )
}

const StyledQuestionImagePicker = styled.div<{ compact?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: ${({ compact }) => (compact ? '100%' : '359px')};
  aspect-ratio: 390 / 610;
  object-fit: cover;
`

const BlurhashCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Foreground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  z-index: 1;
`

const AddImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const AddImageButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px 0;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  backdrop-filter: blur(2px);
`

const AddImage = styled.p<{ compact?: boolean }>`
  color: #fff;
  font-size: ${({ compact }) => (compact ? '16px' : '20px')};
  font-weight: 500;
`

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  height: 1px;
  min-height: 1px;
  box-sizing: border-box;
`

const Spacer = styled.div`
  flex: 1;
  height: 1px;
  min-height: 1px;
  background-color: #eeee;
`

const SuggestedImageSection = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 4;

  filter: drop-shadow(0px 1px 5px #5553);
  -webkit-filter: drop-shadow(0px 1px 5px #5553);
  -moz-filter: drop-shadow(0px 1px 5px #5553);
  -ms-filter: drop-shadow(0px 1px 5px #5553);
  -o-filter: drop-shadow(0px 1px 5px #5553);

  &:hover {
    filter: drop-shadow(0px 1px 5px #5557);
    -webkit-filter: drop-shadow(0px 1px 5px #5557);
    -moz-filter: drop-shadow(0px 1px 5px #5557);
    -ms-filter: drop-shadow(0px 1px 5px #5557);
    -o-filter: drop-shadow(0px 1px 5px #5557);
  }
`

const SuggestedImage = styled.img`
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 2px 8px -4px #000a;
  cursor: pointer;
`

const FaderMask = styled.div`
  max-width: 80%;
  max-height: 80%;
  border-radius: 5px;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 40%, transparent 100%);

  &:hover {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0.3) 100%);
    /* mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 80%, transparent 100%); */
  }
`

const SuggestionText = styled.p<{ compact?: boolean }>`
  color: #fff;
  font-size: ${({ compact }) => (compact ? '14px' : '18px')};
  z-index: 1;
  pointer-events: none;
  font-weight: 500;
`

const SuggestionSubtext = styled.p<{ compact?: boolean }>`
  margin-top: 6px;
  margin-bottom: -60px;
  color: #fffc;
  font-size: ${({ compact }) => (compact ? '12px' : '16px')};
  font-weight: 600;
  z-index: 1;
  pointer-events: none;
`
