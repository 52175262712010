import { UsageWidget } from 'pages/Workspaces/UsageWidget'
import { useGetUserQuery } from 'services/userApi'
import styled from 'styled-components/macro'

export const StripePlan = () => {
  const { data: user, isLoading: userLoading } = useGetUserQuery()

  return (
    <Panel>
      <PlanTitle>{user?.planType || 'loading...'}</PlanTitle>
      <UsageWidget />
    </Panel>
  )
}

const Panel = styled.div`
  width: 300px;
  height: 200px;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const PlanTitle = styled.p`
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
`
