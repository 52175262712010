import { Add, ChevronLeft, HomeRounded, Visibility } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { ContextMenu, ContextMenuItem } from 'components/ContextMenu'
import { Carousel, CarouselClip, CarouselPage } from 'components/Elements/Carousel'
import { underlinedNavItem } from 'components/Elements/navStyles'
import { Sidebar, SidebarDivision } from 'components/Elements/Sidebar'
import { SurveyWizard } from 'pages/Engage/SurveyWizard'
import { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDeleteEndingMutation, usePostEndingMutation } from 'services/endingApi'
import { useDeleteQuestionMutation, usePostQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { centered } from 'styles/layout'
import { useLocalStorage } from 'utils/useLocalStorage'

const QUESTION_TYPES: any[] = [
  // {
  //   label: 'Standard',
  //   type: 'section',
  // },
  {
    label: '',
    type: 'intro',
    icon: '/img/standard_icons/text.png',
  },
  {
    label: '',
    type: 'boolean',
    icon: '/img/standard_icons/boolean.png',
  },
  // {
  //   label: '',
  //   type: 'multiple-choice',
  //   icon: '/img/standard_icons/multiple-choice.png',
  // },
  {
    label: '',
    type: 'image-choice',
    icon: '/img/standard_icons/image-choice.png',
  },
  // {
  //   label: '',
  //   type: 'rank',
  //   icon: '/img/standard_icons/rank.png',
  // },
  {
    label: '',
    type: 'image-rank',
    icon: '/img/standard_icons/image-rank.png',
  },
  {
    label: '',
    type: 'rate',
    icon: '/img/standard_icons/rate.png',
  },
  {
    label: '',
    type: 'text',
    icon: '/img/standard_icons/text.png',
  },
  // {
  //   label: 'Immersive',
  //   type: 'section',
  // },
  // {
  //   label: '',
  //   type: 'card-boolean',
  //   icon: '/img/standard_icons/card-boolean.png',
  // },
  // {
  //   label: 'Card Multiple Choice',
  //   type: 'card-multiple-choice',
  //   video: 'https://i.gyazo.com/f0a7f1186bf974e407256823094990a8.mp4',
  // },
  // {
  //   label: '',
  //   type: 'card-ranking',
  //   icon: '/img/standard_icons/card-ranking.png',
  // },
]

export const LeftBar = ({
  experience,

  open,
  onToggleOpen,

  selectedQuestion,
  onSelectQuestion,

  experienceMapOpen,
  onToggleExperienceMapOpen,

  setStagingSurvey, // todo: revisit this because the pass-down for direct call is a different paradigm than the pass-down of callback handlers so the business logic can stay upstairs

  handleChangeTabsProxy,
}: {
  experience: any

  open: boolean
  onToggleOpen: any

  experienceMapOpen: any
  onToggleExperienceMapOpen: any

  selectedQuestion: any
  onSelectQuestion: any

  setStagingSurvey: any

  handleChangeTabsProxy?: any
}) => {
  const survey = experience as Partial<Survey>

  const [postQuestion] = usePostQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  const [postEnding] = usePostEndingMutation()
  const [deleteEnding] = useDeleteEndingMutation()

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [surveyWizardOpen, setSurveyWizardOpen] = useLocalStorage('storyforms.go.wizard.open', true)

  const sidebarWidth = useMemo(() => (surveyWizardOpen ? 340 : 250), [surveyWizardOpen])

  const prevActiveTab = useRef(activeTabIndex)

  useEffect(() => {
    handleChangeTabsProxy.current = handleChangeTabs
  }, [])

  useEffect(() => {
    if (selectedQuestion) {
      if (selectedQuestion.type === 'ending') {
        if (activeTabIndex !== 1) {
          setActiveTabIndex(1)
        }
      } else if (activeTabIndex !== 0) {
        setActiveTabIndex(0)
      }
    }
  }, [selectedQuestion])

  // useEffect(() => {
  //   if (prevActiveTab.current === activeTabIndex) {
  //     return
  //   }
  //   if (activeTabIndex === 1) {
  //     onSelectQuestion(survey.endings?.[0])
  //   } else if (activeTabIndex === 0 && prevActiveTab.current !== 2) {
  //     onSelectQuestion(survey.questions?.[0]) // todo: restore cached selection
  //   }
  //   prevActiveTab.current = activeTabIndex
  // }, [activeTabIndex])

  const handleChangeTabs = (tabIndex, target?) => {
    if (activeTabIndex === tabIndex) {
      return
    }
    if (!target) {
      // attempt to assign default target
      if (tabIndex === 1) {
        target = survey.endings?.[0]
      } else if (tabIndex === 0 && activeTabIndex !== 2) {
        target = survey.questions?.[0] // todo: restore cached selection
      }
    }
    if (target && target._id !== selectedQuestion?._id) {
      onSelectQuestion(target) // don't set tab index, because the selectedQuestion effect does this automatically
    } else {
      setActiveTabIndex(tabIndex)
    }
  }

  const duplicateQuestion = async (questionId: string, duplicatedAt: number) => {
    const question = { ...survey!.questions!.filter((question) => question._id === questionId)[0], _id: undefined }
    const result = await postQuestion({ ...question })
    if ('data' in result) {
      const questions = [...survey.questions!]
      questions.splice(duplicatedAt + 1, 0, result.data)
      onSelectQuestion(result.data)
      setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, questions }))
    }
  }

  const fullyDeleteQuestion = (question: Question) => {
    if (selectedQuestion && selectedQuestion._id === question._id) {
      // user is deleting the selected question. select adjacent question, if any

      //TODO: Figure out if we can refactor with a nice version of RTK invalidating tags that doesn't give Jared an aneurysm over server calls and doesn't leave Karl going crazy over cable management.

      var nextSelectionTarget
      const currentSelectedIndex = survey.questions?.findIndex((q) => q._id === question._id)
      if (currentSelectedIndex === 0 && survey.questions.length > 1) {
        // if deleting first question, select second question
        nextSelectionTarget = survey.questions[1]
      } else if (currentSelectedIndex > 0) {
        // if deleting any question other than the first, select the previous question in the survey
        nextSelectionTarget = survey.questions[currentSelectedIndex - 1]
      }
      onSelectQuestion(nextSelectionTarget)
    }
    setStagingSurvey((currentSurvey: any) => ({
      ...currentSurvey,
      questions: currentSurvey.questions?.filter((currentQuestion) => currentQuestion._id !== question._id),
    }))
    deleteQuestion({ _id: question._id })
  }

  const duplicateEnding = async (endingId: string, duplicatedAt: number) => {
    const ending = { ...survey!.endings!.filter((ending) => ending._id === endingId)[0], _id: undefined }
    const result = await postEnding({ ...ending })
    if ('data' in result) {
      const endings = [...survey.endings!]
      endings.splice(duplicatedAt + 1, 0, result.data)
      onSelectQuestion(result.data)
      setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, endings }))
    }
  }

  const fullyDeleteEnding = (ending: Question) => {
    if (selectedQuestion && selectedQuestion._id === ending._id) {
      // user is deleting the selected question. select adjacent question, if any
      var nextSelectionTarget
      const currentSelectedIndex = survey.endings?.findIndex((q) => q._id === ending._id)
      if (currentSelectedIndex === 0 && survey.endings.length > 1) {
        // if deleting first question, select second question
        nextSelectionTarget = survey.endings[1]
      } else if (currentSelectedIndex > 0) {
        // if deleting any question other than the first, select the previous question in the survey
        nextSelectionTarget = survey.endings[currentSelectedIndex - 1]
      }
      onSelectQuestion(nextSelectionTarget)
    }
    setStagingSurvey((currentSurvey: any) => ({
      ...currentSurvey,
      endings: currentSurvey.endings?.filter((currentEnding) => currentEnding._id !== ending._id),
    }))
    deleteEnding({ _id: ending._id })
  }

  const [draggingIndex, setDraggingIndex] = useState<number | null>(null)
  const handleDragStart = (index: number) => setDraggingIndex(index)

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault()

  const handleDrop = (index: number) => {
    const questions = [...survey.questions!]
    const draggedValue = questions.splice(draggingIndex!, 1)[0]
    questions.splice(index, 0, draggedValue)
    setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, questions }))
  }

  const sidebarSections = ['questions', 'endings']

  const Tabs = () => {
    return (
      <SidebarDivision style={{ justifyContent: 'space-around' }}>
        <Tooltip title="Back to Workspaces" placement="bottom">
          <NavLink to="/dashboard/workspaces">
            <NavItem
              key={'global-view'}
              style={{ borderBottom: 'none' }}
              selected={false}
              onClick={onToggleExperienceMapOpen}
            >
              <HomeRounded style={{ color: '#888' }} />
            </NavItem>
          </NavLink>
        </Tooltip>
        {sidebarSections.map((tabName, tabIndex) => (
          <NavItem
            key={tabIndex}
            selected={tabIndex === activeTabIndex}
            onClick={tabIndex === activeTabIndex ? undefined : () => handleChangeTabs(tabIndex)}
          >
            {tabName}
          </NavItem>
        ))}
        <Tooltip title="Global View" placement="bottom">
          <NavItem
            key={'global-view'}
            style={{ borderBottom: 'none' }}
            selected={false}
            onClick={onToggleExperienceMapOpen}
          >
            <Visibility style={{ color: experienceMapOpen ? '#3bb4d8' : '#888' }} />
          </NavItem>
        </Tooltip>
      </SidebarDivision>
    )
  }

  return (
    <Sidebar position="left" expanded={!!open} width={sidebarWidth} onToggleOpen={onToggleOpen}>
      <Tabs />
      <CarouselClip width={sidebarWidth} style={{ flex: 1 /* height: surveyWizardOpen ? '40%' : '100%' */ }}>
        <Carousel width={sidebarWidth} fieldNumber={activeTabIndex}>
          {/* Questions tab */}
          <CarouselPage width={sidebarWidth}>
            <ButtonWrapper>
              <Button
                onClick={() => handleChangeTabs(2)}
                size="small"
                sx={{
                  fontSize: '12px',
                  margin: '0 10px',
                  width: '100%',
                  padding: '8px 0',
                  backgroundColor: '#33347d',
                  '&:hover': {
                    backgroundColor: '#4446a4',
                  },
                }}
                startIcon={<Add />}
                variant="contained"
                tabIndex={-1}
              >
                Add a question
              </Button>
            </ButtonWrapper>

            <SidebarScroller>
              {survey?.questions?.map((question, index) => (
                <ContextMenu
                  key={index + '-' + question._id}
                  items={
                    <>
                      <ContextMenuItem
                        action={() => {
                          duplicateQuestion(question._id!, index)
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                      <ContextMenuItem
                        type="dangerous"
                        action={() => {
                          if (window.confirm('Are you sure you want to delete this question?')) {
                            fullyDeleteQuestion(question)
                          }
                        }}
                      >
                        Delete
                      </ContextMenuItem>
                    </>
                  }
                >
                  <SidebarItem
                    draggable="true"
                    onDragStart={(e) => handleDragStart(index)}
                    onDragOver={enableDropping}
                    onDrop={(e) => handleDrop(index)}
                    onClick={() => {
                      handleChangeTabs(0)
                      onSelectQuestion(question)
                    }}
                    selected={question._id === selectedQuestion?._id}
                  >
                    <ItemCounter onClick={() => onSelectQuestion(question)}>{index + 1}</ItemCounter>
                    {question.prompt || 'Untitled question'}
                  </SidebarItem>
                </ContextMenu>
              ))}
            </SidebarScroller>
          </CarouselPage>

          {/* Endings tab */}
          <CarouselPage width={sidebarWidth}>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  postEnding({ prompt: 'All done!', description: 'Thanks for stopping by!', choices: [] })
                    .unwrap()
                    .then((ending: Question) => {
                      const endings = [...(survey.endings || []), ending]
                      onSelectQuestion(ending)
                      setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, endings }))
                    })
                }}
                size="small"
                sx={{
                  fontSize: '12px',
                  margin: '0 10px',
                  width: '100%',
                  padding: '8px 0',
                  backgroundColor: '#33347d',
                  '&:hover': {
                    backgroundColor: '#4446a4',
                  },
                }}
                startIcon={<Add />}
                variant="contained"
                tabIndex={-1}
                disabled={survey.endings?.length > 0}
              >
                Add an ending
              </Button>
            </ButtonWrapper>

            <SidebarScroller>
              {survey?.endings?.map((ending, index) => (
                <ContextMenu
                  key={index + '-' + ending._id}
                  items={
                    <>
                      <ContextMenuItem
                        action={() => {
                          duplicateEnding(ending._id!, index)
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                      <ContextMenuItem
                        type="dangerous"
                        action={() => {
                          if (window.confirm('Are you sure you want to delete this ending?')) {
                            fullyDeleteEnding(ending)
                          }
                        }}
                      >
                        Delete
                      </ContextMenuItem>
                    </>
                  }
                >
                  <SidebarItem
                    draggable="true"
                    onDragStart={(e) => handleDragStart(index)}
                    onDragOver={enableDropping}
                    onDrop={(e) => handleDrop(index)}
                    onClick={() => {
                      onSelectQuestion(ending)
                    }}
                    selected={ending._id === selectedQuestion?._id}
                  >
                    <ItemCounter onClick={() => onSelectQuestion(ending)}>{index + 1}</ItemCounter>
                    {ending.prompt || 'Untitled ending'}
                  </SidebarItem>
                </ContextMenu>
              ))}
            </SidebarScroller>
          </CarouselPage>

          {/* 'Add a question' tab */}
          <CarouselPage width={sidebarWidth}>
            <div>
              <p style={{ fontSize: 16, padding: '10px 5px' }}>
                <ChevronLeft
                  sx={{ verticalAlign: 'middle', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleChangeTabs(0)}
                />
                <span style={{ verticalAlign: 'middle', fontWeight: 600, marginLeft: 4 }}>Add a question</span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  padding: 20,
                }}
              >
                {QUESTION_TYPES.map((questionType, index) =>
                  questionType.type === 'section' ? (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ flex: 1, height: 1, backgroundColor: '#333' }}></div>
                      <p style={{ margin: '0 8px' }}>{questionType.label}</p>
                      <div style={{ flex: 1, height: 1, backgroundColor: '#333' }}></div>
                    </div>
                  ) : (
                    <QuestionType
                      key={index}
                      onClick={() =>
                        postQuestion({ type: questionType.type, prompt: '', choices: [] })
                          .unwrap()
                          .then((question: Question) => {
                            onSelectQuestion(question)
                            handleChangeTabs(0)
                            const questions = [...(survey.questions || []), question]
                            setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, questions }))
                          })
                      }
                    >
                      {questionType.video ? (
                        <video
                          style={{ width: 100, cursor: 'pointer' }}
                          src={questionType.video}
                          typeof="video/mp4"
                          autoPlay={true}
                          muted={true}
                          loop={true}
                          controls={false}
                        />
                      ) : questionType.icon ? (
                        <img
                          style={{ width: 80, cursor: 'pointer' }}
                          alt={questionType.label}
                          src={questionType.icon}
                        />
                      ) : null}
                      {questionType.label && <p>{questionType.label}</p>}
                    </QuestionType>
                  )
                )}
              </div>
            </div>
          </CarouselPage>
        </Carousel>
      </CarouselClip>
      {/* <DraggableTray minHeight={100} style={{ backgroundColor: '#f4f4f4', borderTop: '1px solid #eeee' }}>
      </DraggableTray> */}
      <div style={{ position: 'relative' }}>
        <FadeToWhite />
        <ExpandableTray
          style={{
            maxHeight: surveyWizardOpen ? 600 : 65,
          }}
        >
          <div style={{ paddingTop: 20, background: 'linear-gradient(0deg, #fff0, #fff)' }}></div>
          <SurveyWizard
            title={
              <div className="rcw-header-row" onClick={() => setSurveyWizardOpen(!surveyWizardOpen)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px auto',
                  }}
                >
                  <img src="/img/fox_front.png" style={{ width: 30, marginRight: 10 }} />
                  <GradientText style={{ fontSize: 20 }}>Storyforms AI</GradientText>
                </div>
                <Toggle
                  src="/img/legacy_icons/chevron-black.png"
                  style={{ transform: surveyWizardOpen ? 'rotateX(0deg)' : 'rotateX(180deg)', marginRight: 10 }}
                />
              </div>
            }
            /* title={
            <WavyContainer>
              <WaveSvg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                <path
                  d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"
                  style={{ stroke: 'none', fill: '#11d2db' }}
                ></path>
              </WaveSvg>
              <div className="rcw-header-row">
                <p style={{ margin: 20, color: '#fff', fontSize: 20 }}>Storyforms AI</p>
                <Toggle
                  src="/img/legacy_icons/chevron-black.png"
                  style={{ transform: surveyWizardOpen ? 'rotateX(0deg)' : 'rotateX(180deg)' }}
                  onClick={() => setSurveyWizardOpen(!surveyWizardOpen)}
                />
              </div>
            </WavyContainer>
          } */
            survey={survey}
            setStagingSurvey={setStagingSurvey}
            open={surveyWizardOpen}
          />
        </ExpandableTray>
      </div>
    </Sidebar>
  )
}

const SidebarScroller = styled.div`
  overflow: auto;
  padding-bottom: 60px;
  /* height: calc(
    100vh - 290px
  ); // accounts for 50px page topbar, 40px sidebar topbar, 200px redirect uri bottom tray */
`

export const SectionHeader = styled.p`
  font-size: 16px;
  color: '#08324c';
  font-weight: 500;
`

export const SidebarItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid #eeeeee;
  background: ${({ selected }) => (selected ? '#eeeeee' : 'transparent')};

  &:hover {
    background: ${({ selected }) => (selected ? '#eeeeee' : '#f8f8f8')};
  }
`

export const AddButton = styled.span`
  ${centered}
  height: 20px;
  width: 20px;
  background: #dedede;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background: #cccccc;
  }
`

export const ItemCounter = styled.div`
  ${centered}
  border-radius: 6px;
  min-width: 20px;
  padding: 2px 3px;
  height: 100%;
  /* background: linear-gradient(45deg, rgb(17, 210, 219), rgb(13, 131, 217)); */
  background-color: #5db0cf;
  color: #ffffff;
  margin-right: 10px;
`

const QuestionType = styled.div`
  margin: 10px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 300ms ease;

  &:hover {
    opacity: 1;
  }
`

const NavItem = styled.div<{ selected: boolean }>`
  ${underlinedNavItem}
  margin: 0 10px;
  height: 40px;
  font-size: 12px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
`

const ExpandableTray = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  transition: max-height 300ms ease;
  background: linear-gradient(10deg, rgb(13, 131, 217, 0.5), rgb(17, 210, 219, 0.2));
`

const FadeToWhite = styled.div`
  height: 20px;
  background: linear-gradient(0deg, #fff 15%, #fff0);
  position: absolute;
  width: 100%;
  top: -20px;
`

const GradientText = styled.p`
  background-color: #08324c;
  background-image: linear-gradient(45deg, rgba(13, 131, 217, 0.9), rgba(17, 210, 219, 0.9));
  background-size: 100%;
  background-clip: text;
  background-repeat: repeat;
  font-family: 'Avenir';
  font-weight: 500;
  width: max-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

const Toggle = styled.img`
  float: right;
  margin-right: 8px;
  width: 15px;
  cursor: pointer;
  transition: transform 300ms ease;
  filter: invert(0.5);
`

const WaveSvg = styled.svg`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

const WavyContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
`
