import React from 'react'
import styled from 'styled-components/macro'

interface Props {
  text: any
  position?: 'left' | 'right' | 'top' | 'bottom'
  disabled?: boolean
  style?: React.CSSProperties
  children: React.ReactNode
}

export const TooltipText = ({ text, position = 'right', disabled = false, style, children }: Props) => {
  //@karl @jared, fade in tooltip, wait 0.5s for tooltip to show up after mouseover
  return (
    <Tooltip>
      {!disabled && (
        <TooltipOverlay position={position} style={style}>
          {text}
        </TooltipOverlay>
      )}
      {children}
    </Tooltip>
  )
}

const TooltipOverlay = styled.span<{ position: 'left' | 'right' | 'top' | 'bottom' }>`
  visibility: hidden;
  min-width: 120px;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px 10px;
  position: absolute;
  z-index: 999;
  top: -15px;
  font-size: 12px;
  font-weight: normal;
  left: ${({ position }) => (position === 'right' ? '110%' : 'unset')};
  right: ${({ position }) => (position === 'left' ? '110%' : 'unset')};

  ${({ position }) =>
    position === 'right' || position === 'bottom' || position === 'top' ? '&:after' : '&:before'} {
    content: '';
    position: absolute;
    top: ${({ position }) => (position === 'bottom' ? '-5px' : position === 'top' ? 'unset' : '25px')};
    bottom: ${({ position }) => (position === 'top' ? '-5px' : 'unset')};

    margin-top: ${({ position }) => (position === 'top' ? 'unset' : '-5px')};
    margin-bottom: ${({ position }) => (position === 'bottom' ? 'unset' : '-5px')};
    border-width: 5px;
    border-style: solid;
    left: ${({ position }) =>
      position === 'bottom' || position === 'top' ? '25px' : position === 'right' ? 'unset' : '100%'};
    right: ${({ position }) => (position === 'left' ? 'unset' : '100%')};
    border-color: ${({ position }) =>
      position === 'right'
        ? 'transparent #000000 transparent transparent'
        : 'transparent transparent transparent #000000'};
    transform: ${({ position }) =>
      position === 'bottom' ? 'rotate(-90deg)' : position === 'top' ? 'rotate(90deg)' : 'unset'};
  }
`

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  text-decoration: underline dotted 1.5px;
  text-underline-position: under;
  text-decoration-color: #aaa;

  &:hover ${TooltipOverlay} {
    visibility: visible;
  }
`
