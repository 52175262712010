import styled from 'styled-components/macro'

import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined'

interface Props {
  orientation: 'desktop' | 'mobile'
  setOrientation: (orientation: 'desktop' | 'mobile') => void
}

export const DeviceSelector = ({ orientation, setOrientation }: Props) => {
  return (
    <StyledDeviceSelector>
      <Desktop selected={orientation === 'desktop'} onClick={() => setOrientation('desktop')} />
      <Mobile selected={orientation === 'mobile'} onClick={() => setOrientation('mobile')} />
    </StyledDeviceSelector>
  )
}

const StyledDeviceSelector = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 10px;
`

const Mobile = styled(SmartphoneOutlinedIcon)<{ selected: boolean }>`
  margin: 0px 4px;
  color: ${({ selected }) => (selected ? '#aaaaaa' : '#cccccc')};
  cursor: pointer;
  &:hover {
    color: #bbbbbb;
  }
`

const Desktop = styled(DesktopWindowsOutlinedIcon)<{ selected: boolean }>`
  margin: 0px 4px;
  color: ${({ selected }) => (selected ? '#aaaaaa' : '#cccccc')};
  cursor: pointer;
  &:hover {
    color: #bbbbbb;
  }
`
