import { Navigate, useLocation } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components/macro'

import { ThemeContext } from 'App'
import { useAppSelector } from 'app/hooks'
import { SurveyWizard } from 'pages/Engage/SurveyWizard'
import { useContext, useState } from 'react'
import { centered } from 'styles/layout'
import { storyform } from 'styles/theme'
import usePageTitle from 'utils/usePageTitle'
import { ConfirmEmail } from './ConfirmEmail'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { ShimmerField } from './Shimmer'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

export const Login = () => {
  const { authenticated, loginPage, recoveryAddress } = useAppSelector((state) => state.auth)
  const location = useLocation()
  const { from } = (location.state as any) || { from: { pathname: '/' } }
  const [counter, setCounter] = useState(0)
  const theme = useContext(ThemeContext)

  const infoTextSubstitutions = [
    { text: 'digital engagement', fontSize: 50 },
    { text: 'customer loyalty', fontSize: 50 },
    { text: 'lead generation', fontSize: 50 },
    { text: 'product matching', fontSize: 50 },
  ]

  const loginPages = ['resetPassword', 'forgotPassword', 'signIn', 'signUp', 'confirmEmail']

  const displayedInfo = infoTextSubstitutions[counter % infoTextSubstitutions.length]

  usePageTitle('Login | Storyforms')

  return (
    <>
      {authenticated ? (
        <Navigate to={from.pathname} />
      ) : (
        <ThemeProvider theme={storyform[theme]}>
          <StyledLogin loaded={false}>
            <InputSection>
              <ShimmerField />
              <LoginCarousel fieldNumber={loginPages.indexOf(loginPage)}>
                <ResetPassword />
                <ForgotPassword />
                <SignIn />
                <SignUp />
                <ConfirmEmail />
              </LoginCarousel>
            </InputSection>

            <InfoSection>
              <InfoText>
                <div style={{ margin: '0 40px 0 auto' }}>
                  <img src="/img/fox_front.png" style={{ width: 200, marginRight: 10 }} />
                  <div style={{ margin: '40px 0' }}>
                    <p style={{ fontSize: 60, color: '#fff' }}>Storyforms AI</p>
                    <p style={{ fontSize: 60, color: '#0d96ff' }}>quickly creates</p>
                    <p style={{ fontSize: 60, color: '#fff' }}>
                      gamified <span style={{ color: '#0d96ff' }}>forms</span>
                    </p>
                  </div>
                  <div style={{ maxWidth: 600 }}>
                    <p style={{ color: '#fffd', fontSize: 24 }}>
                      Work smarter not harder by utilizing our AI assistant to help you quickly build complete
                      gamified forms with images!
                    </p>
                  </div>
                </div>
              </InfoText>
              <InfoGraphic>
                <WizardWrapper>
                  <SurveyWizard
                    title={
                      <div className="rcw-header-row">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '10px auto',
                          }}
                        >
                          <img src="/img/fox_front.png" style={{ width: 30, marginRight: 10 }} />
                          <GradientText style={{ fontSize: 20 }}>Storyforms AI</GradientText>
                        </div>
                        <Toggle src="/img/legacy_icons/chevron-black.png" />
                      </div>
                    }
                    setStagingSurvey={undefined}
                  />
                </WizardWrapper>
              </InfoGraphic>
              {/* <BannerBackground>
                <Engagement>
                  the future of
                  <br />
                  <PinkText size={displayedInfo.fontSize}>{displayedInfo.text}</PinkText>
                  <br />
                  is here
                </Engagement>
              </BannerBackground> */}
              <LoginVisual></LoginVisual>
            </InfoSection>
          </StyledLogin>
        </ThemeProvider>
      )}
    </>
  )
}

const StyledLogin = styled.div<{ loaded: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in;
  overflow: hidden;

  height: 100vh;
  width: 100vw;
`

const InputSection = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  max-width: 400px;
  border-right: 1px solid ${({ theme }) => theme.centerSplit};
  background: ${({ theme }) => theme.inputBackground};
`

const LoginCarousel = styled.div<{ fieldNumber: number }>`
  ${centered}
  width: 500%;
  transform: ${({ fieldNumber }) => `translateX(${-400 * fieldNumber}px)`};
  transition: 0.2s all;
`

const InfoSection = styled.div`
  position: relative;
  display: flex;
  flex: 3;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  background-image: url('/img/login_visual_bg.jpeg');
  background-size: cover;
`

const BannerBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgb(22 94 139), rgb(129, 203, 227));
  background: ${({ theme }) => theme.splashBackground};
  background-size: cover;
`

const Engagement = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 50px;
  font-weight: 500;
`

const PinkText = styled.span<{ size: number }>`
  font-size: ${({ size }) => `${size}px`};
  color: #e814d8;
`

const CardDecks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const LoginVisual = styled.div`
  background-image: url('/img/login_visual.jpeg');
  height: 100vh;

  z-index: 99999;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const GradientText = styled.p`
  background-color: #08324c;
  background-image: linear-gradient(45deg, rgba(13, 131, 217, 0.9), rgba(17, 210, 219, 0.9));
  background-size: 100%;
  background-clip: text;
  background-repeat: repeat;
  font-family: 'Avenir';
  font-weight: 500;
  width: max-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

const Toggle = styled.img`
  float: right;
  margin-right: 8px;
  width: 15px;
  cursor: pointer;
  transition: transform 300ms ease;
  filter: invert(0.5);
`

const WizardWrapper = styled.div`
  width: 330px;
  right: 200px;
  bottom: 200px;
  margin-right: 80px;
  margin-left: 40px;
`

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  margin-left: 40px;
  padding-bottom: 120px;
`

const InfoGraphic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
