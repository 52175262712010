import { useAppDispatch } from 'app/hooks'
import { RootState } from 'app/store'
import { CanvasAction, ExportFormat, ImageFormat, PhotoEditorSDKUI, Tool, UIEvent } from 'photoeditorsdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetS3SignedUrlMutation } from 'services/miscApi'
import { photoEditorClosed } from 'services/photoEditorSlice'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { UnsplashProvider } from './UnsplashProvider'

export const PhotoEditor = ({ image, setStagingSurvey }: { image: string; setStagingSurvey?: any }) => {
  const dispatch = useAppDispatch()
  const [getSignedUrl] = useGetS3SignedUrlMutation()

  const { question: editingQuestion, choiceIndex: editingChoiceIndex } = useSelector(
    (state: RootState) => state.photoEditor
  )
  const [putQuestion] = usePutQuestionMutation()

  const initEditor = async () => {
    const editor = await PhotoEditorSDKUI.init({
      container: '#photo-editor',
      image,
      license: process.env.REACT_APP_IMGLY_LICENSE,
      engine: {
        crossOrigin: 'anonymous',
        downscaleOptions: {
          maxMegaPixels: {
            desktop: 1,
            mobile: 1,
          },
        },
      },
      export: {
        image: {
          enableDownload: false,
          format: ImageFormat.PNG,
          exportType: ExportFormat.BLOB,
        },
      },
      mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO, CanvasAction.EXPORT, CanvasAction.CLOSE],
      forceCrop: true,
      custom: {
        languages: {
          en: {
            mainCanvasActions: {
              buttonExport: 'Save',
            },
            library: {
              controls: {
                placeholderSearch: 'Search images...',
              },
            },
          },
        },
      },
      defaultTool: Tool.LIBRARY,
      library: {
        enableUpload: true,
        flattenCategories: true,
        provider: UnsplashProvider,
      },
      layout: 'advanced',
      assetBaseUrl: '/assets',
    })

    editor.on(UIEvent.CLOSE, () => {
      dispatch(photoEditorClosed())
    })

    editor.on(UIEvent.EXPORT, (blob: Blob) => {
      // todo: show fullscreen spinner
      // todo: enforce blob.size limit
      getSignedUrl().then(async (value) => {
        if ('error' in value) {
          console.error(value.error)
        } else if ('data' in value) {
          dispatch(photoEditorClosed())
          await fetch(value.data.signedRequest, {
            // todo: move this ajax into utility
            method: 'PUT',
            headers: {
              'content-type': blob.type,
            },
            body: blob,
          })
          if (['image-choice', 'image-rank'].indexOf(editingQuestion.type) !== -1 && editingChoiceIndex !== -1) {
            // image belongs to one of the question's choices
            const choices = editingQuestion.choices.map((choice, choiceIndex) =>
              choiceIndex === editingChoiceIndex
                ? { ...choice, image: { url: value.data.resourceUrl } }
                : { ...choice }
            )
            putQuestion({ _id: editingQuestion._id, delta: { choices } })
            setStagingSurvey((currentSurvey: any) => {
              return {
                ...currentSurvey,
                questions: currentSurvey.questions.map((question) =>
                  question._id === editingQuestion._id ? { ...question, choices } : question
                ),
              }
            })
          } else {
            // image belongs to the question itself
            putQuestion({ _id: editingQuestion._id, delta: { image: { url: value.data.resourceUrl } } })
            setStagingSurvey((currentSurvey: any) => {
              return {
                ...currentSurvey,
                questions: currentSurvey.questions.map((question) =>
                  question._id === editingQuestion._id
                    ? { ...question, image: { url: value.data.resourceUrl } }
                    : question
                ),
              }
            })
          }
          // todo: hide fullscreen spinner
        }
      })
    })
  }

  useEffect(() => {
    initEditor()
  }, [])

  return (
    <>
      <StyledPhotoEditor id="photo-editor" />
      <CloseButton
        onClick={() => {
          dispatch(photoEditorClosed())
        }}
      >
        Close
      </CloseButton>
    </>
  )
}

const StyledPhotoEditor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`

const CloseButton = styled.button`
  position: fixed;
  top: 9px;
  right: 24px;
  padding: 4px 8px;
  min-height: 32px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 4px;
  background-color: #000;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  z-index: 999;
  cursor: pointer;
`
