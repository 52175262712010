import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

// S/O https://stackoverflow.com/a/66084680
export const BackButton = ({
  backLabel = 'Go back',
  homeLabel = 'Go home',
  homePath = '/dashboard', // used if !hasPreviousState
  style,
}: {
  backLabel?: any
  homeLabel?: any
  homePath?: string
  style?: any
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousState = location.key !== 'default'

  const goBack = () => {
    if (hasPreviousState) {
      navigate(-1)
    } else {
      navigate(homePath)
    }
  }

  return (
    <StyledBackButton style={style} onClick={goBack}>
      {hasPreviousState ? backLabel : homeLabel}
    </StyledBackButton>
  )
}

const StyledBackButton = styled.p`
  background-color: transparent;
  color: #333;
  line-height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
`
