import { StyleSheet, Text, View } from '@react-pdf/renderer'

export const RateVitals = ({ question, index, votes }: { question: Question; index: number; votes: any }) => {
  const total = votes?.total || 0

  const left = !total ? 0 : (votes?.['0'] / total) * 100 || 0
  const mid_l = !total ? 0 : (votes?.['1'] / total) * 100 || 0
  const center = !total ? 0 : (votes?.['2'] / total) * 100 || 0
  const mid_r = !total ? 0 : (votes?.['3'] / total) * 100 || 0
  const right = !total ? 0 : (votes?.['4'] / total) * 100 || 0

  const dropoffRate = (question.dropoffRate || 0) * 100
  return (
    <View style={count.container}>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${left}%`, backgroundColor: '#8383f1' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(left)}% ${question.choices[0].label} (${(
            votes?.['0'] || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${mid_l}%`, backgroundColor: '#bbb4f8' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(mid_l)}% (${(
            votes?.['1'] || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${center}%`, backgroundColor: '#d2d2d2' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(center)}% Neutral (${(
            votes?.['2'] || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${mid_r}%`, backgroundColor: '#ffd1d8' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(mid_r)}% (${(
            votes?.['3'] || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      <View style={count.bar}>
        <View style={[count.barFill, { width: `${right}%`, backgroundColor: '#ea919e' }]}>
          <Text style={count.barLabelOverflow}>{`${Math.round(right)}% ${question.choices[1].label} (${(
            votes?.['4'] || 0
          ).toLocaleString()} votes)`}</Text>
        </View>
      </View>
      {/* {index !== 0 && (
          <View style={[count.bar, { marginTop: 20 }]}>
            <View style={[count.barFill, { width: `${100 - dropoffRate}%`, backgroundColor: '#0fc2cd' }]}>
              <Text style={count.barLabelOverflow}>{`${Math.round(100 - dropoffRate)}% Retention`}</Text>
            </View>
          </View>
        )} */}
    </View>
  )
}

const count = StyleSheet.create({
  container: {
    marginTop: 18,
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 300,
    height: 22,
    // border: '1px solid #ddd',
    marginLeft: 8,
    marginBottom: 6,
  },
  barFill: {
    height: 20,
    border: 'none',
    color: '#ffffff',
    minWidth: 10,
  },
  barLabel: {
    width: '100%',
    color: 'rgb(255, 255, 255)',
    zIndex: 999,
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  barLabelOverflow: {
    fontSize: 10,
    color: '#333',
    left: '100%',
    marginLeft: 4,
    marginTop: 3,
    width: 300,
  },
})
