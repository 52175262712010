import { useAppSelector } from 'app/hooks'
import {
  addResponseMessage,
  addUserMessage,
  deleteMessages,
  dropMessages,
  renderCustomComponent,
  setQuickButtons,
  toggleInputDisabled,
  toggleMsgLoader,
  Widget,
} from 'lib/ChatWidget'
import { useEffect, useRef, useState } from 'react'

import { usePostQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'

// const OPENAI_API_KEY = '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'
const OPENAI_API_KEY = 'sk-proj-VSvieLAtbIOXahPLBhbjT3BlbkFJL7LjfSwovwn7XzIrHTMf'

export const SurveyWizard = ({
  survey,
  setStagingSurvey,
  title,
  open,
}: {
  survey?: Partial<Survey>
  setStagingSurvey: any
  title?: any
  open?: boolean
}) => {
  const [postQuestion] = usePostQuestionMutation()

  const authenticated = useAppSelector((state) => state.auth.authenticated)

  const [currentStep, setCurrentStep] = useState<string>('surveyTopic')

  const surveyTopic = useRef('')
  const surveyLength = useRef(3)
  const surveyGuidelines = useRef('')

  const { Configuration, OpenAIApi } = require('openai')

  const configuration = new Configuration({
    apiKey: OPENAI_API_KEY,
  })
  const openai = new OpenAIApi(configuration)

  const steps = {
    intro: {
      // prompt: `Hi! I'm the Storyforms AI Assistant. How can I help you build your form?`,
      prompt: `Hi! I'm the Storyforms AI Assistant. I can help you build your form!`,
      quickButtons: !!survey
        ? [
            { label: 'Suggest questions', value: 'Suggest questions' },
            // { label: 'Expand this form', value: 'Expand this form' },
          ]
        : [{ label: 'Suggest questions', value: 'survey' }],
      disableTextInput: true,
      clearPrevious: true,
    },
    surveyTopic: {
      prompt: 'What topic would you like to ask about?',
      disableTextInput: false,
      clearPrevious: true,
      quickButtons: [],
    },
    surveyLength: {
      prompt: `How many questions do you want? (Type a number)`,
      // quickButtons: [
      //   { label: '3', value: '3' },
      //   { label: '5', value: '5' },
      //   { label: '10', value: '10' },
      // ],
    },
    surveyGuidelines: {
      prompt: 'Any additional guidelines?',
      quickButtons: [{ label: 'Nope', value: 'Nope' }],
    },
    surveyGenerating: {
      prompt: 'Got it! Generating some questions. This may take a minute...',
    },
    surveyGenerated: {
      quickButtons: [
        {
          label: 'Generate more',
          value: 'generate-more',
        },
        {
          label: 'Change topic',
          value: 'change-topic',
        },
        // {
        //   label: 'Generate more questions',
        //   value: '',
        // },
      ],
    },
    surveyGenerationError: {},
    questionsGenerating: {
      prompt: 'Ok, generating more questions. Sit tight!',
    },
  }

  useEffect(() => {
    if (open) {
      setTimeout(focusInput, 300)
    }
  }, [open])

  useEffect(() => {
    const step = steps[currentStep]
    if (!step) {
      return
    }
    if (step.clearPrevious) {
      dropMessages()
    }
    if (step.prompt) {
      addResponseMessage(step.prompt)
    }
    if (step.quickButtons) {
      setQuickButtons(step.quickButtons)
    }
    if (step.disableTextInput) {
      toggleInputDisabled()
      return () => toggleInputDisabled()
    } else {
      // refocus text input
      if (open) {
        focusInput()
      }
    }
  }, [currentStep])

  const focusInput = () => {
    try {
      const el: any = document.getElementsByClassName('rcw-input')[0]
      if (el?.focus) {
        el.focus()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const runDelayedWithMsgLoader = (fn) => {
    toggleMsgLoader()
    setTimeout(() => {
      toggleMsgLoader()
      fn()
    }, 1500)
  }

  const handleUserResponse = (userMessage?: string) => {
    let targetStep
    switch (currentStep) {
      case 'surveyTopic':
        targetStep = 'surveyLength'
        surveyTopic.current = userMessage?.trim()
        break
      case 'surveyLength':
        targetStep = 'surveyGenerating'
        const lengthInput = Number.parseInt(userMessage)
        if (!isNaN(lengthInput)) {
          surveyLength.current = lengthInput
        }
        setQuickButtons([]) // hide numerical quick buttons
        surveyGuidelines.current = userMessage?.trim() || ''
        setTimeout(generateSurvey, 1501)
        // targetStep = 'surveyGuidelines'
        break
      case 'surveyGuidelines':
        targetStep = 'surveyGenerating'
        setQuickButtons([]) // hide 'Nope' quick button
        surveyGuidelines.current = userMessage?.trim() || ''
        setTimeout(generateSurvey, 1501)
        break
      default:
        break
    }
    if (targetStep) {
      runDelayedWithMsgLoader(() => setCurrentStep(targetStep))
    }
  }

  const handleQuickResponse = (quickResponse: string) => {
    switch (quickResponse) {
      case 'change-topic':
        addUserMessage('Change topic')
        setCurrentStep('surveyTopic')
        break
      case 'generate-more':
        addUserMessage('Generate more')
        setCurrentStep('surveyLength')
        break
      case 'Suggest questions':
        addUserMessage(quickResponse)
        runDelayedWithMsgLoader(() => setCurrentStep('surveyTopic'))
        break
      case 'Expand this form':
        addUserMessage(quickResponse)
        break
      case 'Generate more questions':
        addUserMessage(quickResponse)
        runDelayedWithMsgLoader(() => setCurrentStep('questionsGenerating'))
        break
      default:
        switch (currentStep) {
          case 'surveyLength': // quickResponse is a stringified number
            addUserMessage(quickResponse)
            handleUserResponse(quickResponse)
            break
          case 'surveyGuidelines':
            addUserMessage(quickResponse)
            handleUserResponse() // handle the quick response case by passing no args instead of "Nope" (the current quickResponse value)
            break
          default:
            break
        }
        break
    }

    setQuickButtons([]) // hide quick buttons after one is clicked
  }

  const QuestionPreview = ({
    question,
    messageId,
    index = 0,
  }: {
    question: Partial<Question>
    messageId: string
    index: number
  }) => {
    return (
      <div className="rcw-response">
        <div className="rcw-message-text">
          <p style={{ marginBottom: 4 }}>{`Q${index + 1}. ${question.prompt}`}</p>
          {['multiple-choice', 'rank'].indexOf(question.type) !== -1 &&
            question.choices?.map((choice, choiceIndex) => (
              <p key={messageId + '-choice' + choiceIndex} style={{ marginLeft: 10 }}>{`${String.fromCharCode(
                'A'.charCodeAt(0) + choiceIndex
              )}. ${choice?.label || '[placeholder]'}`}</p>
            ))}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <QuestionPreviewButton
              style={{
                color: '#208b9d',
              }}
              onClick={() => {
                const { prompt, choices } = question
                let type
                if (question.type === 'multiple-choice') {
                  type = 'image-choice'
                } else if (question.type === 'rank') {
                  type = 'image-rank'
                } else {
                  type = question.type
                }
                postQuestion({ type, prompt, choices })
                  .unwrap()
                  .then((createdQuestion: Question) => {
                    setStagingSurvey((currentSurvey) => ({
                      ...currentSurvey,
                      questions: [...(currentSurvey.questions || []), createdQuestion],
                    }))
                  })
                  .catch((err) => {
                    if (!authenticated) {
                      alert('Login or sign up to use Storyforms AI')
                    } else {
                      console.error(err)
                    }
                  })
                deleteMessages(1, messageId)
              }}
            >
              Use this
            </QuestionPreviewButton>
            <QuestionPreviewButton style={{ color: 'gray' }} onClick={() => deleteMessages(1, messageId)}>
              Discard
            </QuestionPreviewButton>
          </div>
        </div>
      </div>
    )
  }

  const generateSurvey = async () => {
    toggleMsgLoader()
    const response = await openai.createChatCompletion({
      model: 'gpt-3.5-turbo',
      // model: 'gpt-4-0613',
      // model: 'text-davinci-003',
      // model: 'text-curie-001',
      messages: [
        {
          role: 'user',
          content: `Using type definitions:\n\nQuestion {\n  type:\n    'boolean' // empty choices\n    | 'multiple-choice'\n    | 'rank' // ordered preference\n    | 'rate' // sliding scale. choices array stores left and right labels of the rating slider as its two Choice objects\n    | 'text' // empty choices\n  prompt: string\n  choices: Choice[]\n}\n\nChoice {\n  label: string\n  value: number // 0-index, counting\n}\n\nproduce a JSON array of Question objects for a ${
            surveyLength.current
          } question survey about ${
            surveyTopic.current
          }. Output should be a valid JSON array, starting and ending with array brackets. Output should not contain any newline characters. Limit multiple choice and ranking questions to 4 choices. ${
            surveyGuidelines.current?.length > 0
              ? 'Additional guidelines for the form: ' + surveyGuidelines.current
              : ''
          }\n\n`,
        },
      ],
      temperature: 0.7,
      max_tokens: 845,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })
    if (response?.data?.choices?.[0].message?.content) {
      const content = response?.data?.choices?.[0].message?.content
      let questions = JSON.parse(content.substring(content.indexOf('['), content.lastIndexOf(']') + 1))
      for (let i = 0; i < questions.length; i++) {
        setTimeout(() => {
          const messageId = `${Date.now()}`
          renderCustomComponent(
            QuestionPreview,
            { question: questions[i], index: i, messageId: messageId },
            false,
            messageId
          )
          // addResponseMessage(questionStr)
        }, i * 500)
      }
      setTimeout(() => {
        toggleMsgLoader()
        setCurrentStep('surveyGenerated')
      }, (questions.length - 1) * 500)
    } else {
    }
  }

  return (
    <Widget
      title={title}
      emojis={false}
      autofocus={false}
      showTimeStamp={false}
      handleNewUserMessage={handleUserResponse}
      handleQuickButtonClicked={handleQuickResponse}
    />
  )
}

const QuestionPreviewButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`
