import { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useKey } from 'utils/useKey'

export const GrowingInput = ({
  defaultValue = '',
  placeholder,
  icon,
  onChange,
  style,
  editingBackground,
}: {
  defaultValue: string
  placeholder?: string
  icon?: any
  onChange?: any
  style?: any
  editingBackground?: string
}) => {
  const value = useRef(defaultValue)
  const [editing, setEditing] = useState(false)
  const inputElement = useRef(null)

  useKey('Escape', () => inputElement.current?.blur())

  return (
    <InputWrapper
      style={{
        border: editing ? '1px solid #ddd7' : undefined,
        ...style,
        cursor: editing ? 'text' : 'pointer',
        background: editing ? editingBackground || 'unset' : undefined,
      }}
      hover={style?.['&:hover']}
      onClick={
        !!defaultValue
          ? () => {
              setEditing(true)
              setTimeout(() => inputElement.current?.focus(), 1)
            }
          : () => {
              setEditing(true)
              value.current = ''
              setTimeout(() => inputElement.current?.focus(), 1)
            }
      }
    >
      {icon}
      {!defaultValue && !editing && <>{placeholder || '+ Add a label'}</>}
      <Input
        ref={inputElement}
        contentEditable={true}
        spellCheck={false}
        onBlur={() => setEditing(false)}
        style={{ visibility: !!defaultValue || editing ? 'visible' : 'collapse' }}
        onInput={(event: any) => {
          onChange(event.target.innerHTML)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
          // todo: deprecated
          if (event.keyCode === 13) {
            // 13 is the Enter/Return key
            event.preventDefault()
            inputElement.current?.blur()
          }
        }}
        dangerouslySetInnerHTML={{ __html: value.current }}
      ></Input>
    </InputWrapper>
  )
}

const InputWrapper = styled.div<{ hover?: string }>`
  max-width: 200px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  transition: 300ms border ease, 300ms background-color ease;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    ${({ hover }) => hover || 'border: 1px solid #ddd7;'}
  }
`

const Input = styled.span`
  border: none;
  outline: none;
`
